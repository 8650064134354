/* import router from '@/router'; */

export default {
  state: {
    appearance: {
      theme: null,
    },
    content: {
      page: '/',
      id: null,
      user: {
        pages: [{
            name: "profile",
            title: "Профиль",
            path: "/profile",
            icon: "profile",
          },
          {
            name: "objects",
            title: "Список систем",
            path: "/objects",
            icon: "list",
          },
          {
            name: "Login",
            title: "Выход",
            path: "/login",
            icon: "exit",
          },
        ],
      },
      dispatcher: {
        pages: [{
            name: "profile",
            title: "Профиль",
            path: "/profile",
            icon: "profile",
          },
          {
            name: "building",
            title: "Здание",
            path: "/building",
            icon: "list",
          },
          {
            name: "unit",
            title: "Система",
            path: localStorage.getItem('unit__unit-id') !== null ? `/unit/${localStorage.getItem('unit__unit-id')}` : '/unit/default',
            icon: "key",
          },
          {
            name: "Login",
            title: "Выход",
            path: "/login",
            icon: "exit",
          },
        ],
      }
    },
    patterns: {
      email: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
      email_add: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
      phone: /^[+]\d{1}\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$|^7[0-9]{10,10}$/,
      password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      password_old: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      password_new: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      password_confirm: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      confirm: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      date: /(0[1-9]|1[0-9]|2[0-9]|3[01]).(0[1-9]|1[012]).[0-9]{4}/,
      code: /^[0-9]{4,4}$/,
      name: /^[а-яА-ЯёЁa-zA-Z0-9\s()]{2,30}$/,
      first_name: /^[а-яА-ЯёЁ]{2,30}$/,
      middle_name: /^[а-яА-ЯёЁ]{2,30}$/,
      last_name: /^[а-яА-ЯёЁ]{2,30}$/,
      status: /^[а-яА-ЯёЁa-zA-Z0-9-\s]{2,30}$/,
      comment: /^[а-яА-ЯёЁa-zA-Z0-9-\s]{2,30}$/,
      number: /^[0-9]{4} [0-9]{4} [0-9]{4} [0-9]{4}$/,
      holder: /^[a-zA-Z]{2,30} [a-zA-Z]{2,30}$/,
      cvc: /^[0-9]{3,3}$/,
      contract: /^[а-яА-ЯёЁa-zA-Z0-9-\s]{2,30}$/,
      address: /^[а-яА-ЯёЁ0-9-\s,.]{2,50}$/,
      addr: /^[0-9]{1,5}$/,
      quantity: /^[0-9]{1,2}$/,
      hum: /^[0-9]{1,3}$/,
      temp: /^[0-9]{1,2}$/,
      carb: /^[0-9]{1,4}$/,
      p_in_min: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_in_max: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_out_min: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_out_nom: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_out_max: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_precharge: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_drain: /^(0|[1-9]\d*)([.]\d+)?$/,
      Kp: /^(0|[1-9]\d*)([.]\d+)?$/,
      Ki: /^(0|[1-9]\d*)([.]\d+)?$/,
      Kd: /^(0|[1-9]\d*)([.]\d+)?$/,
      p_delay: /^[0-9]{1,3}$/,
      p_timeout: /^[0-9]{1,3}$/,
      t_drain: /^[0-9]{1,3}$/,
      t_open: /^[0-9]{1,3}$/,
      t_close: /^[0-9]{1,3}$/,
      discharge_timeout: /^[0-9]{1,4}$/,
      precharge_timeout: /^[0-9]{1,4}$/,
      pump_rpm: /^[0-9]{1,4}$/,
      hum_min: /^[0-9]{1,2}$/,
      hum_max: /^[0-9]{1,2}$/,
      temp_min: /^[0-9]{1,3}$/,
      temp_max: /^[0-9]{1,2}$/,
      carb_min: /^[0-9]{1,4}$/,
      carb_max: /^[0-9]{1,4}$/,
    },
    notification: {
      visible: false,
      title: null,
      text: null,
      control: false,
    },
    pagination: {
      start: 0,
      total: 10,
      limit: 10,
      pages: [],
      prev: false,
      next: true,
    },
    program: {
      list: {
        categories: [],
      },
      programs: [],
      program: {},
      previous: null,
      extended: localStorage.getItem('objects__hum-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    modal: {
      current: null,
      password: {
        type: 'password',
        header: 'Изменение пароля',
        body: [{
            id: 0,
            kind: "single",
            name: "password_old",
            title: "Введите старый пароль",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
          }, {
            id: 1,
            kind: "single",
            name: "password_new",
            title: "Введите новый пароль",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
          },
          {
            id: 2,
            kind: "single",
            name: "password_confirm",
            title: "Подтвердите новый пароль",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
          },
        ],
        footer: [{
          id: 0,
          type: "text",
          value: "Изменить пароль",
          paint: "blue",
          action: "changePassword",
          disabled: true,
        }],
      },
      document: {
        type: 'document',
        header: 'Добавление документа',
        body: [{
          id: 0,
          kind: "file",
          input: {
            name: "name",
            title: "Название документа",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          button: {
            value: "add",
            disabled: false,
          },
          status: 'default',
        }, ],
        footer: [{
            id: 0,
            type: "text",
            value: "Добавить документ",
            paint: "blue",
            action: "addDocument",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gary",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      object: {
        type: 'object',
        header: 'Создание объекта',
        body: [{
            id: 0,
            kind: "single",
            name: "type",
            title: "Тип объекта",
            value: "",
            dropdown: {
              type: "simple",
              name: "",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 0,
                  name: "home",
                  title: "Индивидуальная",
                },
                {
                  id: 1,
                  name: "pro",
                  title: "Централизованная",
                },
                {
                  id: 2,
                  name: "lite",
                  title: "Локальная",
                },
              ],
            },
          },
          {
            id: 1,
            kind: "single",
            name: "control_channels",
            title: "Каналы управления",
            value: "",
            dropdown: {
              type: "multi",
              name: "",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 0,
                  name: "hum",
                  title: "По влажности",
                },
                {
                  id: 1,
                  name: "temp",
                  title: "По температуре",
                },
                {
                  id: 2,
                  name: "carb",
                  title: "По уровню CO2",
                }
              ],
            },
          },
          {
            id: 2,
            kind: "single",
            name: "address",
            title: "Адрес объекта",
            value: "",
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 3,
            kind: "double",
            first: {
              type: 'input',
              name: "name",
              title: "Название объекта",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
            last: {
              type: 'phone',
              name: "phone",
              title: "Телефон владельца",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
            status: 'default',
          },
          {
            id: 4,
            kind: "double",
            first: {
              type: 'datepicker',
              name: "date",
              title: "Дата договора",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: false,
            },
            last: {
              type: 'input',
              name: "contract",
              title: "Номер договора",
              value: '',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: false,
            },
            status: 'default',
          },
          {
            id: 5,
            kind: "set",
            name: "owner",
            title: "Владелец",
            value: "",
            dropdown: {
              type: "simple",
              name: "",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 0,
                  name: "exist",
                  title: "Существующий пользователь",
                },
                {
                  id: 1,
                  name: "new",
                  title: "Новый пользователь",
                },
              ],
            },
            set: {
              new: [{
                id: 6,
                kind: "single",
                name: "last_name",
                title: "Фамилия владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 7,
                kind: "single",
                name: "first_name",
                title: "Имя владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 8,
                kind: "single",
                name: "middle_name",
                title: "Отчество владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 9,
                kind: "single",
                name: "email",
                title: "Электронная почта владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 10,
                kind: "single",
                name: "password",
                title: "Пароль владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
              }, ],
              exist: [{
                id: 6,
                kind: "single",
                name: "email",
                title: "Электронная почта владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, ],
            }
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Создать объект",
            paint: "blue",
            action: "createObject",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      zone: {
        type: 'zone',
        header: 'Добавление зоны',
        body: [{
            id: 0,
            kind: "single",
            name: "name",
            title: "Название зоны",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 1,
            kind: "single",
            name: "num",
            title: "Номер зоны",
            value: "",
            dropdown: {
              type: "simple",
              name: "",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [],
            },
          },
          {
            id: 2,
            kind: "setup",
            status: 'default',
            first: {
              name: "hum",
              title: "Влажность зоны",
              value: null,
              default: 45,
              unit: '%',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
              visible: null,
            },
            middle: {
              name: "temp",
              title: "Температура зоны",
              value: null,
              default: 22,
              unit: '°',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
              visible: null,
            },
            last: {
              name: "carb",
              title: "Уровень CO2 зоны",
              value: null,
              default: 350,
              unit: ' PPM',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
              visible: null,
            },
          },
          {
            id: 3,
            kind: "module",
            status: 'valid',
            visible: null,
            dropdown: {
              type: "simple",
              name: "device",
              title: "Тип устройства",
              value: null,
              alias: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 1,
                  name: "s",
                  title: "Датчик",
                },
                {
                  id: 2,
                  name: "g",
                  title: "Гигростат",
                },
              ],
            },
            input: {
              name: "addr",
              title: "Адрес",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
            button: {
              value: "add",
              disabled: true,
            },
            modules: [],
          },
          {
            id: 4,
            kind: "module",
            status: 'valid',
            visible: null,
            dropdown: {
              type: "simple",
              name: "nozzle",
              title: "Тип форсунок",
              value: null,
              alias: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 1,
                  name: "01L",
                  title: "01L",
                },
                {
                  id: 2,
                  name: "02L",
                  title: "02L",
                },
              ],
            },
            input: {
              name: "quan",
              title: "Количество",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
            button: {
              value: "add",
              disabled: true,
            },
            modules: [],
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Добавить зону",
            paint: "blue",
            action: "addZone",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      contact: {
        type: 'contact',
        header: 'Добавление контакта',
        body: [{
            id: 0,
            kind: "single",
            name: "last_name",
            title: "Фамилия",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 1,
            kind: "single",
            name: "first_name",
            title: "Имя",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 2,
            kind: "single",
            name: "middle_name",
            title: "Отчество",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 3,
            kind: "single",
            name: "comment",
            title: "Комментарий",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 4,
            kind: "single",
            name: "phone",
            title: "Телефон",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 5,
            kind: "single",
            name: "email",
            title: "Электронная почта",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Добавить контакт",
            paint: "blue",
            action: "addContact",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gary",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      user: {
        type: 'user',
        header: 'Добавление пользователя',
        body: [{
            id: 0,
            kind: "single",
            name: "systems",
            title: "Выбрать системы",
            value: "",
            dropdown: {
              type: "multi",
              name: "",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [],
            },
          },
          {
            id: 1,
            kind: "single",
            name: "last_name",
            title: "Фамилия",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 2,
            kind: "single",
            name: "first_name",
            title: "Имя",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 3,
            kind: "single",
            name: "middle_name",
            title: "Отчество",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 4,
            kind: "single",
            name: "status",
            title: "Статус",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 5,
            kind: "single",
            name: "phone",
            title: "Телефон",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 6,
            kind: "single",
            name: "email",
            title: "Электронная почта",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Добавить",
            paint: "blue",
            action: "addUser",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gary",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      owner: {
        type: 'owner',
        header: 'Изменение владельца системы',
        body: [{
          id: 1,
          kind: "set",
          name: "owner",
          title: "Владелец",
          value: "",
          dropdown: {
            type: "simple",
            name: "",
            value: "",
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
            categories: [{
                id: 0,
                name: "exist",
                title: "Существующий пользователь",
              },
              {
                id: 1,
                name: "new",
                title: "Новый пользователь",
              },
            ],
          },
          set: {
            new: [{
                id: 5,
                kind: "single",
                name: "last_name",
                title: "Фамилия владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 6,
                kind: "single",
                name: "first_name",
                title: "Имя владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 7,
                kind: "single",
                name: "middle_name",
                title: "Отчество владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              },
              {
                id: 8,
                kind: "single",
                name: "phone",
                title: "Телефон владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              },
              {
                id: 9,
                kind: "single",
                name: "email",
                title: "Электронная почта владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Неверный формат",
              }, {
                id: 10,
                kind: "single",
                name: "password",
                title: "Пароль владельца",
                value: null,
                status: "default",
                error: false,
                errorMessage: "Не менее 6 символов, строчные и заглавные буквы, цифры",
              },
            ],
            exist: [{
              id: 5,
              kind: "single",
              name: "email",
              title: "Электронная почта владельца",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            }, ],
          }
        }, ],
        footer: [{
            id: 0,
            type: "text",
            value: "Изменить владельца",
            paint: "blue",
            action: "changeOwner",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gary",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      card: {
        type: 'card',
        header: 'Добавление карты',
        body: [{
            id: 0,
            kind: "single",
            name: "system",
            title: "Платежная система",
            value: "",
            status: "default",
            dropdown: {
              type: "simple",
              name: "",
              value: "",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 0,
                  name: "mir",
                  title: "Мир",
                },
                {
                  id: 1,
                  name: "visa",
                  title: "Visa",
                },
                {
                  id: 2,
                  name: "mastercard",
                  title: "Mastercard",
                },
              ],
            },
          }, {
            id: 1,
            kind: "single",
            name: "number",
            title: "Номер карты",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 2,
            kind: "single",
            name: "holder",
            title: "Владелец карты",
            value: null,
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
          },
          {
            id: 3,
            kind: "triple",
            status: "default",
            first: {
              type: 'picker',
              name: "month",
              title: "Месяц",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
            middle: {
              type: 'picker',
              name: "year",
              title: "Год",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
            last: {
              type: "cvc",
              name: "cvc",
              title: "CVC",
              value: null,
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
            },
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Добавить карту",
            paint: "blue",
            action: "addCard",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gary",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      payment: {
        type: 'payment',
        header: 'Оплата услуги',
        body: [{
            id: 0,
            kind: "single",
            name: "contract",
            title: "Номер договора",
            value: '№А-5644001 от 13.12.2021',
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
            disabled: true,
          },
          {
            id: 1,
            kind: "single",
            name: "address",
            title: "Адрес системы",
            value: 'г. Бобалево, ул. Маневровая 156, кв 8',
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
            disabled: true,
          },
          {
            id: 2,
            kind: "double",
            first: {
              name: "date",
              title: "Срок уплаты",
              value: '01.05.2022',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
            },
            last: {
              name: "cost",
              title: "Стоимость",
              value: '1 389 руб. 09 коп.',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
            },
          },
          {
            id: 3,
            kind: "single",
            title: "Карта оплаты",
            dropdown: {
              type: "card",
              name: "card",
              system: 'mir',
              value: "**** **** **** 5610",
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              categories: [{
                  id: 1,
                  name: "1",
                  system: 'mir',
                  title: "**** **** **** 5610",
                },
                {
                  id: 2,
                  name: "2",
                  system: 'visa',
                  title: "**** **** **** 2880",
                },
              ],
            },
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Оплатить",
            paint: "blue",
            action: "payService",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      maintenance: {
        type: 'maintenance',
        header: 'Запись на плановое ТО',
        body: [{
            id: 0,
            kind: "single",
            name: "contract",
            title: "Номер договора",
            value: '№А-5644001 от 13.12.2021',
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
            disabled: true,
          },
          {
            id: 1,
            kind: "single",
            name: "address",
            title: "Адрес системы",
            value: 'г. Бобалево, ул. Маневровая 156, кв 8',
            status: "default",
            error: false,
            errorMessage: "Неверный формат",
            disabled: true,
          },
          {
            id: 2,
            kind: "double",
            first: {
              name: "date",
              type: 'input',
              title: "Срок уплаты",
              value: '01.05.2022',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
            },
            last: {
              name: "cost",
              type: 'input',
              title: "Стоимость",
              value: '1 389 руб. 09 коп.',
              status: "default",
              error: false,
              errorMessage: "Неверный формат",
              disabled: true,
            },
          },
        ],
        footer: [{
            id: 0,
            type: "text",
            value: "Записаться",
            paint: "blue",
            action: "getMaintenance",
            disabled: false,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      callback: {
        type: 'callback',
        header: 'Связь с техподдержкой',
        body: [{
          id: 0,
          kind: "single",
          name: "phone",
          title: "Телефон для связи",
          value: null,
          status: "default",
          error: false,
          errorMessage: "Неверный формат",
        }, ],
        footer: [{
          id: 0,
          type: "text",
          value: "Заказать звонок",
          paint: "blue",
          action: "getCallback",
          disabled: true,
        }, ],
      },
      params: null,
      visible: false,
      cancellation: true,
      validation: false,
    },
    popup: {
      current: null,
      error: {
        type: 'error',
        header: 'Ошибка!',
        body: 'Видимо, что-то пошло не так. Попробуйте позднее.',
        footer: [{
          id: 0,
          type: "text",
          value: "Понятно",
          paint: "blue",
          action: "cancel",
          disabled: false,
        }, ],
      },
      password: {
        type: 'password',
        header: 'Уведомление!',
        body: 'Смена пароля успешно произведена.',
        footer: [{
          id: 0,
          type: "text",
          value: "Понятно",
          paint: "blue",
          action: "cancel",
          disabled: false,
        }, ],
      },
      set: {
        type: 'set',
        header: 'Уведомление!',
        body: 'Регистрация успешно завершена!',
        footer: [{
          id: 0,
          type: "text",
          value: "Понятно",
          paint: "blue",
          action: "cancel",
          disabled: false,
        }, ],
      },
      recover: {
        type: 'recover',
        header: 'Уведомление!',
        body: 'На вашу почту отправлена ссылка для сброса пароля. Проверьте почту и перейдите по ссылке.',
        footer: [{
          id: 0,
          type: "text",
          value: "Понятно",
          paint: "blue",
          action: "cancel",
          disabled: false,
        }, ],
      },
      register: {
        type: 'register',
        header: 'Уведомление!',
        body: 'Регистрация прошла успешно.',
        footer: [{
          id: 0,
          type: "text",
          value: "Понятно",
          paint: "blue",
          action: "cancel",
          disabled: false,
        }, ],
      },
      document: {
        type: 'document',
        header: 'Удаление документа',
        body: 'Вы уверены, что хотите удалить документ?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить документ",
            paint: "blue",
            action: "deleteDocument",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      zone: {
        type: 'zone',
        header: 'Удаление зоны',
        body: 'Вы уверены, что хотите удалить зону?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить зону",
            paint: "blue",
            action: "deleteZone",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      system: {
        type: 'system',
        header: 'Удаление системы',
        body: 'Вы уверены, что хотите удалить систему?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить систему",
            paint: "blue",
            action: "deleteSystem",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      avatar: {
        type: 'avatar',
        header: 'Удаление аватара',
        body: 'Вы уверены, что хотите удалить аватар?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить аватар",
            paint: "blue",
            action: "deleteAvatar",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      contact: {
        type: 'contact',
        header: 'Удаление контакта',
        body: 'Вы уверены, что хотите удалить контакт?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить контакт",
            paint: "blue",
            action: "deleteContact",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      user: {
        type: 'user',
        header: 'Удаление пользователя',
        body: 'Вы уверены, что хотите удалить пользователя?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить",
            paint: "blue",
            action: "deleteUser",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      card: {
        type: 'card',
        header: 'Удаление карты',
        body: 'Вы уверены, что хотите удалить карту?',
        footer: [{
            id: 0,
            type: "text",
            value: "Да, удалить карту",
            paint: "blue",
            action: "deleteCard",
            disabled: true,
          },
          {
            id: 1,
            type: "text",
            value: "Отмена",
            paint: "gray",
            action: "cancel",
            disabled: false,
          },
        ],
      },
      callback: {
        type: 'callback',
        header: 'Уведомление!',
        body: 'Ваша заявка принята. Наши специалисты свяжутся с Вами в ближайшее время.',
        footer: [{
          id: 0,
          type: "text",
          value: "Понятно",
          paint: "blue",
          action: "cancel",
          disabled: false,
        }, ],
      },
      params: null,
      visible: false,
    },
    viewer: {
      name: 'Договор №А-5644001 от 13.12.2021',
      visible: false,
    },
    headers: {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('authorization__user-token') || null}`,
      }
    },
    preloader: false,
  },
  getters: {
    getCommonContent: state => state.content,
    getCommonNotification: state => state.notification,
    getCommonPagination: state => state.pagination,
    getCommonProgram: state => state.program,
    getCommonModal: state => state.modal,
    getCommonViewer: state => state.viewer,
    getCommonPreloader: state => state.preloader,
    getCommonPopup: state => state.popup,
  },
  mutations: {
    changePage(state, payload) {
      if (payload.role === 'user') {
        state.content.user.pages.forEach(page => {
          if (page.name == payload.page) state.content.page = page.title;
        });
      }
      if (payload.role === 'dispatcher') {
        state.content.dispatcher.pages.forEach(page => {
          if (page.name == payload.page) state.content.page = page.title;
          if (page.name == 'unit') {
            page.path = localStorage.getItem('unit__unit-id') !== null ? `/unit/${localStorage.getItem('unit__unit-id')}` : '/unit/default';
          }
        });
      }
    },
    setSystemId(state, payload) {
      state.content.dispatcher.pages.forEach(page => {
        if (page.name == 'unit') page.path = `/unit/${payload}`;

        localStorage.setItem('unit__unit-id', payload)
      });
    },
    setBuildingId(state, payload) {
      state.content.dispatcher.pages.forEach(page => {
        if (page.name == 'building') {
          page.path = `/building/${payload}`;

          localStorage.setItem('building__first-id', payload)
        }
      });
    },
    createNotification(state, payload) {
      state.notification = payload;
    },
    destroyNotification(state) {
      state.notification.visible = false;
      state.notification.title = null;
      state.notification.text = null;
      state.notification.control = false;
    },
    createPagination(state, payload) {
      state.pagination.start = payload.pagination.start;
      state.pagination.limit = payload.pagination.limit;
      state.pagination.total = payload.pagination.total;
      state.pagination.pages = [];

      let pagesLength = Math.ceil(state.pagination.total / state.pagination.limit);

      for (let i = 1; i < pagesLength + 1; i++) {
        let page = {
          id: i,
          value: i,
          current: (state.pagination.start + state.pagination.limit) / state.pagination.limit == i ? true : false,
        }

        state.pagination.pages.push(page);
      }

      if (state.pagination.start + state.pagination.limit >= state.pagination.total) {
        state.pagination.next = false;
      }
    },
    changePagination(state, payload) {
      if (payload) {
        if (payload.type === "prev") {
          state.pagination.start = state.pagination.start - state.pagination.limit;
          if (state.pagination.start <= 0) {
            state.pagination.start = 0;
          }

          if (state.pagination.start === 0) {
            state.pagination.prev = false;
          }

          state.pagination.next = true;
        }

        if (payload.type === "next") {
          state.pagination.start = state.pagination.start + state.pagination.limit;

          if (state.pagination.start >= state.pagination.total) {
            state.pagination.start = state.pagination.total - state.pagination.limit;
          }

          if (state.pagination.start === state.pagination.total - state.pagination.limit) {
            state.pagination.next = false;
          }

          state.pagination.prev = true;
        }

        if (payload.page) {
          state.pagination.start = payload.page * state.pagination.limit - state.pagination.limit;
          state.pagination.prev = true;
          state.pagination.next = true;

          if (state.pagination.start === 0) {
            state.pagination.prev = false;
          }

          if (state.pagination.start === state.pagination.total - state.pagination.limit) {
            state.pagination.next = false;
          }
        }
      }
    },
    createProgram(state, payload) {
      state.program.programs = payload.programs;

      if (state.program.programs.length > 0) {
        state.program.programs.forEach((program, index) => {
          if (program.type === 'hum') {
            if (program.active_user) {
              let day = new Date().getDay();

              if (
                day >= program.start_day && day <= program.stop_day ||
                day === program.start_day || day === program.stop_day
              ) {
                state.program.current = program;
              }
            }
            program.max = payload.board.params.features.hum_max;
            program.min = payload.board.params.features.hum_min;
            program.period = {
              name: null,
              value: 50,
            };

            program.start_day_num = program.start_day === 0 ? 7 : program.start_day;
            program.stop_day_num = program.stop_day === 0 ? 7 : program.stop_day;

            state.program.programs[index] = JSON.parse(JSON.stringify(program));

            if (state.program.previous && state.program.previous.id === program.id) {
              state.program.program = state.program.programs[index];
              state.program.previous = state.program.programs[index];
            } else state.program.program = state.program.programs[0];

            state.program.list.type = 'difficult';
            state.program.list.paint = 'blue';

            if (state.program.previous && state.program.previous.id === program.id) {
              state.program.list.value = state.program.programs[index].name;
            } else state.program.list.value = state.program.programs[0].name;

            let obj = {
              id: state.program.programs[index].id,
              name: state.program.programs[index].id,
              title: state.program.programs[index].name,
            }

            state.program.list.categories[index] = obj;
            state.program.zones = JSON.parse(JSON.stringify(payload.zones));

            for (let i = 0; i < state.program.zones.length; i++) {
              state.program.zones[i].hum.programs.forEach(prog => {
                if (prog.id === state.program.program.id) {
                  let morning = {
                    value: prog.morning,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }
                  let day = {
                    value: prog.day,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }
                  let evening = {
                    value: prog.evening,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }
                  let night = {
                    value: prog.night,
                    unit: '%',
                    status: 'default',
                    control: false,
                  }

                  state.program.zones[i].hum.morning = morning;
                  state.program.zones[i].hum.day = day;
                  state.program.zones[i].hum.evening = evening;
                  state.program.zones[i].hum.night = night;
                }
              })
            }

            state.program.cancellation = true;
            state.program.validation = false;
            //state.backup.hum = {};
          }
        })
      }
    },
    showPreloader(state) {
      state.preloader = true;
    },
    hidePreloader(state) {
      state.preloader = false;
    },
    setHeaders(state, payload) {
      state.headers = {
        headers: {
          'Authorization': `Bearer ${payload}`,
        }
      }
    },
    removeHeaders(state) {
      state.headers = {}
    },
    handleValidation(state, module) {
      module.state[module.payload.name].value = module.payload.value;

      if (module.payload.page === 'reset' || module.payload.page === 'set') {
        if (module.payload.name === 'password') {
          if (!module.payload.value.match(state.patterns[module.payload.name])) {
            module.state[module.payload.name].status = 'invalid';
            module.state[module.payload.name].error = 'Не менее 6 символов, строчные и заглавные буквы, цифры';
          } else if (module.payload.value.match(state.patterns[module.payload.name]) && module.payload.value !== module.state.confirm.value && module.state.confirm.value !== null) {
            module.state[module.payload.name].status = 'invalid';
            module.state[module.payload.name].error = 'Пароли не совпадают';
          } else {
            module.state[module.payload.name].status = 'valid';
            module.state[module.payload.name].error = null;
          }
        }

        if (module.payload.name === 'confirm') {
          if (!module.payload.value.match(state.patterns[module.payload.name])) {
            module.state[module.payload.name].status = 'invalid';
            module.state[module.payload.name].error = 'Не менее 6 символов, строчные и заглавные буквы, цифры';
          } else if (module.payload.value.match(state.patterns[module.payload.name]) && module.payload.value !== module.state.password.value) {
            module.state[module.payload.name].status = 'invalid';
            module.state[module.payload.name].error = 'Пароли не совпадают';
          } else {
            module.state[module.payload.name].status = 'valid';
            module.state[module.payload.name].error = null;
          }
        }

        if (
          module.state.password.value === module.state.confirm.value &&
          module.state.password.value.match(state.patterns[module.payload.name]) &&
          module.state.confirm.value.match(state.patterns[module.payload.name])
        ) {
          module.state.password.status = 'valid';
          module.state.password.error = null;
          module.state.confirm.status = 'valid';
          module.state.confirm.error = null;
        }
      } else {
        if (module.payload.value.match(state.patterns[module.payload.name])) {
          module.state[module.payload.name].status = 'valid';
          module.state[module.payload.name].error = null;
        } else {
          module.state[module.payload.name].status = 'invalid';
          module.state[module.payload.name].error = 'Неверный формат';
        }
      }

      module.state.validation = true;
      if (module.state.cancellation) module.state.cancellation = false;

      for (let name in module.state) {
        if (module.state[name] && module.state[name].status !== undefined) {
          if (module.state[name].status !== 'valid') module.state.validation = false;
        }
      }
    },
    handleError(state, error) {
      state.preloader = false;

      //if (error.response === undefined) router.push("/login");

      let payload = {
        type: "error",
        params: {
          text: error.response ? error.response.data.error.message : error,
        },
      };

      this.commit("createPopup", payload);

      console.log(error);
    },
    createModal(state, payload) {
      state.modal.params = payload.params;
      state.modal.current = JSON.parse(JSON.stringify(state.modal[payload.type]));

      if (payload.type === "callback") {
        state.modal.current.body.forEach(element => {
          if (element.name === payload.params.name && payload.params.value !== null) {
            element.value = payload.params.value;
            element.status = 'valid';
            state.modal.current.footer[0].disabled = false;
          }
        });
      }

      if (payload.type === 'user') {
        state.modal.params.systems.forEach(system => {
          let obj = {
            id: system.id,
            name: system.id,
            title: `${system.name} ${system.contract.value}`
          }

          state.modal.current.body[0].dropdown.categories.push(obj);
        });
      }

      if (payload.type === 'zone') {
        state.modal.params.control_channels.forEach(channel => {
          let setup = state.modal.current.body[2];

          for (const key in setup) {
            if (key === 'first' || key === 'middle' || key === 'last') {
              if (setup[key].name === channel) setup[key].visible = true;
            }
          }
        });
      }

      if (payload.type === 'object' && payload.action === 'edit') {
        let current = state.modal.current.body;

        state.modal.current.header = 'Редактирование объекта';
        state.modal.current.footer[0].value = 'Сохранить';
        state.modal.current.footer[0].action = 'editObject';

        for (let i = 0; i < current.length; i++) {
          let pay = {
            type: "object",
            event: "change",
            kind: current[i].kind,
            id: current[i].id,
          };

          if (current[i].id === 0) {
            let categories = current[i].dropdown.categories;

            pay.source = "dropdown";
            pay.name = payload.params.type;

            categories.forEach(category => {
              if (category.name === payload.params.type) pay.value = category.title;
            });

            this.commit("changeModal", pay);
          }

          if (current[i].id === 1) {
            let categories = current[i].dropdown.categories;
            let channels = payload.params.control_channels;
            let arr = [];

            pay.source = "dropdown";
            pay.multi = true;
            pay.name = 'control_channels';

            categories.forEach(category => {
              channels.forEach(channel => {
                if (category.name === channel) arr.push(category.title);
              });
            });

            pay.value = arr.join(', ')

            this.commit("changeModal", pay);
          }

          if (current[i].id === 2) {
            pay.source = "input";
            pay.name = 'address';
            pay.value = payload.params.address;

            this.commit("changeModal", pay);
          }

          if (current[i].id === 3) {
            if (payload.params.name) {
              pay.source = "input";
              pay.name = 'name';
              pay.part = 'first';
              pay.value = payload.params.name;

              this.commit("changeModal", pay);
            }

            if (payload.params.phone) {
              pay.source = "phone";
              pay.name = 'phone';
              pay.part = 'last';
              pay.value = payload.params.phone;

              this.commit("changeModal", pay);
            }
          }

          if (current[i].id === 4) {
            if (payload.params.date) {
              pay.source = "datepicker";
              pay.name = 'date';
              pay.part = 'first';
              pay.value = payload.params.date;

              this.commit("changeModal", pay);
            }

            if (payload.params.contract) {
              pay.source = "input";
              pay.name = 'contract';
              pay.part = 'last';
              pay.value = payload.params.contract;

              this.commit("changeModal", pay);
            }
          }

          if (current[i].id === 5) {
            current.splice(i, 1);
          }

          state.modal.current.footer[0].disabled = false;

          /* if (current[i].id === 5) {
            let categories = current[i].dropdown.categories;

            pay.source = "dropdown";
            pay.name = payload.params.owner;

            categories.forEach(category => {
              if (category.name === payload.params.owner) pay.value = category.title;
            });

            console.log(pay);

            this.commit("changeModal", pay);
          } */

          /* if (current[i].id === 6) {
            pay.source = "input";
            pay.name = 'email';
            pay.value = payload.params.email;

            this.commit("changeModal", pay);
          } */
        }
      }

      state.modal.visible = true;
    },
    changeModal(state, payload) {
      state.modal.current.body.forEach(element => {
        if (payload.event === 'focus') {
          if (payload.kind === "setup") {
            if (element.id === payload.id) {
              if (payload.name === 'carb') {
                if (isNaN(+payload.value.slice(0, -4))) element[payload.part].value = '';
                else element[payload.part].value = +payload.value.slice(0, -4);
              } else {
                if (isNaN(+payload.value.slice(0, -1))) element[payload.part].value = '';
                else element[payload.part].value = +payload.value.slice(0, -1);
              }

              element[payload.part].unit = '';
            }
          }
        }

        if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
          if (payload.kind === "single") {
            if (element.id === payload.id) {
              if (payload.source === 'dropdown') {
                if (payload.open) {
                  for (let key in state.modal.params) {
                    if (key === 'num') {
                      let common = [...Array(31)].map((el, i) => {
                        return {
                          id: i + 1,
                          name: i + 1,
                          title: `Зона ${i + 1}`
                        }
                      });

                      let arr = this.state.system.zones;

                      for (let i = 0; i < arr.length; i++) {
                        for (let j = 0; j < common.length; j++) {
                          if (common[j].id === arr[i].num.val) {
                            common.splice(common.indexOf(common[j]), 1)
                          }
                        }
                      }

                      state.modal.current.body[1].dropdown.categories = common;
                    }
                  }
                } else {
                  element.dropdown.value = payload.value;

                  if (payload.type == 'zone') {
                    element.value = +payload.name;
                  } else if (payload.type == 'user') {
                    let categories = element.dropdown.categories;
                    let strings = element.dropdown.value.split(', ');
                    let newArr = [];

                    for (let i = 0; i < categories.length; i++) {
                      for (let j = 0; j < strings.length; j++) {
                        if (categories[i].title === strings[j]) newArr.push(categories[i].id);
                      }
                    }

                    element.value = newArr;
                  } else if (payload.type == 'object') {
                    if (element.dropdown.type === 'multi') {
                      let categories = element.dropdown.categories;
                      let strings = element.dropdown.value.split(', ');
                      let newArr = [];

                      for (let i = 0; i < categories.length; i++) {
                        for (let j = 0; j < strings.length; j++) {
                          if (categories[i].title === strings[j]) newArr.push(categories[i].name);
                        }
                      }

                      element.value = newArr;
                    } else element.value = payload.name;
                  } else element.value = payload.name;

                  if (payload.value !== '') {
                    element.status = 'valid';
                    element.dropdown.status = 'valid';
                    element.dropdown.error = false;
                  } else {
                    element.status = 'invalid';
                    element.dropdown.status = 'invalid';
                    element.dropdown.error = true;
                  }

                  for (let key in state.modal.params) {
                    if (element.name === key) {
                      state.modal.params[key] = element.value;
                    }
                  }
                }
              } else {
                element.value = payload.value;

                if (payload.name === 'password_new' || payload.name === 'password_confirm') {
                  if (payload.name === 'password_new') {
                    if (!payload.value.match(state.patterns[payload.name])) {
                      element.status = 'invalid';
                      element.error = true;
                      element.errorMessage = 'Не менее 6 символов, строчные и заглавные буквы, цифры';
                    } else if (payload.value.match(state.patterns[payload.name]) && payload.value !== state.modal.current.body[2].value && state.modal.current.body[2].value !== null) {
                      element.status = 'invalid';
                      element.error = true;
                      element.errorMessage = 'Пароли не совпадают';
                    } else {
                      element.status = 'valid';
                      element.error = false;
                    }
                  }

                  if (payload.name === 'password_confirm') {
                    if (!payload.value.match(state.patterns[payload.name])) {
                      element.status = 'invalid';
                      element.error = true;
                      element.errorMessage = 'Не менее 6 символов, строчные и заглавные буквы, цифры';
                    } else if (payload.value.match(state.patterns[payload.name]) && payload.value !== state.modal.current.body[1].value) {
                      element.status = 'invalid';
                      element.error = true;
                      element.errorMessage = 'Пароли не совпадают';
                    } else {
                      element.status = 'valid';
                      element.error = false;
                    }
                  }

                  if (
                    state.modal.current.body[1].value === state.modal.current.body[2].value &&
                    state.modal.current.body[1].value.match(state.patterns.password_new) &&
                    state.modal.current.body[2].value.match(state.patterns.password_confirm)
                  ) {
                    state.modal.current.body[1].status = 'valid';
                    state.modal.current.body[1].error = false;
                    state.modal.current.body[2].status = 'valid';
                    state.modal.current.body[2].error = false;
                  }
                } else {
                  if (payload.value.match(state.patterns[payload.name])) {
                    element.status = 'valid';
                    element.error = false;
                  } else {
                    element.status = 'invalid';
                    element.error = true;
                  }
                }

                for (let key in state.modal.params) {
                  if (element.name === key) {
                    state.modal.params[key] = element.value;
                  }
                }
              }
            }
          }

          if (payload.kind === "double") {
            if (element.id === payload.id) {
              if (payload.source === 'datepicker') {
                if (isNaN(payload.value)) element[payload.part].value = null;
                else element[payload.part].value = payload.value;

                if (element[payload.part].value !== null) {
                  element[payload.part].status = 'valid';
                  element[payload.part].error = false;
                } else {
                  element[payload.part].status = 'invalid';
                  element[payload.part].error = true;
                }
              } else {
                element[payload.part].value = payload.value;

                if (payload.value.match(state.patterns[payload.name])) {
                  element[payload.part].status = 'valid';
                  element[payload.part].error = false;
                } else {
                  element[payload.part].status = 'invalid';
                  element[payload.part].error = true;
                }
              }

              if (element.first.status === 'valid' && element.last.status === 'valid') element.status = 'valid';
              else element.status = 'invalid';

              for (let key in state.modal.params) {
                if (element[payload.part].name === key) {
                  state.modal.params[key] = element[payload.part].value;
                }
              }
            }
          }

          if (payload.kind === "setup") {
            if (element.id === payload.id) {
              if (payload.source === "switch") {
                element[payload.part].disabled = !payload.value;
                element[payload.part].value = element[payload.part].default;

                if (payload.value === true) {
                  element[payload.part].status = 'valid';

                  if (payload.part === 'first') {
                    state.modal.current.body[3].visible = true;
                    state.modal.current.body[4].visible = true;
                  }
                } else if (payload.value === false) {
                  element[payload.part].value = null;
                  element[payload.part].status = 'default';
                  element[payload.part].error = false;

                  if (payload.part === 'first') {
                    element[payload.part].unit = '%';
                    state.modal.current.body[3].visible = false;
                    state.modal.current.body[4].visible = false;
                  }
                  if (payload.part === 'middle') element[payload.part].unit = '°';
                  if (payload.part === 'last') element[payload.part].unit = ' PPM';
                }

                for (let key in state.modal.params) {
                  if (element[payload.part].name === key) {
                    state.modal.params[key].status = !element[payload.part].disabled;
                    state.modal.params[key].set = element[payload.part].value;
                  }
                }
              }

              if (payload.source === "input") {
                if (!isNaN(+payload.value)) element[payload.part].value = +payload.value;

                if (payload.value.match(state.patterns[payload.name])) {
                  element[payload.part].status = 'valid';
                  element[payload.part].error = false;
                } else {
                  element[payload.part].status = 'invalid';
                  element[payload.part].error = true;
                }

                for (let key in state.modal.params) {
                  if (element[payload.part].name === key) {
                    state.modal.params[key].set = element[payload.part].value;
                  }
                }
              }

              if (element.first.status === 'default' && element.middle.status === 'default' && element.last.status === 'default') {
                element.status = 'default';
              } else if (element.first.status === 'invalid' || element.middle.status === 'invalid' || element.last.status === 'invalid') {
                element.status = 'invalid';
              } else {
                element.status = 'valid';
              }
            }
          }

          if (payload.kind === "module") {
            if (element.id === payload.id) {
              if (payload.source === 'dropdown') {
                element.dropdown.value = payload.value;
                element.dropdown.alias = payload.name;

                if (payload.value !== '') {
                  element.dropdown.status = 'valid';
                  element.dropdown.error = false;
                } else {
                  element.dropdown.status = 'invalid';
                  element.dropdown.error = true;
                }
              }

              if (payload.source === 'input') {
                element.input.value = payload.value;

                if (payload.value.match(state.patterns[payload.name])) {
                  element.input.status = 'valid';
                  element.input.error = false;
                } else {
                  element.input.status = 'invalid';
                  element.input.error = true;
                }
              }

              if (payload.source === 'add') {
                let obj = {
                  first: {
                    value: element.dropdown.value,
                  },
                  last: {
                    value: element.input.value,
                  },
                };

                element.modules.push(obj);

                if (element.dropdown.name === 'device') {
                  let obj2 = {
                    type: element.dropdown.alias,
                    addr: +element.input.value,
                  };
                  state.modal.params.devs.push(obj2);
                }

                if (element.dropdown.name === 'nozzle') {
                  let obj3 = {
                    type: element.dropdown.alias,
                    quan: +element.input.value,
                  };
                  state.modal.params.nozzles.push(obj3);
                }

                element.dropdown.value = '';
                element.dropdown.status = 'default';
                element.input.value = '';
                element.input.status = 'default';
                element.button.disabled = true;
              }

              if (payload.source === 'delete') {
                element.modules = element.modules.filter((element, index) => index !== payload.index);

                if (element.dropdown.name === 'device') {
                  state.modal.params.devs = state.modal.params.devs.filter((element, index) => index !== payload.index);
                }
                if (element.dropdown.name === 'nozzle') {
                  state.modal.params.nozzles = state.modal.params.nozzles.filter((element, index) => index !== payload.index);
                }
              }

              if (element.dropdown.status === 'valid' && element.input.status === 'valid') {
                element.button.disabled = false;
              } else {
                element.button.disabled = true;
              }

              if (element.dropdown.status !== 'invalid' && element.input.status !== 'invalid') {
                element.status = 'valid';
              } else {
                element.status = 'invalid';
              }
            }
          }

          if (payload.kind === "set") {
            if (element.id === payload.id) {
              if (payload.source === 'dropdown') {
                element.dropdown.value = payload.value;
                element.value = payload.name;

                if (payload.value !== '') {
                  element.status = 'valid';
                  element.dropdown.status = 'valid';
                  element.dropdown.error = false;
                } else {
                  element.status = 'invalid';
                  element.dropdown.status = 'invalid';
                  element.dropdown.error = true;
                }

                for (let key in state.modal.params) {
                  if (element.name === key) {
                    state.modal.params[key] = element.value;
                  }
                }
              }

              let start = state.modal.current.body.indexOf(element) + 1;
              let data = element.set[payload.name];

              state.modal.current.body.splice(start, state.modal.current.body.length);
              data.forEach(elem => state.modal.current.body.push(elem));
            }
          }

          if (payload.kind === "triple") {
            if (element.id === payload.id) {
              if (payload.source === 'picker') {
                element[payload.part].value = payload.value;

                if (element[payload.part].value !== '') {
                  element[payload.part].status = 'valid';
                  element[payload.part].error = false;
                } else {
                  element[payload.part].status = 'invalid';
                  element[payload.part].error = true;
                }

                for (let key in state.modal.params) {
                  if (element[payload.part].name === key) {
                    state.modal.params[key] = payload.value;
                  }
                }
              }

              if (payload.source === 'cvc') {
                element[payload.part].value = payload.value;

                if (payload.value.match(state.patterns[payload.name])) {
                  element[payload.part].status = 'valid';
                  element[payload.part].error = false;
                } else {
                  element[payload.part].status = 'invalid';
                  element[payload.part].error = true;
                }

                for (let key in state.modal.params) {
                  if (element[payload.part].name === key) {
                    state.modal.params[key] = payload.value;
                  }
                }
              }

              if (element.first.status === 'valid' && element.middle.status === 'valid' && element.last.status === 'valid') element.status = 'valid';
              else element.status = 'invalid';
            }
          }

          if (payload.kind === "file") {
            if (element.id === payload.id) {
              if (payload.source === 'input') {
                element.input.value = payload.value;

                if (payload.value.match(state.patterns[payload.name])) {
                  element.input.status = 'valid';
                  element.input.error = false;
                } else {
                  element.input.status = 'invalid';
                  element.input.error = true;
                }

                for (let key in state.modal.params) {
                  if (element.input.name === key) {
                    state.modal.params[key] = payload.value;
                  }
                }
              }

              if (payload.source === 'file') {
                element.button.value = 'apply';
                state.modal.params.file = payload.value;
              }

              if (element.input.status === 'valid' && element.button.value === 'apply') element.status = 'valid';
              else element.status = 'invalid';
            }
          }
        }

        if (payload.event === 'blur') {
          if (payload.kind === "setup") {
            if (element.id === payload.id && element[payload.part].status !== 'invalid') {
              if (payload.name === 'hum') element[payload.part].unit = '%';
              if (payload.name === 'temp') element[payload.part].unit = '°';
              if (payload.name === 'carb') element[payload.part].unit = ' PPM';
            }
          }
        }
      });

      state.modal.current.footer[0].disabled = !state.modal.current.body.every(element => element.status == 'valid');
    },
    validateModal(state) {
      console.log(state.modal.validation);
    },
    destroyModal(state) {
      state.modal.current = null;
      state.modal.params = null;
      state.modal.visible = false;
      state.modal.cancellation = true;
      state.modal.validation = false;
    },
    createPopup(state, payload) {
      state.popup.current = state.popup[payload.type];
      state.popup.visible = true;

      if (payload.type === 'error') {
        state.popup.current.body = payload.params.text;
      } else state.popup.params = payload.params;
    },
    destroyPopup(state) {
      state.popup.current = null;
      state.popup.params = null;
      state.popup.visible = false;
    },
    createViewer(state) {
      state.viewer.visible = true;
    },
    destroyViewer(state) {
      state.viewer.visible = false;
    },
    setTheme(state) {
      document.body.setAttribute('data-theme', state.appearance.theme)
    },
    changeTheme(state, payload) {
      state.appearance.theme = payload;
    },
    clearCommonState(state) {
      state.notification = {
        visible: false,
        title: null,
        text: null,
        control: false,
      };
    },
  },
  actions: {
    getCommonCallback(context) {
      context.commit("showPreloader");
      setTimeout(() => {
        context.commit("hidePreloader");

        let payload = {
          type: "callback",
        };

        context.commit('createPopup', payload);
      }, 1000);
    },
  }
}