<template>
  <main class="unit" :data-id="about.id">
    <div class="unit__content">
      <Notification />
      <slot v-if="this.$route.params.id">
        <div class="unit__navbar">
          <ul class="unit__navbar-list">
            <li class="unit__navbar-back" @click="goBack">
              <span class="unit__navbar-arrow">
                <font-awesome-icon icon="arrow-left" />
              </span>
              <div class="unit__navbar-info">
                <span class="unit__navbar-name">Здание</span>
                <span class="unit__navbar-feature"></span>
              </div>
            </li>
            <li
              v-for="item in navbar.list"
              :class="[
                item.isActive
                  ? 'unit__navbar-item unit__navbar-item_current'
                  : 'unit__navbar-item',
              ]"
              :id="item.id"
              :key="item.id"
              @click="setActiveItem"
            >
              <span class="unit__navbar-quantity">{{ item.quantity }}</span>
              <div class="unit__navbar-info">
                <span class="unit__navbar-name">{{ item.name }}</span>
                <span class="unit__navbar-feature">{{ item.feature }}</span>
              </div>
            </li>
          </ul>
        </div>
        <UnitInfo v-if="navbar.current === 1" />
      </slot>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import UnitInfo from "../pages/Unit-Info";
  import Notification from "../layout/Notification";

  export default {
    name: "Unit",
    components: {
      Notification,
      UnitInfo
    },
    computed: {
      ...mapGetters({
        navbar: "getUnitNavbar",
        about: "getUnitAbout",
      }),
    },
    methods: {
      ...mapMutations([
        "setUnitActiveNavbarItem",
      ]),
      ...mapActions([
        "fetchUnit",
      ]),
      goBack() {
        this.$router.push(`/building/${localStorage.getItem("building__first-id")}`);
        
        localStorage.removeItem("unit__navbar-current");
      },
      setActiveItem(event) {
        let item = event.target.closest(".unit__navbar-item");

        this.setUnitActiveNavbarItem(item);
      },
    },
    created() {
      this.fetchUnit(this.$route.params.id);
    },
  };
</script>

<style lang="scss" scoped>
  .unit {
    position: relative;
    width: 100%;

    @include mediaquery(sm) {
      width: calc(100% - 75px);
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;

      @include mediaquery(sm) {
        padding-bottom: 100px;
      }
    }

    &__navbar {
      width: 100%;
      margin-bottom: 20px;

      &-list {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
          margin-left: -5px;
          margin-right: -5px;
          margin-top: -5px;
          margin-bottom: -10px;
          width: calc(100% + 10px);
        }
      }

      &-back {
        display: flex;
        width: 100%;
        border-radius: 6px;
        border: 2px solid #41c6eb;
        background-color: #41c6eb;
        box-shadow: $shadowLight;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        .unit__navbar-arrow {
          color: #41c6eb;
          background-color: #fff;
        }

        .unit__navbar-name {
          color: #fff;
        }

        .unit__navbar-feature {
          color: #fff;
        }
      }

      &-item {
        @include base;

        display: flex;
        width: 100%;
        border: 2px solid $colorMainLight;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        &_current {
          border: 2px solid #41c6eb;

          .unit__navbar-quantity {
            color: #41c6eb;
            background-color: #fff;
          }

          .unit__navbar-name {
            color: #222325;
          }
        }
      }

      &-arrow,
      &-quantity {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        overflow: hidden;
      }

      &-name {
        font-weight: 500;
        font-size: 14px;
        color: #9b9b9b;
        text-transform: uppercase;
      }

      &-feature {
        margin-top: 5px;
        font-size: 10px;
        color: #9b9b9b;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
</style>