import axios from 'axios';

export default {
  state: {
    navbar: {},
    about: {
      extended: localStorage.getItem('unit__about-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    regimes: [],
    zones: [],
    tasks: [],
    config: {
      extended: localStorage.getItem('unit__config-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    state: {
      network: {
        type: null,
        status: null,
        indicator: null,
        timer: null,
        countdown: null,
      },
      extended: localStorage.getItem('unit__state-extended') === 'true' ? true : false || false,
    },
    control: {
      extended: localStorage.getItem('unit__control-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    flush: {
      extended: localStorage.getItem('unit__flush-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    features: {
      extended: localStorage.getItem('unit__features-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    network: {
      extended: localStorage.getItem('unit__network-extended') === 'true' ? true : false || false,
    },
    firmware: {
      extended: localStorage.getItem('unit__firmware-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    deleting: true,
    backup: {},
  },
  getters: {
    getUnitNavbar: state => state.navbar,
    getUnitAbout: state => state.about,
    getUnitRegimes: state => state.regimes,
    getUnitZones: state => state.zones,
  },
  mutations: {
    toggleUnitSection(state, payload) {
      if (payload === 'hum' || payload === 'temp' || payload === 'carb') {
        state.regimes.forEach(regime => {
          if (regime.type === payload) {
            regime.extended = !regime.extended;

            localStorage.setItem(`unit__${payload}-extended`, regime.extended);
          }
        });
      } else {
        state[payload].extended = !state[payload].extended;

        localStorage.setItem(`unit__${payload}-extended`, state[payload].extended);
      }
    },
    setUnitActiveNavbarItem(state, item) {
      state.navbar.list.forEach(element => element.isActive = false);
      state.navbar.list.forEach(element => {
        if (element.id == item.id) {
          element.isActive = true;
          state.navbar.current = +item.id;
          localStorage.setItem(`unit__navbar-current`, +item.id);
        }
      });
    },
    setUnitActiveZone(state, payload) {
      state.zones.forEach((zone, index) => {
        if (payload.id !== zone.id) {
          zone.current = false;
          zone.cancellation = true;
          zone.validation = false;
          zone.extended = false;

          if (state.backup.zones && zone.id === state.backup.zones[index].id) {
            zone.hum = state.backup.zones[index].hum;
            zone.temp = state.backup.zones[index].temp;
            zone.name = state.backup.zones[index].name;
            zone.num = state.backup.zones[index].num;
            zone.device = state.backup.zones[index].device;
            zone.devs = state.backup.zones[index].devs;
            zone.nozzle = state.backup.zones[index].nozzle;
            zone.nozzles = state.backup.zones[index].nozzles;
            zone.status = 'default';
            state.backup.zones[index] = {};
          }
        }
      });
      state.zones.forEach(zone => zone.id === payload.id ? zone.current = true : false);
      state.deleting = false;
    },
    toggleUnitZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) {
          zone.extended = !zone.extended;
        }
      });
    },
    extendUnitZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) {
          zone.extended = true;
        }
      });
    },
    collapseUnitZone(state, payload) {
      state.zones.forEach(zone => {
        if (zone.id === payload.id) zone.extended = false;
      });
    },

    updateUnitNavbar(state, payload) {
      state.navbar = {
        current: localStorage.getItem(`unit__navbar-current`) ? +localStorage.getItem(`unit__navbar-current`) : 1,
      };

      state.navbar.list = [{
          id: 1,
          quantity: 6,
          name: 'Информация',
          feature: payload.contract.value,
          isActive: state.navbar.current === 1 ? true : false,
        },
      ];
    },
    updateUnitAbout(state, payload) {
      state.about.id = payload.id;
      state.about.contract = payload.contract;
      state.about.active_user = payload.active_user;
      state.about.active_srv = payload.active_srv;
      state.about.errors = payload.errors;
      state.about.date = payload.date;
      state.about.mileage = +payload.mileage * 10000;
      state.about.pump_hours = payload.pump_hours;
      state.about.type = payload.type;
      state.about.control_channels = payload.control_channels;
      state.about.unit_type = payload.unit_type;
      state.about.unit_architecture = payload.unit_architecture;

      payload.from_users.forEach(user => {
        if (user.issuingAccess === true) {
          state.about.from_user = user;
        }
      });

      state.about.name = {
        element: 'input',
        name: 'name',
        title: 'Имя объекта',
        value: payload.name,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.address = {
        element: 'input',
        name: 'address',
        title: 'Адрес объекта',
        value: payload.address,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_start = {
        element: 'datepicker',
        name: 'date_start',
        title: 'Адрес объекта',
        value: payload.date_start,
        pattern: /^[0-9]{2,250}$/,
        status: 'valid',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_install = {
        element: 'datepicker',
        name: 'date_install',
        title: 'Адрес объекта',
        value: payload.date_install,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'valid',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_warranty = {
        element: 'datepicker',
        name: 'date_warranty',
        title: 'Адрес объекта',
        value: payload.date_warranty,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_maintenance_last = {
        element: 'datepicker',
        name: 'date_maintenance_last',
        value: payload.date_maintenance_last,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.date_maintenance_plan = {
        element: 'datepicker',
        name: 'date_maintenance_plan',
        value: payload.date_maintenance_plan,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.owner = [{
          id: 0,
          element: 'input',
          type: "text",
          name: 'last_name',
          title: 'Фамилия владельца',
          value: state.about.from_user.last_name,
          pattern: /^[а-яА-ЯёЁ]{1,30}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 1,
          element: 'input',
          type: "text",
          name: 'first_name',
          title: 'Имя владельца',
          value: state.about.from_user.first_name,
          pattern: /^[а-яА-ЯёЁ]{1,30}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 2,
          element: 'input',
          type: "text",
          name: 'middle_name',
          title: 'Отчество владельца',
          value: state.about.from_user.middle_name,
          pattern: /^[а-яА-ЯёЁ]{1,30}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 3,
          element: 'input',
          type: "tel",
          name: 'phone',
          title: 'Телефон',
          value: state.about.from_user.phone,
          pattern: /^[+]\d{1}\s[(]\d{3}[)]\s\d{3}[-]\d{2}[-]\d{2}$|^7[0-9]{10,10}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
        {
          id: 4,
          element: 'input',
          type: "email",
          name: 'email',
          title: 'Электронная почта',
          value: state.about.from_user.email,
          pattern: /^[-\w.]+@([A-z0-9][-A-z0-9]+\.)+[A-z]{2,4}$/,
          status: 'default',
          error: false,
          errorText: 'Неверный формат',
        },
      ];
      state.about.documents = payload.documents;
      state.about.modes = payload.modes;

      state.about.modes.forEach(mode => {
        if (mode.type === 'vacation') {
          state.about.vacation = mode;
        }
      })

      if (payload.state === 0) {
        state.about.state = {
          value: 'disabled',
          text: 'выключена'
        }
      }

      if (payload.state === 1) {
        state.about.state = {
          value: 'enabled',
          text: 'включена'
        }
      }

      if (payload.state === 2) {
        state.about.state = {
          value: 'error',
          text: 'ошибка'
        }
      }

      if (payload.state === 3) {
        state.about.state = {
          value: 'active',
          text: 'работает'
        }
      }

      if (payload.state === 4) {
        state.about.state = {
          value: 'inactive',
          text: 'не работает'
        }
      }

      localStorage.setItem('unit__unit-id', payload.id);
      localStorage.setItem('unit__board-id', payload.board.id);
    },
    updateUnitRegimes(state, payload) {
      state.regimes = [{
          id: 0,
          type: 'hum',
          current: null,
          programs: [],
          list: {
            categories: [],
          },
          previous: null,
          extended: localStorage.getItem('system__hum-extended') === 'true' ? true : false || false,
          cancellation: true,
          validation: false,
        },
        {
          id: 1,
          type: 'temp',
          programs: [],
          list: {
            categories: [],
          },
          previous: null,
          extended: localStorage.getItem('system__temp-extended') === 'true' ? true : false || false,
          cancellation: true,
          validation: false,
        },
        {
          id: 2,
          type: 'carb',
          programs: [],
          list: {
            categories: [],
          },
          previous: null,
          extended: localStorage.getItem('system__carb-extended') === 'true' ? true : false || false,
          cancellation: true,
          validation: false,
        }
      ];

      state.regimes.forEach(regime => {
        payload.programs.forEach(program => {
          if (program.type === regime.type) regime.programs.push(program);
        });
      });

      state.regimes = state.regimes.filter(regime => regime.programs.length > 0);

      state.regimes.forEach(regime => {
        regime.programs.forEach((program, index) => {
          if (program.active_srv) {
            let day = new Date().getDay() === 0 ? 7 : new Date().getDay();

            if (
              day >= program.days[0] && day <= program.days[program.days.length - 1] ||
              day === program.days[0] || day === program.days[program.days.length - 1]
            ) {
              regime.current = program;
            }
          }

          program.max = payload.board[`${program.type}_max`];
          program.min = payload.board[`${program.type}_min`];

          program.period = {
            name: null,
            value: 50,
          };

          program.periods.forEach(period => {
            let start = period.start.split(":", 2);
            let stop = period.stop.split(":", 2);

            period.start = {
              hour: +start[0],
              minute: +start[1]
            }

            period.stop = {
              hour: +stop[0],
              minute: +stop[1]
            }
          })

          program.start_day = program.days[0];
          program.stop_day = program.days[program.days.length - 1];

          program.start_day_num = program.start_day === 0 ? 7 : program.start_day;
          program.stop_day_num = program.stop_day === 0 ? 7 : program.stop_day;

          if (regime.previous && regime.previous.id === program.id) {
            regime.program = regime.programs[index];
            regime.previous = regime.programs[index];
          } else regime.program = regime.programs[0];

          regime.list.type = 'difficult';

          if (regime.type === 'hum') regime.list.paint = 'blue';
          if (regime.type === 'temp') regime.list.paint = 'orange';
          if (regime.type === 'carb') regime.list.paint = 'black';

          if (regime.previous && regime.previous.id === program.id) {
            regime.list.value = regime.programs[index].name;
          } else regime.list.value = regime.programs[0].name;

          let obj = {
            id: regime.programs[index].id,
            name: regime.programs[index].id,
            title: regime.programs[index].name,
          }

          regime.list.categories[index] = obj;


          regime.cancellation = true;
          regime.validation = false;
          state.backup.regimes = [];
        });

        regime.zones = JSON.parse(JSON.stringify(payload.zones)).filter(zone => zone[regime.type] !== null);

        for (let i = 0; i < regime.zones.length; i++) {
          if (regime.zones[i].hum !== null) {
            regime.zones[i].hum.programs.forEach(prog => {
              if (prog.id === regime.program.id) {
                let morning = {
                  value: prog.morning,
                  unit: '%',
                  status: 'default',
                }
                let day = {
                  value: prog.day,
                  unit: '%',
                  status: 'default',
                }
                let evening = {
                  value: prog.evening,
                  unit: '%',
                  status: 'default',
                }
                let night = {
                  value: prog.night,
                  unit: '%',
                  status: 'default',
                }

                regime.zones[i].hum.morning = morning;
                regime.zones[i].hum.day = day;
                regime.zones[i].hum.evening = evening;
                regime.zones[i].hum.night = night;
              }
            })
          }

          if (regime.zones[i].temp !== null) {
            regime.zones[i].temp.programs.forEach(prog => {
              if (prog.id === regime.program.id) {
                let morning = {
                  value: prog.morning,
                  unit: '°',
                  status: 'default',
                }
                let day = {
                  value: prog.day,
                  unit: '°',
                  status: 'default',
                }
                let evening = {
                  value: prog.evening,
                  unit: '°',
                  status: 'default',
                }
                let night = {
                  value: prog.night,
                  unit: '°',
                  status: 'default',
                }

                regime.zones[i].temp.morning = morning;
                regime.zones[i].temp.day = day;
                regime.zones[i].temp.evening = evening;
                regime.zones[i].temp.night = night;
              }
            })
          }

          if (regime.zones[i].carb !== null) {
            regime.zones[i].carb.programs.forEach(prog => {
              if (prog.id === regime.program.id) {
                let morning = {
                  value: prog.morning,
                  unit: '°',
                  status: 'default',
                }
                let day = {
                  value: prog.day,
                  unit: '°',
                  status: 'default',
                }
                let evening = {
                  value: prog.evening,
                  unit: '°',
                  status: 'default',
                }
                let night = {
                  value: prog.night,
                  unit: '°',
                  status: 'default',
                }

                regime.zones[i].carb.morning = morning;
                regime.zones[i].carb.day = day;
                regime.zones[i].carb.evening = evening;
                regime.zones[i].carb.night = night;
              }
            })
          }
        }
      });
    },
    updateUnitZones(state, payload) {
      state.zones = JSON.parse(JSON.stringify(payload.zones));

      state.zones.forEach(zone => {
        zone.name = {
          value: JSON.parse(JSON.stringify(zone.name)),
          status: 'default',
        }

        zone.num = {
          type: 'simple',
          name: 'num',
          val: zone.num,
          value: `Зона ${zone.num}`,
          status: 'default',
          error: false,
          errorMessage: 'Неверный формат',
          categories: [],
        };

        zone.programs = [];

        payload.control_channels.forEach(channel => {
          if (zone[channel] !== null) {
            zone[channel].status = 'default';
            zone[channel].control = false;

            zone[channel].min = {
              value: zone[channel].min,
              name: 'min',
              status: 'default',
              limit: payload.board[`${[channel]}_min`]
            };

            zone[channel].max = {
              value: zone[channel].max,
              name: 'max',
              status: 'default',
              limit: payload.board[`${[channel]}_max`]
            }

            if (channel === 'hum') {
              zone.hum.unit = '%';
              zone.hum.default = 45;

              let device = {
                dropdown: {
                  name: "device",
                  value: null,
                  alias: null,
                  status: "default",
                  categories: [{
                      id: 1,
                      name: "s",
                      title: "Датчик",
                    },
                    {
                      id: 2,
                      name: "g",
                      title: "Гигростат",
                    },
                  ],
                },
                input: {
                  name: "address",
                  value: null,
                  pattern: /^[а-яА-ЯёЁa-zA-Z0-9?!,.\s]{2,250}$/,
                  status: "default",
                  error: false,
                  errorMessage: "Неверный формат",
                },
                button: {
                  value: "add",
                  disabled: true,
                },
              };

              let nozzle = {
                dropdown: {
                  name: "nozzle",
                  value: null,
                  alias: null,
                  status: "default",
                  categories: [{
                      id: 1,
                      name: "01L",
                      title: "01L",
                    },
                    {
                      id: 2,
                      name: "02L",
                      title: "02L",
                    },
                  ],
                },
                input: {
                  name: "quan",
                  value: null,
                  pattern: /^[0-9]{1,5}$/,
                  status: "default",
                  error: false,
                  errorMessage: "Неверный формат",
                },
                button: {
                  value: "add",
                  disabled: true,
                },
              };

              zone.device = JSON.parse(JSON.stringify(device));
              zone.nozzle = JSON.parse(JSON.stringify(nozzle));
            }

            if (channel === 'temp') {
              zone.temp.unit = '°';
              zone.temp.default = 22;
            }

            if (channel === 'carb') {
              zone.carb.unit = '°';
              zone.carb.default = 600;
            }

            state.regimes.forEach(regime => {
              if (regime.type === channel) {
                let program = {};

                if (regime.program.active_srv === true) {
                  //let day = new Date().getDay() === 0 ? 7 : new Date().getDay();
                  let hour = new Date().getHours();
                  //if (
                  //day >= regime.program.start_day && day <= regime.program.stop_day ||
                  //day === regime.program.start_day || day === regime.program.stop_day
                  //) {


                  regime.program.periods.forEach(period => {
                    if (
                      (period.start.hour <= hour && hour <= period.stop.hour) ||
                      (period.start.hour >= hour && hour < period.stop.hour && period.name === 'night') ||
                      (period.start.hour <= hour && hour >= period.stop.hour && period.name === 'night')

                    ) {
                      let start = `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${period.start.minute < 10 ? "0" + period.start.minute : period.start.minute}`;
                      let stop = `${period.stop.hour < 10 ? "0" + period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? "0" + period.stop.minute : period.stop.minute}`;

                      program = {
                        type: channel,
                        id: regime.program.id,
                        period: period.name,
                        title: period.title,
                        range: `${start} - ${stop}`,
                        name: regime.program.name,
                        active_srv: regime.program.active_srv,
                      }
                    }
                  });
                  //}

                  zone.programs.push(program);
                }
              }
            });
          }
        });

        if (zone.errors !== null) {
          zone.errors.forEach(element => {
            if (element.code === 50) {
              zone.devs.forEach(dev => {
                if (dev.id === element.dev_id) {
                  element.text = `Нет связи с датчиком (${dev.type}#${dev.addr})`;
                }
              });
            }

            if (element.code === 51) element.text = 'Ошибка датчика влажности';
            if (element.code === 52) element.text = 'Ошибка датчика CO2';
            if (element.code === 53) element.text = 'Ошибка часов';
            if (element.code === 54) element.text = 'Ошибка сенсора экрана';
            if (element.code === 55) element.text = 'Ошибка внешней flash';
          });
        }

        zone.current = false;
        zone.extended = false;
        zone.cancellation = true;
        zone.validation = false;
      });

      state.deleting = true;
    },
    updateUnitTasks(state, payload) {
      state.tasks = payload.data;
    },
    updateUnitConfig(state, payload) {
      state.config.params = [{
          id: 0,
          element: 'dropdown',
          type: 'simple',
          name: 'unit_type',
          title: 'Тип системы',
          value: payload.unit_type,
          error: null,
          status: 'default',
          categories: [{
              id: 1,
              name: '1',
              title: 'Uno'
            }, {
              id: 2,
              name: '2',
              title: 'Multi'
            },
            {
              id: 3,
              name: '3',
              title: 'Profi'
            },
          ],
        },
        {
          id: 1,
          element: 'dropdown',
          type: 'simple',
          name: 'unit_architecture',
          title: 'Архитектура системы',
          value: payload.unit_architecture,
          error: null,
          status: 'default',
          categories: [{
              id: 1,
              name: '1',
              title: 'Лучевая'
            }, {
              id: 2,
              name: '2',
              title: 'Кольцевая'
            },
            {
              id: 3,
              name: '4',
              title: 'Комбинированная'
            },
          ],
        },
        {
          id: 2,
          element: 'dropdown',
          type: 'simple',
          name: 'pump_model',
          title: 'Модель насоса',
          value: payload.pump_model,
          error: null,
          status: 'default',
          categories: [{
            id: 1,
            name: '1',
            title: 'Pro max'
          }, {
            id: 2,
            name: '2',
            title: 'Pro'
          }, ],
        },
        {
          id: 3,
          element: 'dropdown',
          type: 'simple',
          name: 'pump_block',
          title: 'Насосный блок',
          value: payload.pump_block,
          error: null,
          status: 'default',
          categories: [{
            id: 1,
            name: '1',
            title: 'WetPRO-18'
          }, {
            id: 2,
            name: '2',
            title: 'WetPRO-20'
          }, ],
        },
        {
          id: 4,
          element: 'dropdown',
          type: 'simple',
          name: 'water_prepare',
          title: 'Водоподготовка',
          value: payload.water_prepare,
          error: null,
          status: 'default',
          categories: [{
            id: 1,
            name: '1',
            title: 'Clean-018'
          }, {
            id: 2,
            name: '2',
            title: 'Clean-020'
          }, ],
        },
      ];
    },
    updateUnitState(state, payload) {
      let formatTime = (function () {
        function num(val) {
          val = Math.floor(val);
          return val < 10 ? '0' + val : val;
        }

        return function (ms) {
          let sec = ms / 1000,
            hours = sec / 3600 % 24,
            minutes = sec / 60 % 60,
            seconds = sec % 60;

          return num(hours) + ":" + num(minutes) + ":" + num(seconds);
        };
      })();

      let zones = JSON.parse(JSON.stringify(payload.zones));

      state.state.p_in = payload.p_in;
      state.state.p_out = payload.p_out;
      state.state.valve_in = payload.valve_in;
      state.state.valve_drain = payload.valve_drain;
      state.state.pump_rpm = payload.pump_rpm;
      state.state.active_srv = payload.active_srv;
      state.state.status = payload.status;
      state.state.timer = payload.timer;
      state.state.errors = payload.errors;
      state.state.zones = zones.sort((a, b) => a.num - b.num);
      state.state.timer = formatTime(state.state.timer * 1000);

      if (state.state.status === 0) state.state.status = 'Ожидание';
      if (state.state.status === 1) state.state.status = 'Набор давления';
      if (state.state.status === 2) state.state.status = 'Активный режим';
      if (state.state.status === 3) state.state.status = 'Снижение давления';
      if (state.state.status === 4) state.state.status = 'Сброс воды';
      if (state.state.status === 5) state.state.status = 'Цикл завершён';
      if (state.state.status === 10) state.state.status = 'Пауза';
      if (state.state.status === 11) state.state.status = 'Ручной режим';
      if (state.state.status === 101) state.state.status = 'Недостаточное входное давление';
      if (state.state.status === 102) state.state.status = 'Высокое входное давление';
      if (state.state.status === 103) state.state.status = 'Не удалось набрать выходное давление';
      if (state.state.status === 104) state.state.status = 'Не удалось поддерживать выходное давление';
      if (state.state.status === 105) state.state.status = 'Превышено выходное давление';
      if (state.state.status === 106) state.state.status = 'Не удалось снизить давление';
      if (state.state.status === 107) state.state.status = 'Не удалось сбросить воду';
      if (state.state.status === 108) state.state.status = 'Ошибка связи с насосом';
      if (state.state.status === 109) state.state.status = 'Авария, утечка';

      if (state.state.errors !== null) {
        state.state.errors.forEach(element => {
          if (element.code === 1) element.text = 'Ошибка ethernet';
          if (element.code === 2) element.text = 'Ошибка часов';
          if (element.code === 3) element.text = 'Ошибка внешней flash';
          if (element.code === 4) element.text = 'Ошибка радиомодуля';
          if (element.code === 5) element.text = 'Ошибка модуля расширения';
          if (element.code === 6) element.text = 'Ошибка сервера времени';
          if (element.code === 7) element.text = 'Ошибка DNS';
          if (element.code === 8) element.text = 'Ошибка связи с сервером';
          if (element.code === 9) element.text = 'Ошибка насоса';
          if (element.code === 10) element.text = 'Превышено число попыток набора давления';
        });
      }

      if (state.state.network) {
        if (state.state.network.type === 'request') {
          state.state.network.status = 2;
          state.state.network.type = null;
        } else {
          state.state.network.status = 1;
          state.state.network.indicator = 'blink';
        }

        setTimeout(() => state.state.network.indicator = 'default', 1000);
        clearInterval(state.state.network.timer)

        state.state.network.timer = null;
        state.state.network.countdown = 30;

        state.state.network.timer = setInterval(() => {
          state.state.network.countdown--;

          if (state.state.network.countdown <= 1) {
            state.state.network.countdown = 30;
            state.state.network.status = 0;
            state.state.network.indicator = 'off';

            setTimeout(() => {
              clearInterval(state.state.network.timer);
              state.state.network.timer = null;
            }, 1000);
          }
        }, 1000);
      }
    },
    updateUnitControl(state, payload) {
      let control = JSON.parse(JSON.stringify(payload));

      state.control.manual_control = control.manual_control;
      state.control.valve_drain = control.valve_drain;
      state.control.valve_in = control.valve_in;
      state.control.pump = control.pump;
      state.control.zones = control.zones.sort((a, b) => a.num - b.num);
    },
    updateUnitFlush(state, payload) {
      if (payload.board.flush) {
        let flush = JSON.parse(JSON.stringify(payload.board.flush));

        state.flush.active = flush.active;
        state.flush.start = {
          day: flush.start.day,
          day_num: flush.start.day === 0 ? 7 : flush.start.day,
          hour: flush.start.hour,
          minute: flush.start.minute,
        }
        state.flush.stop = {
          day: flush.stop.day,
          day_num: flush.stop.day === 0 ? 7 : flush.stop.day,
          hour: flush.stop.hour,
          minute: flush.stop.minute,
        }
      }
    },
    updateUnitFeatures(state, payload) {
      state.features.params = [{
          id: 0,
          name: 'p_in_min',
          title: 'Входное давление min (бар)',
          value: payload.board.params.features.p_in_min,
          error: null,
          status: 'default',
        },
        {
          id: 1,
          name: 'p_in_max',
          title: 'Входное давление max (бар)',
          value: payload.board.params.features.p_in_max,
          error: null,
          status: 'default',
        },
        {
          id: 2,
          name: 'p_out_min',
          title: 'Выходное давление min (бар)',
          value: payload.board.params.features.p_out_min,
          error: null,
          status: 'default',
        },
        {
          id: 3,
          name: 'p_out_nom',
          title: 'Выходное давление nom (бар)',
          value: payload.board.params.features.p_out_nom,
          error: null,
          status: 'default',
        },
        {
          id: 4,
          name: 'p_out_max',
          title: 'Выходное давление max (бар)',
          value: payload.board.params.features.p_out_max,
          error: null,
          status: 'default',
        },
        {
          id: 5,
          name: 'p_precharge',
          title: 'P предварительное (бар)',
          value: payload.board.params.features.p_precharge,
          error: null,
          status: 'default',
        },
        {
          id: 6,
          name: 'precharge_timeout',
          title: 'Таймаут набора давления (с)',
          value: payload.board.params.features.precharge_timeout,
          error: null,
          status: 'default',
        },
        {
          id: 7,
          name: 'p_delay',
          title: 'Задержка контроля давления (с)',
          value: payload.board.params.features.p_delay,
          error: null,
          status: 'default',
        },
        {
          id: 8,
          name: 'p_timeout',
          title: 'Таймаут ошибки давления (c)',
          value: payload.board.params.features.p_timeout,
          error: null,
          status: 'default',
        },
        {
          id: 9,
          name: 'pump_rpm',
          title: 'Vmax (об/мин)',
          value: payload.board.params.features.pump_rpm,
          error: null,
          status: 'default',
        },
        {
          id: 10,
          name: 'Kp',
          title: 'Регулятор давления Кп',
          value: payload.board.params.features.Kp,
          error: null,
          status: 'default',
        },
        {
          id: 11,
          name: 'Ki',
          title: 'Регулятор давления Ки',
          value: payload.board.params.features.Ki,
          error: null,
          status: 'default',
        },
        {
          id: 12,
          name: 'Kd',
          title: 'Регулятор давления Кд',
          value: payload.board.params.features.Kd,
          error: null,
          status: 'default',
        },
        {
          id: 13,
          name: 'p_drain',
          title: 'Давление сброса',
          value: payload.board.params.features.p_drain,
          error: null,
          status: 'default',
        },
        {
          id: 14,
          name: 'discharge_timeout',
          title: 'Таймаут снижения давления (с)',
          value: payload.board.params.features.discharge_timeout,
          error: null,
          status: 'default',
        },
        {
          id: 15,
          name: 't_drain',
          title: 'Время сброса',
          value: payload.board.params.features.t_drain,
          error: null,
          status: 'default',
        },
        {
          id: 16,
          name: 't_open',
          title: 'Длительность работы',
          value: payload.board.params.features.t_open,
          error: null,
          status: 'default',
        },
        {
          id: 17,
          name: 't_close',
          title: 'Длительность паузы',
          value: payload.board.params.features.t_close,
          error: null,
          status: 'default',
        },
        {
          id: 18,
          name: 'hum_min',
          title: 'Минимальная влажность зон',
          value: payload.board.params.features.hum_min,
          error: null,
          status: 'default',
        },
        {
          id: 19,
          name: 'hum_max',
          title: 'Максимальная влажность зон',
          value: payload.board.params.features.hum_max,
          error: null,
          status: 'default',
        },
        {
          id: 20,
          name: 'temp_min',
          title: 'Минимальная температура зон',
          value: payload.board.params.features.temp_min,
          error: null,
          status: 'default',
        }, {
          id: 21,
          name: 'temp_max',
          title: 'Максимальная температура зон',
          value: payload.board.params.features.temp_max,
          error: null,
          status: 'default',
        },
        {
          id: 22,
          name: 'carb_min',
          title: 'Минимальный уровень CO2 зон',
          value: payload.board.params.features.carb_min,
          error: null,
          status: 'default',
        }, {
          id: 23,
          name: 'carb_max',
          title: 'Максимальный уровень CO2 зон',
          value: payload.board.params.features.carb_max,
          error: null,
          status: 'default',
        },
      ];
    },
    updateUnitNetwork(state, payload) {
      state.network.params = [{
          id: 0,
          name: 'MAC',
          title: 'MAC',
          value: payload.board.params.network.MAC,
          error: null,
          status: "default",
        },
        {
          id: 1,
          name: 'IP',
          title: 'IP',
          value: payload.board.params.network.IP,
          error: null,
          status: "default",
        },
        {
          id: 2,
          name: 'mask',
          title: 'Маска',
          value: payload.board.params.network.mask,
          error: null,
          status: "default",
        },
        {
          id: 3,
          name: 'gateway',
          title: 'Основной шлюз',
          value: payload.board.params.network.gateway,
          error: null,
          status: "default",
        },
        {
          id: 4,
          name: 'DNS',
          title: 'DNS',
          value: payload.board.params.network.DNS,
          error: null,
          status: "default",
        },
      ];
    },
    updateUnitFirmware(state, payload) {
      let firmware = JSON.parse(JSON.stringify(payload.board));

      state.firmwares = [{
          id: 0,
          version: '1.1.0',
          date: 1664603460,
          file: null,
        },
        {
          id: 1,
          version: '1.1.1',
          date: 1664603490,
          file: null,
        }
      ];
      state.firmware.version = firmware.version;
      state.firmware.processing = false;
      state.firmware.list = {
        element: 'dropdown',
        type: 'simple',
        name: 'firmware_list',
        value: null,
        error: null,
        status: 'default',
        categories: [],
      };
      state.firmware.file = null;

      state.firmwares.forEach(item => {
        let obj = {
          id: item.id,
          name: item.version,
          title: `Прошивка ${item.version}`
        }

        state.firmware.list.categories.push(obj);
      });
    },
    updateUnitModes(state, payload) {
      console.log(payload);

      state.modes = [{
          id: 0,
          name: 'flush',
          title: 'Режим промывки бака',
          active: false,
          start: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          stop: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          priority: 0,
        },
        {
          id: 1,
          name: 'group',
          title: 'Режим деления на группы',
          active: false,
          start: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          stop: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          priority: 1,
        },
        {
          id: 2,
          name: 'ring',
          title: 'Режим кольцо',
          active: false,
          start: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          stop: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          priority: 2,
        }, {
          id: 3,
          name: 'vacation',
          title: 'Режим отпуска',
          active: false,
          start: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          stop: {
            day: 0,
            hour: 0,
            minute: 0,
          },
          priority: 3,
        }
      ]
    },

    handleUnitChanges(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        if (payload.section === 'about') {
          if (isEmpty(state.backup.about)) {
            state.backup.about = JSON.parse(JSON.stringify(state.about));
          }

          state.about.cancellation = false;
          state.about.validation = true;

          if (payload.name === 'name' || payload.name === 'address') {
            if (state.about[payload.name].name === payload.name) {
              state.about[payload.name].value = payload.value;
              state.about.extended = true;

              if (payload.value.match(state.about[payload.name].pattern)) {
                state.about[payload.name].status = 'valid';
                state.about[payload.name].error = false;
              } else {
                state.about[payload.name].status = 'invalid';
                state.about[payload.name].error = true;
              }
            }
          } else if (
            payload.name === 'date_start' ||
            payload.name === 'date_install' ||
            payload.name === 'date_warranty' ||
            payload.name === 'date_maintenance_last' ||
            payload.name === 'date_maintenance_plan') {
            if (state.about[payload.name].name === payload.name) {
              state.about[payload.name].value = Math.round(payload.value);

              if (payload.name === 'date_maintenance_last' || payload.name === 'date_maintenance_plan') state.about.extended = true;

              if (String(payload.value).match(state.about[payload.name].pattern)) {
                state.about[payload.name].status = 'valid';
                state.about[payload.name].error = false;
              } else {
                state.about[payload.name].status = 'invalid';
                state.about[payload.name].error = true;
              }
            }
          } else if (payload.name === 'vacation') {
            state.about[payload.name].active_srv = payload.value;
            state.about[payload.name].changed = true;
          } else {
            state.about.owner.forEach(param => {
              if (param.name === payload.name) {
                param.value = payload.value;

                if (payload.value.match(param.pattern)) {
                  param.status = 'valid';
                  param.error = false;
                } else {
                  param.status = 'invalid';
                  param.error = true;
                }
              }
            });
          }

          state.about.owner.forEach(param => {
            if (param.status === 'invalid' || state.about.name.status === 'invalid' || state.about.address.status === 'invalid') {
              state.about.validation = false;
            }
          });
        }

        if (payload.section === 'config') {
          if (isEmpty(state.backup.config)) {
            state.backup.config = JSON.parse(JSON.stringify(state.config));
          }

          state.config.params.forEach(param => {
            if (param.name === payload.name) {
              param.value = payload.value;
              param.status = 'valid';
            }
          });

          state.config.cancellation = false;
          state.config.validation = true;

          state.config.params.forEach(param => {
            if (param.status === 'invalid') {
              state.config.validation = false;
            }
          });
        }

        if (payload.section === 'control') {
          if (isEmpty(state.backup.control)) {
            state.backup.control = JSON.parse(JSON.stringify(state.control));
          }

          if (payload.name === 'manual_control') state.control.manual_control = payload.value;
          if (payload.name === 'pump') state.control.pump = payload.value;
          if (payload.name === 'valve_drain') state.control.valve_drain = payload.value;
          if (payload.name === 'valve_in') state.control.valve_in = payload.value;

          state.control.zones.forEach(zone => {
            if (zone.id === payload.id) {
              zone[payload.param].activity = payload.value;
              zone.changed = true;
            }
          });

          state.control.cancellation = false;
          state.control.validation = true;
        }

        if (payload.section === 'flush') {
          if (isEmpty(state.backup.flush)) {
            state.backup.flush = JSON.parse(JSON.stringify(state.flush));
          }

          if (payload.name === 'active') {
            state.flush.active = payload.value;

            state.flush.cancellation = false;
            state.flush.validation = true;
          }

          if (payload.type === 'day') {
            let popup = {
              type: "error",
              params: {
                text: null
              }
            }

            if (payload.stage === 'start') {
              if (payload.num >= state.flush.stop.day_num) {
                popup.params.text = 'День начала промывки должен быть меньше дня окончания промывки.';
                this.commit('createPopup', popup);
              }
            }

            if (payload.stage === 'stop') {
              if (payload.num <= state.flush.start.day_num) {
                popup.params.text = 'День начала промывки должен быть меньше дня окончания промывки.';
                this.commit('createPopup', popup);
              }
            }

            if (!popup.params.text) {
              state.flush[payload.stage][payload.type] = payload.value;
              state.flush[payload.stage][`${payload.type}_num`] = payload.num;

              state.flush.cancellation = false;
              state.flush.validation = true;
            }
          }

          if (payload.type === 'time') {
            let popup = {
              type: "error",
              params: {
                text: null
              }
            }

            let prev = state.flush[payload.stage][payload.unit];
            state.flush[payload.stage][payload.unit] = payload.value;

            let startHour = state.flush.start.hour < 10 ? '0' + state.flush.start.hour : String(state.flush.start.hour);
            let stopHour = state.flush.stop.hour < 10 ? '0' + state.flush.stop.hour : String(state.flush.stop.hour);
            let startMin = state.flush.start.minute < 10 ? '0' + state.flush.start.minute : String(state.flush.start.minute);
            let stopMin = state.flush.stop.minute < 10 ? '0' + state.flush.stop.minute : String(state.flush.stop.minute);


            if (Number(stopHour + stopMin) <= Number(startHour + startMin)) {
              popup.params.text = 'Время начала промывки должен быть меньше времени окончания промывки.';
              this.commit('createPopup', popup);
              state.flush[payload.stage][payload.unit] = prev;
            }

            if (!popup.params.text) {
              state.flush.cancellation = false;
              state.flush.validation = true;
            }
          }
        }

        if (payload.section === 'firmware') {
          if (isEmpty(state.backup.firmware)) {
            state.backup.firmware = JSON.parse(JSON.stringify(state.firmware));
          }

          if (payload.name === 'file') state.firmware.file = payload.value;
          if (payload.name === 'firmware_list') {
            state.firmware.list.value = payload.value;
          }

          state.firmware.cancellation = false;
          state.firmware.validation = true;
        }

        if (payload.section === 'features') {
          if (isEmpty(state.backup.features)) {
            state.backup.features = JSON.parse(JSON.stringify(state.features));
          }

          state.features.params.forEach(param => {
            if (param.name === payload.name) {
              param.value = payload.value;

              if (this.state.common.patterns[payload.name].test(payload.value)) {
                param.status = 'valid';
                param.error = null;
              } else {
                param.status = 'invalid';
                param.error = 'Неверный формат';
              }
            }
          });

          state.features.validation = true;
          state.features.cancellation = false;

          state.features.params.forEach(param => {
            if (param.status === 'invalid') {
              state.features.validation = false;
            }
          });
        }
      }
    },
    handleUnitProperty(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      state.regimes.forEach((regime, index) => {
        if (regime.type === payload.property) {
          if (isEmpty(state.backup.regimes)) {
            state.backup.regimes = JSON.parse(JSON.stringify(state.regimes));
          } else if (isEmpty(state.backup.regimes[index])) {
            state.backup.regimes[index] = JSON.parse(JSON.stringify(state.regimes[index]));
          }

          if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
            if (payload.param === 'programs') {
              regime.programs.forEach(program => {
                if (program.id == +payload.value) {
                  regime.list.value = program.name;
                  regime.program = program;

                  for (let i = 0; i < regime.zones.length; i++) {
                    regime.zones[i][payload.property].programs.forEach(prog => {
                      if (prog.id === +payload.value) {
                        regime.zones[i][payload.property].morning.value = prog.morning;
                        regime.zones[i][payload.property].day.value = prog.day;
                        regime.zones[i][payload.property].evening.value = prog.evening;
                        regime.zones[i][payload.property].night.value = prog.night;
                      }
                    })
                  }
                }
              });
            } else {
              if (payload.param === 'value') {
                if (payload.scope === 'period') {
                  regime.program.period.value = payload.value;

                  regime.zones.forEach(zone => {
                    zone[payload.property][regime.program.period.name].value = payload.value;
                    zone[payload.property][regime.program.period.name].status = 'valid';

                    zone[payload.property].programs.forEach(prog => {
                      if (prog.id === regime.id) {
                        prog[regime.program.period.name] = payload.value;
                      }
                    })
                  });
                } else {
                  regime.program.value = payload.value;
                  regime.program.period.name = null;

                  regime.zones.forEach(zone => {
                    zone[payload.property].morning.value = payload.value;
                    zone[payload.property].morning.status = 'valid';
                    zone[payload.property].day.value = payload.value;
                    zone[payload.property].day.status = 'valid';
                    zone[payload.property].evening.value = payload.value;
                    zone[payload.property].evening.status = 'valid';
                    zone[payload.property].night.value = payload.value;
                    zone[payload.property].night.status = 'valid';

                    zone[payload.property].programs.forEach(prog => {
                      if (prog.id === regime.program.id) {
                        prog.morning = payload.value;
                        prog.day = payload.value;
                        prog.evening = payload.value;
                        prog.night = payload.value;
                      }
                    })
                  });
                }
              }

              if (payload.param === 'active_srv') {
                regime.extended = true;
                regime.program.active_srv = payload.value;
              }

              if (payload.param === 'period') {
                if (payload.type === 'day') {
                  let popup = {
                    type: "error",
                    params: {
                      text: null
                    }
                  }

                  if (regime.program.name === 'Будни') {
                    if (payload.stage === 'start') {
                      if (payload.num >= regime.program.stop_day_num) {
                        popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                        this.commit('createPopup', popup);
                      }
                    }

                    if (payload.stage === 'stop') {
                      if (payload.num <= regime.program.start_day_num) {
                        popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                        this.commit('createPopup', popup);
                      } else if (payload.num >= regime.programs[1].start_day_num) {
                        popup.params.text = 'День окончания программы "Будни" должен быть меньше дня начала программы "Выходные".';
                        this.commit('createPopup', popup);
                      }
                    }
                  }

                  if (regime.program.name === 'Выходные') {
                    if (payload.stage === 'start') {
                      if (payload.num >= regime.program.stop_day_num) {
                        popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                        this.commit('createPopup', popup);
                      } else if (payload.num <= regime.programs[0].stop_day_num) {
                        popup.params.text = 'День начала программы "Выходные" должен быть больше дня окончания программы "Будни".';
                        this.commit('createPopup', popup);
                      }
                    }
                    if (payload.stage === 'stop') {
                      if (payload.num <= regime.program.start_day_num) {
                        popup.params.text = 'День начала программы должен быть меньше дня окончания программы.';
                        this.commit('createPopup', popup);
                      }
                    }
                  }

                  if (!popup.params.text) {
                    regime.program[`${payload.stage}_${payload.type}`] = payload.value;
                    regime.program[`${payload.stage}_${payload.type}_num`] = payload.num;
                  }
                }

                if (payload.type === 'time') {
                  regime.program[payload.stage][payload.unit] = payload.value;
                }
              }

              if (payload.param === 'range') {
                regime.program.periods.forEach((element, index) => {
                  if (element.id == payload.id) {
                    element[payload.stage][payload.unit] = payload.value;

                    if (payload.stage === 'stop') {
                      if (index + 1 === regime.program.periods.length) {
                        regime.program.periods[0].start[payload.unit] = payload.value;
                      } else {
                        regime.program.periods[index + 1].start[payload.unit] = payload.value;
                      }
                    }

                    if (payload.stage === 'start') {
                      if (index === 0) {
                        regime.program.periods[regime.program.periods.length - 1].stop[payload.unit] = payload.value;
                      } else {
                        regime.program.periods[index - 1].stop[payload.unit] = payload.value;
                      }
                    }
                  }
                });
              }

              if (payload.param === 'select') {
                regime.program.period.name = payload.period;
                if (!payload.value) regime.program.period.name = null;
              }

              if (payload.param === "zone") {
                regime.zones.forEach(zone => {
                  if (zone.id == payload.id) {
                    if (payload.value > regime.program.max) payload.value = regime.program.max;
                    if (payload.value < regime.program.min) payload.value = regime.program.min;

                    zone[payload.property][payload.period].value = payload.value;
                    regime.program.period.name = null;

                    if (String(payload.value).match(/^[0-9]{1,2}$/)) {
                      zone[payload.property][payload.period].status = 'valid';
                    } else {
                      zone[payload.property][payload.period].status = 'invalid';
                    }

                    zone[payload.property].programs.forEach(prog => {
                      if (prog.id === regime.program.id)
                        prog[payload.period] = payload.value;
                    })
                  }
                });
              }

              regime.cancellation = false;
              regime.validation = true;

              regime.zones.forEach(zone => {
                if (
                  zone[payload.property].morning.status === 'invalid' ||
                  zone[payload.property].day.status === 'invalid' ||
                  zone[payload.property].evening.status === 'invalid' ||
                  zone[payload.property].night.status === 'invalid'
                ) {
                  regime.validation = false;
                }
              });
            }
          }

          if (payload.event === 'focus') {
            if (payload.param === "zone") {
              regime.zones.forEach(zone => {
                if (zone.id == payload.id) {
                  zone[payload.property][payload.period].unit = '';
                  zone[payload.property][payload.period].control = true;
                  regime.program.period.name = null;
                }
              });
            }
          }

          if (payload.event === 'blur') {
            if (payload.param === "zone") {
              regime.zones.forEach(zone => {
                if (zone.id == payload.id) {
                  if (payload.property === 'hum' && zone[payload.property][payload.period].status !== 'invalid') {
                    zone[payload.property][payload.period].unit = '%';
                  }
                  if (payload.property === 'temp' && zone[payload.property][payload.period].status !== 'invalid') {
                    zone[payload.property][payload.period].unit = '°';
                  }
                }
              });
            }
          }
        }
      });
    },
    handleUnitZone(state, payload) {
      function isEmpty(obj) {
        for (let key in obj) {
          return false;
        }
        return true;
      }

      state.zones.forEach((zone, index) => {
        if (zone.id === payload.id) {
          zone.current = true;

          if (isEmpty(state.backup.zones)) {
            state.backup.zones = JSON.parse(JSON.stringify(state.zones));
          } else if (isEmpty(state.backup.zones[index])) {
            state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
          }
        }
      });

      if (payload.event === 'change' || payload.event === 'input') {
        state.zones.forEach((zone, index) => {
          if (zone.id === payload.id) {
            if (isEmpty(state.backup.zones[index])) {
              state.backup.zones[index] = JSON.parse(JSON.stringify(state.zones[index]));
            }

            if (payload.element === 'input') {
              if (payload.name === 'name') {
                zone[payload.name].value = payload.value;

                if (payload.value.match(/^[а-яА-ЯёЁa-zA-Z0-9\s-/\\]{2,30}$/)) zone[payload.name].status = 'valid';
                else zone[payload.name].status = 'invalid';
              }

              if (payload.name === 'address') {
                zone.device.input.value = payload.value;

                if (payload.value.match(/^[0-9]{1,5}$/)) {
                  zone.device.input.status = 'valid';
                  zone.device.button.disabled = false;
                } else {
                  zone.device.input.status = 'invalid';
                  zone.device.button.disabled = true;
                }
              }

              if (payload.name === 'quan') {
                zone.nozzle.input.value = payload.value;

                if (payload.value.match(/^[0-9]{1,5}$/)) {
                  zone.nozzle.input.status = 'valid';
                  zone.nozzle.button.disabled = false;
                } else {
                  zone.nozzle.input.status = 'invalid';
                  zone.nozzle.button.disabled = true;
                }
              }

              if (payload.name === 'min') {
                zone[payload.param][payload.name].value = payload.value;

                if (payload.value.match(/^[0-9]{1,2}$/)) {
                  if (+payload.value >= zone[payload.param][payload.name].limit && +payload.value < zone[payload.param].max.value) {
                    zone[payload.param][payload.name].value = +payload.value;
                    zone[payload.param][payload.name].status = 'valid';
                  } else {
                    zone[payload.param][payload.name].status = 'invalid';
                  }
                } else {
                  zone[payload.param][payload.name].status = 'invalid';
                }
              }

              if (payload.name === 'max') {
                zone[payload.param][payload.name].value = payload.value;

                if (payload.value.match(/^[0-9]{1,3}$/)) {
                  if (+payload.value <= zone[payload.param][payload.name].limit && +payload.value > zone[payload.param].min.value) {
                    zone[payload.param][payload.name].value = +payload.value;
                    zone[payload.param][payload.name].status = 'valid';
                  } else {
                    zone[payload.param][payload.name].status = 'invalid';
                  }
                } else {
                  zone[payload.param][payload.name].status = 'invalid';
                }
              }
            }

            if (payload.element === "switch") {
              if (payload.param !== null) {
                zone[payload.param].active_srv = payload.value;

                if (zone[payload.param].active_srv === false) {
                  zone[payload.param].setting = zone[payload.param].default;
                  zone[payload.param].status = 'default';
                }
              }
            }

            if (payload.element === 'slider') {
              zone[payload.param][payload.name] = payload.value;
            }

            if (payload.element === 'dropdown') {
              if (payload.name === 'num') {
                if (payload.open) {
                  let common = [...Array(31)].map((el, i) => {
                    return {
                      id: i + 1,
                      name: i + 1,
                      title: `Зона ${i + 1}`
                    }
                  });

                  let arr = this.state.unit.zones;

                  for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < common.length; j++) {
                      if (common[j].id === arr[i].num.val) {
                        common.splice(common.indexOf(common[j]), 1)
                      }
                    }
                  }

                  zone.num.categories = common;
                } else {
                  zone.num.value = payload.value;
                  zone.num.val = +payload.alias;
                }
              }

              if (payload.name === 'device') {
                zone.device.dropdown.value = payload.value;
                zone.device.dropdown.alias = payload.alias;
                zone.device.dropdown.status = 'valid';
              }

              if (payload.name === 'nozzle') {
                zone.nozzle.dropdown.value = payload.value;
                zone.nozzle.dropdown.alias = payload.alias;
                zone.nozzle.dropdown.status = 'valid';
              }
            }

            if (payload.element === 'add') {
              if (payload.block === 'devs') {
                let obj2 = {
                  type: zone.device.dropdown.alias,
                  addr: +zone.device.input.value,
                  index: 0
                };

                zone[payload.block].push(obj2);

                let sIndex = 0;
                let gIndex = 0;

                zone[payload.block].forEach(dev => {
                  if (dev.type === 's') dev.index = ++sIndex;
                  if (dev.type === 'g') dev.index = ++gIndex;
                });

                zone.device.dropdown.value = null;
                zone.device.dropdown.alias = null;
                zone.device.dropdown.status = 'default';

                zone.device.input.value = null;
                zone.device.input.status = 'default';

                zone.device.button.disabled = true;
              }

              if (payload.block === 'nozzles') {
                let obj3 = {
                  type: zone.nozzle.dropdown.alias,
                  quan: +zone.nozzle.input.value,
                };

                zone[payload.block].push(obj3);

                zone.nozzle.dropdown.value = null;
                zone.nozzle.dropdown.alias = null;
                zone.nozzle.dropdown.status = 'default';

                zone.nozzle.input.value = null;
                zone.nozzle.input.status = 'default';

                zone.nozzle.button.disabled = true;
              }
            }

            if (payload.element === 'delete') {
              zone[payload.modules] = zone[payload.modules].filter((element, index) => index !== payload.index);
            }

            if (payload.param === 'hum') {
              if (zone.device.dropdown.status === 'valid' && zone.device.input.status === 'valid') {
                zone.device.button.disabled = false;
              }

              if (zone.nozzle.dropdown.status === 'valid' && zone.nozzle.input.status === 'valid') {
                zone.nozzle.button.disabled = false;
              }
            }

            zone.cancellation = false;
            zone.validation = true;

            if (
              zone.name.status === 'invalid' ||
              zone.hum.status === 'invalid' ||
              zone.temp.status === 'invalid' ||
              zone.hum.min.status === 'invalid' ||
              zone.hum.max.status === 'invalid' ||
              zone.temp.min.status === 'invalid' ||
              zone.temp.max.status === 'invalid'
            ) zone.validation = false;
          }
        });
      }

      if (payload.event === 'focus') {
        state.zones.forEach(element => {
          if (element.id === payload.id) {
            if (payload.param !== null) {
              if (isNaN(+payload.value.slice(0, -1))) element[payload.param][payload.name] = '';
              else element[payload.param][payload.name] = +payload.value.slice(0, -1);

              element[payload.param].unit = '';

            } else {
              element[payload.name] = payload.value;
            }
          }
        });
      }

      if (payload.event === 'blur') {
        state.zones.forEach(element => {
          if (element.id === payload.id) {
            if (payload.param !== null) {
              element[payload.param].unit = '';

              if (element[payload.param].set === '') {
                element[payload.param].set = 0;
                element[payload.param].status = 'valid';
              }

              if (payload.param === 'hum') element.hum.unit = '%';
              if (payload.param === 'temp') element.temp.unit = '°';

              element.cancellation = false;
              element.validation = true;

              if (element.name.status === 'invalid' || element.hum.status === 'invalid' || element.temp.status === 'invalid') element.validation = false;
            }
          }
        });
      }
    },

    resetUnitsState(state) {
      if (state.zones) {
        state.zones.forEach((zone, index) => {
          if (state.backup.zones && state.backup.zones.length > 0) {
            if (zone.id === state.backup.zones[index].id) {
              zone.hum = state.backup.zones[index].hum;
              zone.temp = state.backup.zones[index].temp;
              zone.name = state.backup.zones[index].name;
              zone.num = state.backup.zones[index].num;
              zone.device = state.backup.zones[index].device;
              zone.devs = state.backup.zones[index].devs;
              zone.nozzle = state.backup.zones[index].nozzle;
              zone.nozzles = state.backup.zones[index].nozzles;
              zone.status = 'default';
              state.backup.zones[index] = {};
            }
          }
          zone.current = false;
          zone.cancellation = true;
          zone.validation = false;
          zone.extended = false;
        });
      }
      state.deleting = true;
      //state.hum.current = null;
    },
    cancelUnitChanges(state, payload) {
      if (payload.param === "about") {
        state.about.cancellation = true;
        state.about.validation = false;

        state.about.name.value = state.backup.about.name.value;
        state.about.name.status = 'default';
        state.about.name.error = false;

        state.about.address.value = state.backup.about.address.value;
        state.about.address.status = 'default';
        state.about.address.error = false;

        state.about.date_start.value = state.backup.about.date_start.value;
        state.about.date_start.status = 'default';
        state.about.date_start.error = false;

        state.about.date_install.value = state.backup.about.date_install.value;
        state.about.date_install.status = 'default';
        state.about.date_install.error = false;

        state.about.date_warranty.value = state.backup.about.date_warranty.value;
        state.about.date_warranty.status = 'default';
        state.about.date_warranty.error = false;

        state.about.date_maintenance_last.value = state.backup.about.date_maintenance_last.value;
        state.about.date_maintenance_last.status = 'default';
        state.about.date_maintenance_last.error = false;

        state.about.date_maintenance_plan.value = state.backup.about.date_maintenance_plan.value;
        state.about.date_maintenance_plan.status = 'default';
        state.about.date_maintenance_plan.error = false;

        state.about.vacation = state.backup.about.vacation;

        state.about.owner.forEach((param, index) => {
          param.value = state.backup.about.owner[index].value;
          param.status = 'default';
          param.error = false;
        });

        state.backup.about = {};
      }

      if (payload.param === "hum" || payload.param === "temp" || payload.param === "carb") {
        state.regimes.forEach((regime, index) => {
          if (regime.type === payload.param) {
            regime.cancellation = true;
            regime.validation = false;
            regime.program = state.backup.regimes[index].program;
            regime.list = state.backup.regimes[index].list;
            regime.zones = state.backup.regimes[index].zones;

            state.backup.regimes[index] = {};
          }
        });
      }

      if (payload.param === 'zone') {
        state.zones.forEach((element, index) => {
          if (element.id == payload.id) {
            element.hum = state.backup.zones[index].hum;
            element.temp = state.backup.zones[index].temp;
            element.name = state.backup.zones[index].name;
            element.device = state.backup.zones[index].device;
            element.devs = state.backup.zones[index].devs;
            element.nozzle = state.backup.zones[index].nozzle;
            element.nozzles = state.backup.zones[index].nozzles;
            element.status = 'default';
            element.cancellation = true;
            element.validation = false;
            element.extended = false;

            state.backup.zones[index] = {};
          }
        });
      }

      if (payload.param === "config") {
        state.config.cancellation = true;
        state.config.validation = false;

        state.config.params.forEach((param, index) => {
          param.value = state.backup.config.params[index].value;
          param.status = 'default';
          param.error = false;
        });

        state.backup.config = {};
      }

      if (payload.param === "control") {
        state.control.manual_control = state.backup.control.manual_control;
        state.control.valve_drain = state.backup.control.valve_drain;
        state.control.valve_in = state.backup.control.valve_in;
        state.control.pump = state.backup.control.pump;
        state.control.zones = state.backup.control.zones;
        state.control.cancellation = true;
        state.control.validation = false;

        state.backup.control = {};
      }

      if (payload.param === "flush") {
        state.flush.active = state.backup.flush.active;
        state.flush.start = state.backup.flush.start;
        state.flush.stop = state.backup.flush.stop;
        state.flush.cancellation = true;
        state.flush.validation = false;

        state.backup.flush = {};
      }

      if (payload.param === "features") {
        state.features.cancellation = true;
        state.features.validation = false;

        state.features.params.forEach((param, index) => {
          param.value = state.backup.features.params[index].value;
          param.status = 'default';
          param.error = false;
        });

        state.backup.features = {};
      }

      if (payload.param === "firmware") {
        state.firmware.version = state.backup.firmware.version;
        state.firmware.file = null;
        state.firmware.processing = false;
        state.firmware.list.value = null;

        state.firmware.cancellation = true;
        state.firmware.validation = false;

        state.backup.firmware = {};
      }
    },
    clearUnitSection(state, payload) {
      state[payload].cancellation = true;
      state[payload].validation = false;
      state.backup[payload] = {};
    },
    clearUnitState(state) {
      state.navbar = {};
      state.about = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.regimes = [{
          id: 0,
          type: 'hum',
          current: null,
          programs: [],
          list: {
            categories: [],
          },
          previous: null,
          extended: localStorage.getItem('unit__hum-extended') === 'true' ? true : false || false,
          cancellation: true,
          validation: false,
        },
        {
          id: 1,
          type: 'temp',
          programs: [],
          list: {
            categories: [],
          },
          previous: null,
          extended: localStorage.getItem('unit__temp-extended') === 'true' ? true : false || false,
          cancellation: true,
          validation: false,
        },
        {
          id: 2,
          type: 'carb',
          programs: [],
          list: {
            categories: [],
          },
          previous: null,
          extended: localStorage.getItem('unit__carb-extended') === 'true' ? true : false || false,
          cancellation: true,
          validation: false,
        }
      ];
      state.zones = [];
      state.tasks = [];
      state.config = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.state = {
        network: {
          type: null,
          status: null,
          indicator: null,
          timer: null,
          countdown: null,
        },
        extended: false,
      };
      state.control = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.flush = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.features = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.firmware = {
        extended: false,
        cancellation: true,
        validation: false,
      };
      state.network = {
        extended: false,
      };
      state.tasks = [];
      state.deleting = false;
      state.backup = {};
    },
    updateUnitAboutSocket(state, payload) {
      if (payload.mileage) state.about.mileage = +payload.mileage * 10000;
      if (payload.pump_hours) state.about.pump_hours = payload.pump_hours;
    },
    updateUnitZonesSocket(state, payload) {
      if (state.zones !== undefined && state.zones.length > 0) {
        state.zones.forEach((zone, index) => {
          if (zone.id === payload.zones[index].id && !zone.current) {
            zone.hum.cur = payload.zones[index].hum.cur;
            zone.hum.set = payload.zones[index].hum.set;
            zone.hum.activity = payload.zones[index].hum.activity;

            zone.temp.cur = payload.zones[index].temp.cur;
            zone.temp.set = payload.zones[index].temp.set;
            zone.temp.activity = payload.zones[index].temp.activity;

            /* state.hum.programs.forEach(program => {
              if (program.active_srv === true) {
                let day = new Date().getDay() === 0 ? 7 : new Date().getDay();
                let hour = new Date().getHours();

                if (
                  day >= program.start_day && day <= program.stop_day ||
                  day === program.start_day || day === program.stop_day
                ) {
                  program.periods.forEach(period => {
                    if (
                      (period.start.hour <= hour && hour <= period.stop.hour) ||
                      (period.start.hour >= hour && hour < period.stop.hour && period.name === 'night') ||
                      (period.start.hour <= hour && hour >= period.stop.hour && period.name === 'night')

                    ) {
                      let start = `${period.start.hour < 10 ? "0" + period.start.hour : period.start.hour}:${period.start.minute < 10 ? "0" + period.start.minute : period.start.minute}`;
                      let stop = `${period.stop.hour < 10 ? "0" + period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? "0" + period.stop.minute : period.stop.minute}`;

                      zone.program = {
                        id: program.id,
                        period: period.name,
                        title: period.title,
                        range: `${start} - ${stop}`,
                        name: program.name
                      }
                    }
                  });

                  state.hum.zones.forEach(elem => {
                    if (elem.id === zone.id) {

                      elem.hum.programs.forEach(prog => {
                        if (prog.id === zone.program.id) {
                          zone.hum.set = prog[zone.program.period]
                        }
                      });
                    }
                  });
                }
              }
            }); */
          }
        });
      }
    },
  },
  actions: {
    fetchUnit(context, payload) {
      if (payload === 'default') {
        let payload = {
          visible: true,
          title: 'Уведомление!',
          text: `
              В настоящий момент не выбрана ни одна система. Перейдите к списку
              систем и выберите необходимую.
              `,
        };

        context.commit('createNotification', payload);
      } else {
        context.commit("showPreloader");
        context.commit("destroyNotification");
        context.commit("clearUnitState");

        axios
          .get(`https://api.airwet.app/api/getSystem/${payload}`, this.state.common.headers)
          .then((response) => {
            context.state.state.network.type = 'request';

            context.commit('updateUnitNavbar', response.data);
            context.commit('updateUnitAbout', response.data);
            context.commit('updateUnitRegimes', response.data);
            context.commit('updateUnitZones', response.data);

            context.commit("hidePreloader");
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    fetchUnitTasks(context) {
      context.commit("showPreloader");

      axios
        .get(`https://api.airwet.app/api/tasks?filters[executor][id][$eq]=2&pagination[start]=${this.state.common.pagination.start}&pagination[limit]=${this.state.common.pagination.limit}`, this.state.common.headers)
        .then((response) => {
          context.commit('updateUnitTasks', response.data);
          /* context.commit('createPagination', response.data.meta); */
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeUnitActiveStatus(context, payload) {
      context.commit("showPreloader");

      let unit = {
        data: {
          active_srv: payload.active,
          active_user: context.state.about.active_user,
          errors: context.state.about.errors,
          sender: 'engineer',
        },
      }

      axios
        .put(`https://api.airwet.app/api/updateUnit/${payload.unit}`, unit, this.state.common.headers)
        .then((response) => {
          context.commit('updateUnitAbout', response.data);
          context.commit('updateUnitZones', response.data);
          /* context.commit('updateUnitRegimes', response.data); */

          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeUnitOwner(context, payload) {
      context.commit("showPreloader");

      if (payload.owner === 'new') {
        let acronym = '';

        if (payload.first_name !== null && payload.last_name !== null) {
          acronym = `${payload.first_name.substr(0, 1)}${payload.last_name.substr(0, 1)}`;
        }

        axios
          .post("https://api.airwet.app/api/auth/local/register", {
            username: payload.email.substring(0, payload.email.indexOf('@')),
            email: payload.email,
            password: payload.password,
            first_name: payload.first_name,
            last_name: payload.last_name,
            middle_name: payload.middle_name,
            phone: payload.phone,
            acronym: acronym,
            first_entry: true,
          }, this.state.common.headers)
          .then((user) => {
            axios
              .post("https://api.airwet.app/api/users/sendMailToNewUser", {
                user_id: user.data.user.id,
                password: payload.password,
              }, this.state.common.headers)
              .then(() => {
                let unit = {
                  data: {
                    from_user: user.data.user.id
                  },
                }

                axios
                  .put(`https://api.airwet.app/api/updateUnit/${context.state.about.id}`, unit, this.state.common.headers)
                  .then((response) => {
                    context.commit('updateUnitAbout', response.data);
                    context.commit("hidePreloader");
                  })
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload.owner === 'exist') {
        axios
          .get(`https://api.airwet.app/api/users/GetUserIdFromEmail/${payload.email}`, this.state.common.headers)
          .then((user) => {
            let unit = {
              data: {
                from_user: user.data.data.id
              },
            }

            axios
              .put(`https://api.airwet.app/api/updateUnit/${context.state.about.id}`, unit, this.state.common.headers)
              .then((response) => {
                context.commit('updateUnitAbout', response.data);
                context.commit("hidePreloader");
              })
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    addUnitDocument(context, payload) {
      context.commit("showPreloader");

      let formData = new FormData();
      formData.append("files", payload.file);

      axios
        .post("https://api.airwet.app/api/upload", formData, this.state.common.headers)
        .then((file) => {
          axios
            .post("https://api.airwet.app/api/documents", {
              data: {
                name: payload.name,
                file: file.data[0].id,
                from_unit: context.state.about.id,
              }
            }, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateUnitAbout', response.data);
                  /* context.commit('updateUnitRegimes', response.data); */
                  context.commit('updateUnitZones', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    deleteUnitDocument(context, payload) {
      context.commit("showPreloader");

      axios
        .delete(`https://api.airwet.app/api/upload/files/${payload.file}`, this.state.common.headers)
        .then(() => {
          axios
            .delete(`https://api.airwet.app/api/documents/${payload.id}`, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateUnitAbout', response.data);
                  /* context.commit('updateUnitRegimes', response.data); */
                  context.commit('updateUnitZones', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    resetUnitErrors(context, payload) {
      let unit = {
        data: {
          errors: context.state.about.errors.filter(item => item.code === payload.error).map(item => item.code),
        },
      }

      axios
        .put(`https://api.airwet.app/api/resetErrors/${context.state.about.id}`, unit, this.state.common.headers)
        .then(() => {
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    sendUnitChanges(context, payload) {
      context.commit("showPreloader");

      if (payload === 'about') {
        let unit = {
          data: {
            name: context.state.about.name.value,
            address: context.state.about.address.value,
            date_start: context.state.about.date_start.value,
            date_install: context.state.about.date_install.value,
            date_warranty: context.state.about.date_warranty.value,
            date_maintenance_last: context.state.about.date_maintenance_last.value,
            date_maintenance_plan: context.state.about.date_maintenance_plan.value,
          },
        }

        axios
          .put(`https://api.airwet.app/api/updateUnit/${context.state.about.id}`, unit, this.state.common.headers)
          .then((response) => {
            if (context.state.about.vacation.changed) {
              let mode = {
                data: {
                  active_srv: context.state.about.vacation.active_srv,
                }
              };

              axios
                .put(`https://api.airwet.app/api/modes/${context.state.about.vacation.id}`, mode, this.state.common.headers)
                .then(() => {
                  context.commit('clearUnitSection', payload);
                  context.commit("hidePreloader");
                })
                .catch((error) => context.commit("handleError", error));
            } else {
              context.commit('updateUnitAbout', response.data);
              /* context.commit('updateUnitRegimes', response.data); */
              context.commit('updateUnitZones', response.data);

              context.commit('clearUnitSection', payload);
              context.commit("hidePreloader");
            }
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'config') {
        let data = {};
        let prepare = {};

        context.state.config.params.forEach(param => {
          prepare[param.name] = param.value;
        });

        data = {
          data: prepare,
        }

        axios
          .put(`https://api.airwet.app/api/updateUnit/${context.state.about.id}`, data, this.state.common.headers)
          .then(() => {
            axios
              .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateUnitAbout', response.data);
                context.commit('updateUnitConfig', response.data);
                context.commit("hidePreloader");

                context.state.config.cancellation = true;
                context.state.config.validation = false;
                context.state.backup.config = {};
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'control') {
        let zones = JSON.parse(JSON.stringify(context.state.control.zones));

        for (let i = 0; i < zones.length; i++) {
          zones[i] = {
            id: zones[i].id,
            num: zones[i].num,
            hum: !zones[i].hum ? null : {
              id: zones[i].hum.id,
              activity: zones[i].hum.activity
            },
            temp: !zones[i].temp ? null : {
              id: zones[i].temp.id,
              activity: zones[i].temp.activity
            },
            carb: !zones[i].carb ? null : {
              id: zones[i].carb.id,
              activity: zones[i].carb.activity
            }
          }
        }

        let common = {
          data: {
            manual_control: context.state.control.manual_control,
            pump: context.state.control.pump,
            valve_drain: context.state.control.valve_drain,
            valve_in: context.state.control.valve_in,
            zones: zones,
          }
        }

        axios
          .put(`https://api.airwet.app/api/units/${context.state.about.id}/manualControl`, common, this.state.common.headers)
          .then(() => {
            context.commit("hidePreloader");
            context.state.control.cancellation = true;
            context.state.control.validation = false;
            context.state.backup.control = {};
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'flush') {
        let data = {};
        let id = localStorage.getItem('unit__board-id');

        data = {
          data: {
            flush: {
              active: context.state.flush.active,
              start: {
                day: context.state.flush.start.day,
                hour: context.state.flush.start.hour,
                minute: context.state.flush.start.minute,
              },
              stop: {
                day: context.state.flush.stop.day,
                hour: context.state.flush.stop.hour,
                minute: context.state.flush.stop.minute,
              },
            },
          }
        }

        axios
          .put(`https://api.airwet.app/api/boards/${id}`, data, this.state.common.headers)
          .then(() => {
            axios
              .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateUnitFlush', response.data);
                context.commit('clearUnitSection', payload);
                context.commit("hidePreloader");
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'features') {
        let data = {};
        let features = {};
        let network = {};
        let id = localStorage.getItem('unit__board-id');

        context.state.features.params.forEach(param => features[param.name] = +param.value);
        context.state.network.params.forEach(param => network[param.name] = param.value);

        data = {
          data: {
            params: {
              features: features,
              network: network,
            },
          }
        }

        axios
          .put(`https://api.airwet.app/api/boards/${id}`, data, this.state.common.headers)
          .then(() => {
            axios
              .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
              .then((response) => {
                context.commit('updateUnitAbout', response.data);
                context.commit('updateUnitZones', response.data);
                /* context.commit('updateUnitRegimes', response.data); */
                context.commit('updateUnitFeatures', response.data);
                context.commit('clearUnitSection', payload);
                context.commit("hidePreloader");
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload === 'firmware') {
        context.state.firmware.cancellation = true;
        context.state.firmware.validation = false;
        context.state.firmware.processing = true;

        setTimeout(() => {
          context.state.firmware.processing = false;
          context.state.firmware.file = null;
          context.state.firmware.list.value = null;
          context.state.firmware.version = '1.0.2';
        }, 5000);
        context.commit("hidePreloader");
      }
    },
    changeUnitRegime(context, payload) {
      context.commit("showPreloader");

      context.state.regimes.forEach((regime, index) => {
        if (regime.program.id === payload.id) {
          let program = {
            data: regime.program,
          }

          program.data.periods.forEach(period => {
            period.start = `${period.start.hour < 10 ? '0'+ period.start.hour : period.start.hour}:${period.start.minute < 10 ? '0'+ period.start.minute : period.start.minute}`;
            period.stop = `${period.stop.hour < 10 ? '0'+ period.stop.hour : period.stop.hour}:${period.stop.minute < 10 ? '0'+ period.stop.minute : period.stop.minute}`;
          });

          let programZones = regime.zones;
          let backupZones = context.state.backup.regimes[index].zones;

          regime.previous = regime.program;

          axios
            .put(`https://api.airwet.app/api/programs/${program.data.id}`, program, this.state.common.headers)
            .then(() => {
              let endpoints = [];

              for (let i = 0; i < programZones.length; i++) {
                if (JSON.stringify(programZones[i]) !== JSON.stringify(backupZones[i])) {
                  let obj = {
                    url: `https://api.airwet.app/api/zones/${regime.zones[i].id}`,
                    payload: {
                      data: {
                        [regime.type]: {
                          id: regime.zones[i][regime.type].id,
                          programs: regime.zones[i][regime.type].programs
                        }
                      },
                    },
                  };

                  endpoints[i] = obj;
                }
              }

              if (endpoints.length > 0) {
                axios.all(endpoints.map((endpoint) => axios.put(endpoint.url, endpoint.payload, this.state.common.headers)))
                  .then(() => {
                    axios
                      .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                      .then((response) => {
                        context.commit('updateUnitAbout', response.data);
                        context.commit('updateUnitRegimes', response.data);
                        context.commit('updateUnitZones', response.data);
                        context.commit("hidePreloader");
                      })
                  })
                  .catch((error) => context.commit("handleError", error));
              } else {
                axios
                  .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                  .then((response) => {
                    context.commit('updateUnitAbout', response.data);
                    context.commit('updateUnitRegimes', response.data);
                    context.commit('updateUnitZones', response.data);

                    context.commit("hidePreloader");
                  })
                  .catch((error) => context.commit("handleError", error));
              }

              context.state.backup.regimes[index] = {};
            })
            .catch((error) => context.commit("handleError", error));
        }
      });
    },
    addUnitZone(context, payload) {
      context.commit("showPreloader");

      let zone = {
        name: payload.name,
        num: payload.num,
        devs: payload.devs,
        nozzles: payload.nozzles,
        from_unit: payload.from_unit,
      }

      for (const key in payload) {
        if ((key === 'hum' || key === 'temp' || key === 'carb') && payload[key].status === true) {
          zone[key] = {
            set: payload[key].set,
          }
        }
      }

      axios
        .post('https://api.airwet.app/api/zones', {
          data: zone,
        }, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_unit][id][$eq]=${payload.from_unit}`, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  /* context.commit('updateUnitRegimes', response.data); */
                  context.commit('updateUnitZones', response.data);
                  context.commit('updateUnitState', response.data);
                  context.commit('updateUnitControl', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    changeUnitZone(context, payload) {
      context.commit("showPreloader");

      let zone = {
        name: null,
        num: null,
        event: null,
        devs: null,
        nozzles: null,
        hum: {
          id: null,
          activity: null,
          active_srv: null,
          set: null,
          cur: null,
          min: null,
          max: null,
        },
        temp: {
          id: null,
          activity: null,
          active_srv: null,
          set: null,
          cur: null,
          min: null,
          max: null,
        },
      }

      context.state.zones.forEach(elem => {
        if (elem.id === payload.zone) {
          zone.name = elem.name.value;
          zone.num = elem.num.val;
          zone.event = 'service_config';
          zone.devs = elem.devs;
          zone.nozzles = elem.nozzles;
          zone.hum.id = elem.hum.id;
          zone.hum.activity = elem.hum.activity;
          zone.hum.active_srv = elem.hum.active_srv;
          zone.hum.set = elem.hum.set;
          zone.hum.cur = elem.hum.cur;
          zone.hum.min = elem.hum.min.value;
          zone.hum.max = elem.hum.max.value;
          zone.temp.id = elem.temp.id;
          zone.temp.activity = elem.temp.activity;
          zone.temp.active_srv = elem.temp.active_srv;
          zone.temp.set = elem.temp.set;
          zone.temp.cur = elem.temp.cur;
          zone.temp.min = elem.temp.min.value;
          zone.temp.max = elem.temp.max.value;
        }
      });

      axios
        .put(`https://api.airwet.app/api/zones/${payload.zone}`, {
          data: zone,
        }, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_unit][id][$eq]=${payload.unit}`, this.state.common.headers)
            .then(() => {
              if (context.state.hum.current && context.state.hum.current.changed) {
                let program = {
                  data: {
                    active_srv: false,
                  }
                }

                context.state.hum.previous = context.state.hum.program;

                axios
                  .put(`https://api.airwet.app/api/programs/${context.state.hum.current.id}`, program, this.state.common.headers)
                  .then(() => {
                    axios
                      .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                      .then((response) => {
                        /* context.commit('updateUnitRegimes', response.data); */
                        context.commit('updateUnitZones', response.data);
                        context.commit('updateUnitState', response.data);
                        context.commit('updateUnitControl', response.data);
                        context.commit("hidePreloader");
                      })
                      .catch((error) => context.commit("handleError", error));

                    context.state.backup.hum = {};
                  })
                  .catch((error) => context.commit("handleError", error));
              } else {
                axios
                  .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                  .then((response) => {
                    /* context.commit('updateUnitRegimes', response.data); */
                    context.commit('updateUnitZones', response.data);
                    context.commit('updateUnitState', response.data);
                    context.commit('updateUnitControl', response.data);
                    context.commit("hidePreloader");
                  })
              }

              context.state.zones.forEach((elem, index) => {
                if (elem.id === payload.zone) {
                  context.state.backup.zones[index] = {};
                }
              });
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    deleteUnitZone(context, payload) {
      context.commit("showPreloader");

      axios
        .delete(`https://api.airwet.app/api/zones/${payload.zone}`, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://api.airwet.app/api/zones/?populate=devs,nozzles,hum,temp&filters[from_unit][id][$eq]=${payload.unit}`, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://api.airwet.app/api/getUnit/${context.state.about.id}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateUnitZones', response.data);
                  context.commit('updateUnitState', response.data);
                  context.commit('updateUnitControl', response.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
  }
}