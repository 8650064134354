<template>
  <div class="unit__info">
    <section class="about">
      <div class="about__header" @click="toggleSection('about')">
        <div class="about__wrapper">
          <div class="about__name">
            <div class="about__title">{{ about.name.title }}</div>
            <Input
              :type="'text'"
              :name="about.name.name"
              :value="about.name.value"
              :status="about.name.status"
              @paste="handleChanges($event, about.name.element)"
              @input="handleChanges($event, about.name.element)"
              @change="handleChanges($event, about.name.element)"
              @click.stop="false"
            />
            <span class="about__error" v-if="about.name.error">{{
              about.name.errorText
            }}</span>
          </div>
          <div class="about__address">
            <div class="about__title">{{ about.address.title }}</div>
            <Input
              :type="'text'"
              :name="about.address.name"
              :value="about.address.value"
              :status="about.address.status"
              @paste="handleChanges($event, about.address.element)"
              @input="handleChanges($event, about.address.element)"
              @change="handleChanges($event, about.address.element)"
              @click.stop="false"
            />
            <span class="about__error" v-if="about.address.error">{{
              about.address.errorText
            }}</span>
          </div>
          <div class="about__service">
            <div class="about__title">Дата последнего ТО</div>
            <div
              ref="date_maintenance_last"
              name="date_maintenance_last"
              @change="handleChanges($event, 'datepicker')"
              @click.stop="false"
            >
              <Datepicker
                v-model="date_maintenance_last"
                :format="format"
                autoApply
                hideInputIcon
              ></Datepicker>
            </div>
          </div>
          <div class="about__service">
            <div class="about__title">Дата планового ТО</div>
            <div
              ref="date_maintenance_plan"
              name="date_maintenance_plan"
              @change="handleChanges($event, 'datepicker')"
              @click.stop="false"
            >
              <Datepicker
                v-model="date_maintenance_plan"
                :format="format"
                autoApply
                hideInputIcon
              ></Datepicker>
            </div>
          </div>
          <div class="about__mileage">
            <div class="about__title">Обороты | М/ч</div>
            <Input
              :type="'text'"
              :value="`${about.mileage} | ${about.pump_hours}`"
              :disabled="true"
            />
          </div>
        </div>
        <div class="about__control">
          <div class="about__info">
            <div
              :class="`about__state about__state_${about.state.value}`"
            >
              {{ about.state.text }}
            </div>
            <slot v-if="about.state.value === 'error'">
              <Switch
                :paint="'blue'"
                :status="false"
                :disabled="true"
                @click.stop="false"
              />
            </slot>
            <slot v-else-if="about.state.value === 'inactive'">
              <Switch
                :paint="'blue'"
                :status="about.active_user"
                @change.prevent="changeUnitStatus"
              />
            </slot>
            <slot v-else>
              <Switch
                :paint="'blue'"
                :status="about.active_srv"
                @change.prevent="changeUnitStatus"
              />
            </slot>
          </div>
          <button
            class="about__button"
            @click.stop="toggleSection('about')"
          >
            <span
              :class="[
                about.extended
                  ? 'about__button-icon about__button-icon_rotate'
                  : 'about__button-icon',
              ]"
            ></span>
          </button>
        </div>
      </div>
      <div class="about__body" v-show="about.extended">
        <div class="about__contract">
          Договор {{ about.contract.value }} от
          {{ this.convertDate(about.contract.date) }}
        </div>
        <div class="about__params">
          <div class="about__param">
            <div class="about__title">Конфигурация системы</div>
            <slot v-if="about.type === 'pro'">
              <Input
                :type="'text'"
                :value="`Airwet pro`"
                :disabled="true"
              />
            </slot>
            <slot v-if="about.type === 'home'">
              <Input
                :type="'text'"
                :value="`Airwet ${about.type} ${
                  about.unit_type !== null ? about.unit_type : ''
                } ${
                  zones.length > 0 ? this.declOfNum(zones.length) : ''
                }`"
                :disabled="true"
              />
            </slot>
            <slot v-if="about.type === 'light'">
              <Input
                :type="'text'"
                :value="`Airwet ${about.type} ${
                  zones.length > 0 ? this.declOfNum(zones.length) : ''
                }`"
                :disabled="true"
              />
            </slot>
          </div>
          <div class="about__param">
            <div class="about__title">Дата запуска</div>
            <div
              ref="date_start"
              name="date_start"
              @change="handleChanges($event, 'datepicker')"
              :status="about.date_start.status"
            >
              <Datepicker
                v-model="date_start"
                :format="format"
                autoApply
                hideInputIcon
              ></Datepicker>
            </div>
          </div>
          <div class="about__param">
            <div class="about__title">Дата установки системы</div>
            <div
              ref="date_install"
              name="date_install"
              @change="handleChanges($event, 'datepicker')"
            >
              <Datepicker
                v-model="date_install"
                :format="format"
                autoApply
                hideInputIcon
              ></Datepicker>
            </div>
          </div>
          <div class="about__param">
            <div class="about__title">Гарантия до</div>
            <div
              ref="date_warranty"
              name="date_warranty"
              @change="handleChanges($event, 'datepicker')"
            >
              <Datepicker
                v-model="date_warranty"
                :format="format"
                autoApply
                hideInputIcon
              ></Datepicker>
            </div>
          </div>
        </div>
        <div class="about__owner">
          <div
            class="about__block"
            v-for="param in about.owner"
            :data-id="param.id"
            :key="param.id"
          >
            <div class="about__title">{{ param.title }}</div>
            <Input
              :type="param.type"
              :name="param.name"
              :value="param.value"
              :status="param.status"
              :disabled="true"
            />
          </div>
          <div class="about__action">
            <Button
              :type="'action'"
              :value="'edit'"
              :paint="'gray'"
              @click="changeOwner"
            />
          </div>
        </div>
        <div
          class="about__contacts"
          v-if="
            Array.isArray(about.contacts) && about.contacts.length > 0
          "
        >
          <div class="about__contacts-headline">Контакты Airwet</div>
          <div
            class="about__contacts-blocks"
            v-for="contact in about.contacts"
            :data-id="contact.id"
            :key="contact.id"
          >
            <div class="about__block">
              <div class="about__title">Фамилия</div>
              <Input
                :type="'text'"
                :name="'last_name'"
                :value="contact.last_name.value"
                :status="contact.last_name.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="contact.last_name.error">{{
                contact.last_name.error
              }}</span>
            </div>
            <div class="about__block">
              <div class="about__title">Имя</div>
              <Input
                :type="'text'"
                :name="'first_name'"
                :value="contact.first_name.value"
                :status="contact.first_name.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span
                class="about__error"
                v-if="contact.first_name.error"
                >{{ contact.first_name.error }}</span
              >
            </div>
            <div class="about__block">
              <div class="about__title">Отчество</div>
              <Input
                :type="'text'"
                :name="'middle_name'"
                :value="contact.middle_name.value"
                :status="contact.middle_name.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span
                class="about__error"
                v-if="contact.middle_name.error"
                >{{ contact.middle_name.error }}</span
              >
            </div>
            <div class="about__block">
              <div class="about__title">Комментарий</div>
              <Input
                :type="'text'"
                :name="'comment'"
                :value="contact.comment.value"
                :status="contact.comment.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="contact.comment.error">{{
                contact.comment.error
              }}</span>
            </div>
            <div class="about__block">
              <div class="about__title">Телефон</div>
              <Input
                :type="'tel'"
                :name="'phone'"
                :value="contact.phone.value"
                :status="contact.phone.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="contact.phone.error">{{
                contact.phone.error
              }}</span>
            </div>
            <div class="about__block">
              <div class="about__title">Электронная почта</div>
              <Input
                :type="'email'"
                :name="'email'"
                :value="contact.email.value"
                :status="contact.email.status"
                @focus="handleChanges"
                @paste="handleChanges"
                @input="handleChanges"
                @change="handleChanges"
              />
              <span class="about__error" v-if="contact.email.error">{{
                contact.email.error
              }}</span>
            </div>
            <div class="about__action">
              <Button
                :type="'action'"
                :value="'delete'"
                :paint="'gray'"
                @click="deleteContact"
              />
            </div>
          </div>
        </div>
        <div class="about__vacation" v-if="about.vacation">
          <div class="about__vacation-info">
            <div class="about__vacation-title">Отпуск</div>
            <div class="about__vacation-description">
              Активировать режим отпуска
            </div>
          </div>
          <div class="about__vacation-control">
            <Switch
              :name="vacation"
              :status="about.vacation.active_srv"
              :paint="'blue'"
              @change="handleChanges($event, 'switch')"
            />
          </div>
        </div>
        <section class="maintenance" v-if="false">
          <div class="maintenance__header" @click="toggleSubSection">
            <div class="maintenance__info">
              <div class="maintenance__name">01 МАЙ 2022</div>
              <div class="maintenance__description">
                дата ближайшего сервисного обслуживания
              </div>
            </div>
            <div class="maintenance__control">
              <button
                class="maintenance__button"
                @click.stop="toggleSubSection"
              >
                <span class="maintenance__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="maintenance__body">
            <div class="maintenance__blocks">
              <div class="maintenance__block">
                <div class="maintenance__title">01.05.2022</div>
                <div class="maintenance__wrapper">
                  <Input
                    :type="'text'"
                    :value="'Техническое Обслуживание №1'"
                    :disabled="false"
                  />
                  <Button
                    :type="'text'"
                    :value="'Записаться'"
                    :paint="'blue'"
                    @click="getMaintenance"
                  />
                </div>
              </div>
              <div class="maintenance__block">
                <div class="maintenance__title">01.05.2022</div>
                <div class="maintenance__wrapper">
                  <Input
                    :type="'text'"
                    :value="'Техническое Обслуживание №1'"
                    :disabled="true"
                  />
                  <Button
                    :type="'text'"
                    :value="'2 310 руб.'"
                    :paint="'gray'"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="maintenance__block">
                <div class="maintenance__title">01.05.2022</div>
                <div class="maintenance__wrapper">
                  <Input
                    :type="'text'"
                    :value="'Техническое Обслуживание №1'"
                    :disabled="true"
                  />
                  <Button
                    :type="'text'"
                    :value="'2 310 руб.'"
                    :paint="'gray'"
                    :disabled="true"
                  />
                </div>
              </div>
              <div class="maintenance__block">
                <div class="maintenance__title">01.05.2022</div>
                <div class="maintenance__wrapper">
                  <Input
                    :type="'text'"
                    :value="'Техническое Обслуживание №1'"
                    :disabled="true"
                  />
                  <Button
                    :type="'text'"
                    :value="'2 310 руб.'"
                    :paint="'gray'"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="documents">
          <div class="documents__header" @click="toggleSubSection">
            <div class="documents__info">
              <div class="documents__name">ДОКУМЕНТЫ</div>
              <div class="documents__description">
                все документы системы
              </div>
            </div>
            <div class="documents__control">
              <button
                class="documents__button"
                @click.stop="toggleSubSection"
              >
                <span class="documents__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="documents__body">
            <div class="documents__preview">
              <div
                class="documents__document"
                v-for="document in about.documents"
                :data-id="document.id"
                :data-file="document.file.id"
                :key="document.id"
              >
                <div class="documents__document-info">
                  <div class="documents__document-name">
                    {{ document.name }}.{{ document.file.ext.slice(1) }}
                  </div>
                  <div class="documents__document-size">
                    {{ document.file.size }} Кб
                  </div>
                </div>
                <div class="documents__document-control">
                  <Button
                    :type="'action'"
                    :value="'download'"
                    :paint="'gray'"
                    @click="downloadDocument(document)"
                  />
                  <Button
                    :type="'action'"
                    :value="'delete'"
                    :paint="'gray'"
                    @click="deleteDocument"
                  />
                </div>
              </div>
              <div class="documents__new">
                <div class="documents__new-title">Добавить документ</div>
                <Button
                  :type="'action'"
                  :value="'add'"
                  :paint="'gray'"
                  @click="addDocument"
                />
              </div>
            </div>
          </div>
        </section>
        <div class="about__footer">
          <Button
            :type="'text'"
            :value="'Отмена'"
            :paint="'gray'"
            :disabled="about.cancellation"
            @click="cancelChanges($event, 'about')"
          />
          <Button
            :type="'text'"
            :value="'Сохранить изменения'"
            :paint="'blue'"
            :disabled="!about.validation"
            @click="sendChanges('about')"
          />
        </div>
      </div>
    </section>
    <slot v-if="true">
      <section
        class="regime"
        v-for="regime in regimes"
        :data-id="regime.id"
        :data-type="regime.type"
        :key="regime.id"
      >
        <div class="regime__header" @click="toggleSection(regime.type)">
          <div class="regime__wrapper">
            <div class="regime__list">
              <Dropdown
                type="simple"
                :items="regime.list.categories"
                :value="regime.list.value"
                :paint="regime.list.paint"
                @click.stop="
                  handleProperty($event, regime.type, 'programs')
                "
              />
            </div>
            <div class="regime__period" @click.stop="false">
              <div class="regime__start">
                <span class="regime__start-wrapper">
                  <span class="hum__start-strong">Старт</span>
                  программы
                </span>
                <span class="regime__start-date">
                  {{
                    convertProgramTime(regime.program.start_day, "day")
                  }}
                </span>
              </div>
              <div class="regime__stop">
                <span class="regime__stop-wrapper">
                  <span class="hregime__stop-strong">Стоп</span>
                  программы
                </span>
                <span class="regime__stop-date">
                  {{ convertProgramTime(regime.program.stop_day, "day") }}
                </span>
              </div>
            </div>
          </div>
          <div class="regime__control">
            <Switch
              :status="regime.program.active_srv"
              :paint="'blue'"
              @change.prevent="
                handleProperty($event, regime.type, 'active_srv')
              "
              @click.stop="false"
            />
            <button class="regime__button">
              <span
                :class="[
                  regime.extended
                    ? 'regime__button-icon regime__button-icon_rotate'
                    : 'regime__button-icon',
                ]"
              ></span>
            </button>
          </div>
        </div>
        <div class="regime__body" v-show="regime.extended">
          <div class="regime__program" :data-id="regime.program.id">
            <div class="regime__column">
              <div class="regime__schedule">
                <div class="regime__schedule-headline">
                  ГРАФИК ПРОГРАММЫ
                </div>
                <div class="regime__schedule-content">
                  <div
                    class="regime__schedule-period"
                    v-for="period in regime.program.periods"
                    :id="period.id"
                    :key="period.id"
                    :data-period="period.name"
                  >
                    <div class="regime__schedule-title">
                      {{ period.title }}
                    </div>
                    <div class="regime__schedule-range">
                      <Timepicker
                        :hour="
                          convertProgramTime(period.start.hour, 'hour')
                        "
                        :minute="
                          convertProgramTime(
                            period.start.minute,
                            'minute'
                          )
                        "
                        data-stage="start"
                        @click="
                          handleProperty($event, regime.type, 'range')
                        "
                      />
                      <Timepicker
                        :hour="
                          convertProgramTime(period.stop.hour, 'hour')
                        "
                        :minute="
                          convertProgramTime(period.stop.minute, 'minute')
                        "
                        data-stage="stop"
                        @click="
                          handleProperty($event, regime.type, 'range')
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="regime__settings">
                <div class="regime__settings-setting">
                  <div class="regime__settings-stage">СТАРТ</div>
                  <div class="regime__settings-wrapper">
                    <Daypicker
                      :day="
                        convertProgramTime(
                          regime.program.start_day,
                          'day'
                        )
                      "
                      data-stage="start"
                      @click="
                        handleProperty($event, regime.type, 'period')
                      "
                    />
                  </div>
                </div>
                <div class="regime__settings-setting">
                  <div class="regime__settings-stage">СТОП</div>
                  <div class="regime__settings-wrapper">
                    <Daypicker
                      :day="
                        convertProgramTime(regime.program.stop_day, 'day')
                      "
                      data-stage="stop"
                      @click="
                        handleProperty($event, regime.type, 'period')
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="regime__column">
              <div class="regime__zones">
                <div class="regime__select">
                  <Checkbox
                    data-period="morning"
                    :status="
                      regime.program.period.name === 'morning'
                        ? true
                        : false
                    "
                    @change="
                      handleProperty($event, regime.type, 'select')
                    "
                  />
                  <Checkbox
                    data-period="day"
                    :status="
                      regime.program.period.name === 'day' ? true : false
                    "
                    @change="
                      handleProperty($event, regime.type, 'select')
                    "
                  />
                  <Checkbox
                    data-period="evening"
                    :status="
                      regime.program.period.name === 'evening'
                        ? true
                        : false
                    "
                    @change="
                      handleProperty($event, regime.type, 'select')
                    "
                  />
                  <Checkbox
                    data-period="night"
                    :status="
                      regime.program.period.name === 'night'
                        ? true
                        : false
                    "
                    @change="
                      handleProperty($event, regime.type, 'select')
                    "
                  />
                </div>
                <perfect-scrollbar>
                  <div
                    class="regime__zone"
                    v-for="zone in regime.zones"
                    :id="zone.id"
                    :key="zone.id"
                  >
                    <div class="regime__zone-title">
                      {{ zone.name }}
                    </div>
                    <div class="regime__zone-value">
                      <button
                        class="regime__zone-button"
                        data-period="morning"
                        data-step="decrease"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].morning.control"
                      >
                        <span class="regime__zone-minus"></span>
                      </button>
                      <Input
                        :type="'tel'"
                        :value="
                          zone[regime.type].morning.value +
                          zone[regime.type].morning.unit
                        "
                        :status="zone[regime.type].morning.status"
                        data-period="morning"
                        @focus="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @change="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @input="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @blur="
                          handleProperty($event, regime.type, 'zone')
                        "
                      />
                      <button
                        class="regime__zone-button"
                        data-period="morning"
                        data-step="increase"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].morning.control"
                      >
                        <span class="regime__zone-plus"></span>
                      </button>
                    </div>
                    <div class="regime__zone-value">
                      <button
                        class="regime__zone-button"
                        data-period="day"
                        data-step="decrease"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].day.control"
                      >
                        <span class="regime__zone-minus"></span>
                      </button>
                      <Input
                        :type="'tel'"
                        :value="
                          zone[regime.type].day.value +
                          zone[regime.type].day.unit
                        "
                        :status="zone[regime.type].day.status"
                        data-period="day"
                        @focus="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @change="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @input="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @blur="
                          handleProperty($event, regime.type, 'zone')
                        "
                      />
                      <button
                        class="regime__zone-button"
                        data-period="day"
                        data-step="increase"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].day.control"
                      >
                        <span class="regime__zone-plus"></span>
                      </button>
                    </div>
                    <div class="regime__zone-value">
                      <button
                        class="regime__zone-button"
                        data-period="evening"
                        data-step="decrease"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].evening.control"
                      >
                        <span class="regime__zone-minus"></span>
                      </button>
                      <Input
                        :type="'tel'"
                        :value="
                          zone[regime.type].evening.value +
                          zone[regime.type].evening.unit
                        "
                        :status="zone[regime.type].evening.status"
                        data-period="evening"
                        @focus="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @change="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @input="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @blur="
                          handleProperty($event, regime.type, 'zone')
                        "
                      />
                      <button
                        class="regime__zone-button"
                        data-period="evening"
                        data-step="increase"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].evening.control"
                      >
                        <span class="regime__zone-plus"></span>
                      </button>
                    </div>
                    <div class="regime__zone-value">
                      <button
                        class="regime__zone-button"
                        data-period="night"
                        data-step="decrease"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].night.control"
                      >
                        <span class="regime__zone-minus"></span>
                      </button>
                      <Input
                        :type="'tel'"
                        :value="
                          zone[regime.type].night.value +
                          zone[regime.type].night.unit
                        "
                        :status="zone[regime.type].night.status"
                        data-period="night"
                        @focus="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @change="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @input="
                          handleProperty($event, regime.type, 'zone')
                        "
                        @blur="
                          handleProperty($event, regime.type, 'zone')
                        "
                      />
                      <button
                        class="regime__zone-button"
                        data-period="night"
                        data-step="increase"
                        @click="
                          handleProperty($event, regime.type, 'zone')
                        "
                        v-if="zone[regime.type].night.control"
                      >
                        <span class="regime__zone-plus"></span>
                      </button>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
              <div class="regime__tuner">
                <div class="regime__tuner-period">
                  <span class="regime__tuner-title"
                    >Значения периода</span
                  >
                  <div class="regime__tuner-wrapper">
                    <Slider
                      type="horizontal"
                      :min="regime.program.min"
                      :max="regime.program.max"
                      :value="regime.program.period.value"
                      :disabled="!regime.program.period.name"
                      data-scope="period"
                      @input.prevent="
                        handleProperty($event, regime.type, 'value')
                      "
                    />
                    <span class="regime__tuner-value"
                      >{{ regime.program.period.value }}%</span
                    >
                  </div>
                </div>
                <div class="regime__tuner-common">
                  <span class="regime__tuner-title">Общие значения</span>
                  <div class="regime__tuner-wrapper">
                    <Slider
                      type="horizontal"
                      :min="regime.program.min"
                      :max="regime.program.max"
                      :value="regime.program.value"
                      :disabled="false"
                      data-scope="common"
                      @input.prevent="
                        handleProperty($event, regime.type, 'value')
                      "
                    />
                    <span class="regime__tuner-value"
                      >{{ regime.program.value }}%</span
                    >
                  </div>
                </div>
              </div>
              <div class="regime__footer">
                <div class="regime__footer-control">
                  <Button
                    :type="'text'"
                    :value="'Отмена'"
                    :paint="'gray'"
                    :disabled="regime.cancellation"
                    @click="cancelChanges($event, regime.type)"
                  />
                  <Button
                    :type="'text'"
                    :value="'Сохранить изменения'"
                    :paint="'blue'"
                    :disabled="!regime.validation"
                    @click="changeRegime"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </slot>
    <div class="unit__zones" v-if="true">
      <div
        v-for="zone in zones"
        :class="zone.current ? 'zone zone_current' : 'zone'"
        :data-id="zone.id"
        :key="zone.id"
        v-click-outside="commonClick"
        @click="setActiveZone"
      >
        <div class="zone__body">
          <div class="zone__name">
            <input
              :class="{
                'zone__name-input': true,
                'zone__name-input_valid': zone.name.status === 'valid',
                'zone__name-input_invalid':
                  zone.name.status === 'invalid',
              }"
              type="text"
              :name="'name'"
              :status="zone.name.status"
              :value="zone.name.value"
              @input="handleZone($event, 'input')"
              @change="handleZone($event, 'input')"
            />
          </div>
          <div class="zone__programs">
            <div class="zone__programs-title">Программы:</div>
            <div class="zone__programs-body">
              <slot v-if="zone.programs.length === 0">отсутствуют</slot>
              <slot v-else>
                <div
                  v-for="program in zone.programs"
                  class="zone__program"
                  :data-period="program.period"
                  :data-type="program.type"
                  :key="program.id"
                >
                  <img
                    class="zone__icon"
                    :src="require(`../assets/images/${program.type}.svg`)"
                    alt=""
                  />
                  <span class="zone__program-title">{{
                    program.title
                  }}</span>
                </div>
              </slot>
            </div>
          </div>
          <Dropdown
            :type="zone.num.type"
            :name="zone.num.name"
            :items="zone.num.categories"
            :value="zone.num.value"
            :status="zone.num.status"
            @click="handleZone($event, 'dropdown')"
          />
          <div class="zone__info">
            <div class="zone__caption">
              <div class="zone__caption-set">Целевое</div>
              <hr class="zone__caption-line" />
              <div class="zone__caption-cur">Текущее</div>
            </div>
            <div class="zone__indicators">
              <div
                class="zone__indicator"
                data-param="hum"
                v-if="zone.hum"
              >
                <slot v-if="zone.hum.active_srv">
                  <div class="zone__set">
                    <font-awesome-icon icon="arrow-down" />
                    <span class="zone__set-value">{{
                      zone.hum.set
                    }}</span>
                    <span class="zone__set-unit">%</span>
                  </div>
                  <div class="zone__cur">
                    <font-awesome-icon
                      icon="arrow-up"
                      v-if="zone.hum.activity"
                    />
                    <font-awesome-icon icon="arrow-down" v-else />
                    <span class="zone__cur-value">{{
                      zone.hum.cur
                    }}</span>
                    <span class="zone__cur-unit">%</span>
                  </div>
                </slot>
                <slot v-else>
                  <div class="zone__set">
                    <span class="zone__set-dash">&mdash;</span>
                    <span class="zone__set-dash">&mdash;</span>
                    <span class="zone__set-unit">%</span>
                  </div>
                  <div class="zone__cur">
                    <span class="zone__cur-value">{{
                      zone.hum.cur
                    }}</span>
                    <span class="zone__cur-unit">%</span>
                  </div>
                </slot>
                <span
                  :class="
                    zone.hum.active_srv
                      ? 'zone__led zone__led_on'
                      : 'zone__led zone__led_off'
                  "
                ></span>
              </div>
              <div
                class="zone__indicator"
                data-param="temp"
                v-if="zone.temp"
              >
                <slot v-if="zone.temp.active_srv">
                  <div class="zone__set">
                    <font-awesome-icon icon="arrow-down" />
                    <span class="zone__set-value">{{
                      zone.temp.set
                    }}</span>
                    <span class="zone__set-unit">&#176;</span>
                  </div>
                  <div class="zone__cur">
                    <font-awesome-icon
                      icon="arrow-up"
                      v-if="zone.temp.activity"
                    />
                    <font-awesome-icon icon="arrow-down" v-else />
                    <span class="zone__cur-value">{{
                      zone.temp.cur
                    }}</span>
                    <span class="zone__cur-unit">&#176;</span>
                  </div>
                </slot>
                <slot v-else>
                  <div class="zone__set">
                    <span class="zone__set-dash">&mdash;</span>
                    <span class="zone__set-dash">&mdash;</span>
                    <span class="zone__set-unit">&#176;</span>
                  </div>
                  <div class="zone__cur">
                    <span class="zone__cur-value">{{
                      zone.temp.cur
                    }}</span>
                    <span class="zone__cur-unit">&#176;</span>
                  </div>
                </slot>
                <span
                  :class="
                    zone.temp.active_srv
                      ? 'zone__led zone__led_on'
                      : 'zone__led zone__led_off'
                  "
                ></span>
              </div>
              <div
                class="zone__indicator"
                data-param="carb"
                v-if="zone.carb"
              >
                <div class="zone__set">
                  <span class="zone__set-value">1189</span>
                  <span class="zone__set-unit">PPM</span>
                </div>
                <div class="zone__cur">
                  <div class="zone__cur-wrapper">
                    <font-awesome-icon
                      icon="arrow-up"
                      v-if="zone.carb.active_srv && zone.carb.activity"
                    />
                    <font-awesome-icon icon="arrow-down" v-else />
                    <span class="zone__cur-value">820</span>
                  </div>
                  <span class="zone__cur-unit">PPM</span>
                </div>
                <span
                  :class="
                    zone.carb.active_srv
                      ? 'zone__led zone__led_on'
                      : 'zone__led zone__led_off'
                  "
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div class="zone__footer" v-show="zone.extended">
          <slot
            v-if="Array.isArray(zone.errors) && zone.errors.length > 0"
          >
            <div class="zone__errors">
              <div
                class="zone__error"
                v-for="(error, index) in zone.errors"
                :key="index"
                :data-error="error.code"
              >
                <div class="zone__error-wrapper">
                  <span class="zone__error-dot"></span>
                  <div class="zone__error-info">
                    <div class="zone__error-date">
                      {{ convertDate(error.time, "full") }}
                    </div>
                    <div class="zone__error-text">{{ error.text }}</div>
                  </div>
                </div>
              </div>
            </div>
          </slot>
          <div class="zone__params">
            <div class="zone__param" data-param="hum" v-if="zone.hum">
              <div class="zone__wrapper">
                <Switch
                  :paint="'blue'"
                  :name="'active_srv'"
                  :status="zone.hum.active_srv"
                  @change="handleZone($event, 'switch', 'hum')"
                />
                <div class="zone__tuner">
                  <div class="zone__set">
                    <span class="zone__set-value">{{
                      zone.hum.set
                    }}</span>
                    <span class="zone__set-unit">%</span>
                  </div>
                  <Slider
                    type="horizontal"
                    :value="zone.hum.set"
                    :min="zone.hum.min.value"
                    :max="zone.hum.max.value"
                    :name="'set'"
                    :disabled="!zone.hum.active_srv"
                    @input="handleZone($event, 'slider', 'hum')"
                    @change="handleZone($event, 'slider', 'hum')"
                  />
                </div>
                <img
                  class="zone__icon"
                  src="../assets/images/hum.svg"
                  alt=""
                />
              </div>
              <div class="zone__limits">
                <div class="zone__limit">
                  <div class="zone__title">Минимальное</div>
                  <Input
                    :type="'text'"
                    :name="zone.hum.min.name"
                    :value="zone.hum.min.value"
                    :status="zone.hum.min.status"
                    data-param="hum"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
                <div class="zone__limit">
                  <div class="zone__title">Максимальное</div>
                  <Input
                    :type="'text'"
                    :name="zone.hum.max.name"
                    :value="zone.hum.max.value"
                    :status="zone.hum.max.status"
                    data-param="hum"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
              </div>
            </div>
            <div class="zone__param" data-param="temp" v-if="zone.temp">
              <div class="zone__wrapper">
                <Switch
                  :paint="'orange'"
                  :name="'active_srv'"
                  :status="zone.temp.active_srv"
                  @change="handleZone($event, 'switch', 'temp')"
                />
                <div class="zone__tuner">
                  <div class="zone__set">
                    <span class="zone__set-value">{{
                      zone.temp.set
                    }}</span>
                    <span class="zone__set-unit">&#176;</span>
                  </div>
                  <Slider
                    type="horizontal"
                    :value="zone.temp.set"
                    :min="zone.temp.min.value"
                    :max="zone.temp.max.value"
                    :name="'set'"
                    :disabled="!zone.temp.active_srv"
                    @input="handleZone($event, 'slider', 'temp')"
                    @change="handleZone($event, 'slider', 'temp')"
                  />
                </div>
                <img
                  class="zone__icon"
                  src="../assets/images/temp.svg"
                  alt=""
                />
              </div>
              <div class="zone__limits">
                <div class="zone__limit">
                  <div class="zone__title">Минимальное</div>
                  <Input
                    :type="'text'"
                    :name="zone.temp.min.name"
                    :value="zone.temp.min.value"
                    :status="zone.temp.min.status"
                    data-param="temp"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
                <div class="zone__limit">
                  <div class="zone__title">Максимальное</div>
                  <Input
                    :type="'text'"
                    :name="zone.temp.max.name"
                    :value="zone.temp.max.value"
                    :status="zone.temp.max.status"
                    data-param="temp"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
              </div>
            </div>
            <div class="zone__param" data-param="carb" v-if="zone.carb">
              <div class="zone__wrapper">
                <Switch
                  :paint="'dark'"
                  :name="'active_srv'"
                  :status="true"
                  @change="handleZone($event, 'switch', 'carb')"
                />
                <div class="zone__tuner">
                  <div class="zone__set">
                    <span class="zone__set-value">4750</span>
                    <span class="zone__set-unit">PPM</span>
                  </div>
                  <Slider
                    type="horizontal"
                    :name="'set'"
                    @input="handleZone($event, 'slider', 'carb')"
                    @change="handleZone($event, 'slider', 'carb')"
                  />
                </div>
                <img
                  class="zone__icon"
                  src="../assets/images/carb.svg"
                  alt=""
                />
              </div>
              <div class="zone__limits">
                <div class="zone__limit">
                  <div class="zone__title">Минимальное</div>
                  <Input
                    :type="'text'"
                    :name="zone.carb.min.name"
                    :value="zone.carb.min.value"
                    :status="zone.carb.min.status"
                    data-param="carb"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
                <div class="zone__limit">
                  <div class="zone__title">Максимальное</div>
                  <Input
                    :type="'text'"
                    :name="zone.carb.max.name"
                    :value="zone.carb.max.value"
                    :status="zone.carb.max.status"
                    data-param="carb"
                    @input="handleZone($event, 'input')"
                    @change="handleZone($event, 'input')"
                  />
                </div>
              </div>
            </div>
          </div>
          <slot v-if="zone.hum">
            <div class="zone__block" data-block="devs">
              <div class="zone__subblock" data-part="first">
                <div class="zone__title">Тип устройства</div>
                <Dropdown
                  :type="'simple'"
                  :items="zone.device.dropdown.categories"
                  :name="zone.device.dropdown.name"
                  :value="zone.device.dropdown.value"
                  :status="zone.device.dropdown.status"
                  @click="handleZone($event, 'dropdown')"
                />
              </div>
              <div class="zone__subblock" data-part="last">
                <div class="zone__title">Адрес</div>
                <Input
                  :type="'text'"
                  :name="zone.device.input.name"
                  :value="zone.device.input.value"
                  :status="zone.device.input.status"
                  @input="handleZone($event, 'input')"
                  @change="handleZone($event, 'input')"
                />
              </div>
              <Button
                :type="'action'"
                :value="'add'"
                :paint="'gray'"
                :disabled="zone.device.button.disabled"
                @click.stop="handleZone($event, 'add')"
              />
            </div>
            <div
              class="zone__modules"
              v-if="zone.devs.length > 0"
              data-modules="devs"
            >
              <div
                class="zone__module"
                v-for="(elem, index) in zone.devs"
                :data-id="index"
                :key="index"
              >
                <div class="zone__subblock">
                  <Input
                    :type="'text'"
                    :value="elem.type === 's' ? `Датчик` : `Гигростат`"
                    :disabled="true"
                  />
                </div>
                <div class="zone__subblock">
                  <Input
                    :type="'text'"
                    :value="elem.addr"
                    :disabled="true"
                  />
                </div>
                <Button
                  :type="'action'"
                  :value="'delete'"
                  :paint="'gray'"
                  @click.stop="handleZone($event, 'delete')"
                />
              </div>
            </div>
            <div class="zone__block" data-block="nozzles">
              <div class="zone__subblock" data-part="first">
                <div class="zone__title">Тип форсунок</div>
                <Dropdown
                  :type="'simple'"
                  :items="zone.nozzle.dropdown.categories"
                  :name="zone.nozzle.dropdown.name"
                  :value="zone.nozzle.dropdown.value"
                  :status="zone.nozzle.dropdown.status"
                  @click="handleZone($event, 'dropdown')"
                />
              </div>
              <div class="zone__subblock" data-part="last">
                <div class="zone__title">Количество</div>
                <Input
                  :type="'text'"
                  :name="zone.nozzle.input.name"
                  :value="zone.nozzle.input.value"
                  :status="zone.nozzle.input.status"
                  @input="handleZone($event, 'input')"
                  @change="handleZone($event, 'input')"
                />
              </div>
              <Button
                :type="'action'"
                :value="'add'"
                :paint="'gray'"
                :disabled="zone.nozzle.button.disabled"
                @click.stop="handleZone($event, 'add')"
              />
            </div>
            <div
              class="zone__modules"
              v-if="zone.nozzles.length > 0"
              data-modules="nozzles"
            >
              <div
                class="zone__module"
                v-for="(elem, index) in zone.nozzles"
                :data-id="index"
                :key="index"
              >
                <div class="zone__subblock">
                  <Input
                    :type="'text'"
                    :value="elem.type"
                    :disabled="true"
                  />
                </div>
                <div class="zone__subblock">
                  <Input
                    :type="'text'"
                    :value="elem.quan"
                    :disabled="true"
                  />
                </div>
                <Button
                  :type="'action'"
                  :value="'delete'"
                  :paint="'gray'"
                  @click.stop="handleZone($event, 'delete')"
                />
              </div>
            </div>
          </slot>
          <div class="zone__control">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              :disabled="zone.cancellation"
              @click="cancelChanges($event, 'zone')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить'"
              :paint="'blue'"
              :disabled="!zone.validation"
              @click="changeZone"
            />
          </div>
        </div>
        <div
          class="zone__arrow"
          @click="collapseZone($event, zone.extended)"
        >
          <Chevron :orient="zone.extended ? 'up' : 'down'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Datepicker from "@vuepic/vue-datepicker";

  import Button from "../components/Button";
  import Checkbox from "../components/Checkbox";
  import Chevron from "../components/Chevron";
  import Daypicker from "../components/Daypicker";
  import Dropdown from "../components/Dropdown";
  import Input from "../components/Input";
  import Slider from "../components/Slider";
  import Switch from "../components/Switch";
  import Timepicker from "../components/Timepicker";

  export default {
    name: "Unit",
    data() {
      return {
        format: "dd.MM.yyyy",
      };
    },
    components: {
      Datepicker,
      Daypicker,
      Button,
      Checkbox,
      Chevron,
      Dropdown,
      Input,
      Slider,
      Switch,
      Timepicker,
    },
    computed: {
      ...mapGetters({
        navbar: "getUnitNavbar",
        about: "getUnitAbout",
        regimes: "getUnitRegimes",
        zones: "getUnitZones",
      }),
      date_start: {
        get() {
          if (this.$store.state.unit.about.date_start.value !== null) {
            return new Date(
              this.$store.state.unit.about.date_start.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_start.dispatchEvent(event);
        },
      },
      date_install: {
        get() {
          if (this.$store.state.unit.about.date_install.value !== null) {
            return new Date(
              this.$store.state.unit.about.date_install.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_install.dispatchEvent(event);
        },
      },
      date_warranty: {
        get() {
          if (this.$store.state.unit.about.date_warranty.value !== null) {
            return new Date(
              this.$store.state.unit.about.date_warranty.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_warranty.dispatchEvent(event);
        },
      },
      date_maintenance_last: {
        get() {
          if (
            this.$store.state.unit.about.date_maintenance_last.value !== null
          ) {
            return new Date(
              this.$store.state.unit.about.date_maintenance_last.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_maintenance_last.dispatchEvent(event);
        },
      },
      date_maintenance_plan: {
        get() {
          if (
            this.$store.state.unit.about.date_maintenance_plan.value !== null
          ) {
            return new Date(
              this.$store.state.unit.about.date_maintenance_plan.value * 1000
            );
          } else return null;
        },
        set(value) {
          let event = new Event("change");
          event.value = Math.round(Date.parse(value) / 1000);

          this.$refs.date_maintenance_plan.dispatchEvent(event);
        },
      },
    },
    methods: {
      ...mapMutations([
        "toggleUnitSection",
        "setUnitActiveZone",
        "extendUnitZone",
        "collapseUnitZone",
        "handleUnitChanges",
        "handleUnitProperty",
        "handleUnitZone",
        "cancelUnitChanges",
        "createModal",
        "createPopup",
        "updateUnitAboutSocket",
        "resetUnitsState",
      ]),
      ...mapActions([
        "fetchUnit",
        "changeUnitActiveStatus",
        "sendUnitChanges",
        "changeUnitRegime",
        "changeUnitZone",
      ]),
      toggleSection(section) {
        this.toggleUnitSection(section);
      },
      toggleSubSection(event) {
        let section = event.target.closest("section");
        let className = section.classList[0];
        let sectionBody = section.querySelector(`.${className}__body`);
        let sectionButton = section.querySelector(`.${className}__button-icon`);

        if (getComputedStyle(sectionBody).display === "none") {
          sectionBody.style.display = "block";
          sectionButton.className = `${className}__button-icon ${className}__button-icon_rotate`;
        } else if (getComputedStyle(sectionBody).display === "block") {
          sectionBody.style.display = "none";
          sectionButton.className = `${className}__button-icon`;
        }
      },
      convertDate(timestamp, type) {
        if (timestamp !== null) {
          let newDate = new Date(timestamp * 1000);
          let year = newDate.getFullYear();
          let month = newDate.getMonth() + 1;
          if (month < 10) month = "0" + month;
          let day = newDate.getDate();
          if (day < 10) day = "0" + day;
          let hour = newDate.getHours();
          if (hour < 10) hour = "0" + hour;
          let min = newDate.getMinutes();
          if (min < 10) min = "0" + min;
          let sec = newDate.getSeconds();
          if (sec < 10) sec = "0" + sec;

          if (type && type === "full")
            return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
          else return `${day}.${month}.${year}`;
        }
      },
      convertProgramTime(date, param) {
        if (param === "day") {
          let days = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];
          let day = days[date - 1];

          return day;
        } else {
          if (date < 10) date = "0" + date;

          return date;
        }
      },
      convertFlushTime(date, param) {
        if (param === "day") {
          let days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
          let day = days[date];

          return day;
        } else {
          if (date < 10) date = "0" + date;

          return date;
        }
      },
      declOfNum(number) {
        number = Math.abs(number) % 100;
        let n1 = number % 10;
        let arr = ["зона", "зоны", "зон"];

        if (number > 10 && number < 20) return `${number} ${arr[2]}`;
        if (n1 > 1 && n1 < 5) return `${number} ${arr[1]}`;
        if (n1 == 1) return `${number} ${arr[0]}`;
        return `${number} ${arr[2]}`;
      },
      commonClick(event) {
        if (!event.target.closest(".zone")) {
          this.resetUnitsState();
        }
      },
      changeUnitStatus(event) {
        let payload = {
          unit: +event.target.closest(".unit").getAttribute("data-id"),
          active: event.target.checked,
        };

        this.changeUnitActiveStatus(payload);
      },
      changeOwner() {
        let payload = {
          type: "owner",
          params: {
            owner: null,
            last_name: null,
            first_name: null,
            middle_name: null,
            phone: null,
            email: null,
            password: null,
          },
        };

        this.createModal(payload);
      },
      addDocument() {
        let payload = {
          type: "document",
          params: {
            name: null,
          },
        };

        this.createModal(payload);
      },
      downloadDocument(document) {
        window.location.href = `https://api.airwet.app${document.file.url}`;
      },
      deleteDocument(event) {
        let payload = {
          type: "document",
          params: {
            id: +event.target
              .closest(".documents__document")
              .getAttribute("data-id"),
            file: +event.target
              .closest(".documents__document")
              .getAttribute("data-file"),
          },
        };

        this.createPopup(payload);
      },
      resetPasswordLocalUnit() {
        let payload = {
          type: "zone",
        };

        this.createPopup(payload);
      },
      resetErrors(event) {
        let payload = {
          error: +event.target
            .closest(".state__error")
            .getAttribute("data-error"),
        };

        this.resetUnitErrors(payload);
      },
      handleChanges(event, element) {
        let payload = {};

        if (element === "datepicker") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.value,
          };
        }

        if (element === "input") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.target.value,
          };

          if (payload.name === "phone") {
            if (event.type === "paste") {
              let value = event.clipboardData.getData("text/plain");

              let pattern =
                /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
              let phoneNumbers = value.match(pattern);

              if (phoneNumbers !== null) {
                let correctNumber = phoneNumbers[0].replace(
                  pattern,
                  "+7 ($2) $3-$4-$5"
                );

                event.target.value = correctNumber;
              }
            } else if (event.type === "keydown" || event.type === "input") {
              let keyCode;
              event.keyCode && (keyCode = event.keyCode);

              if (event.target.selectionStart < 3) {
                event.target.value = `+7 ${event.data}`;
              }

              let mask = "+7 (___) ___-__-__";
              let index = 0;
              let def = mask.replace(/\D/g, "");
              let val = event.target.value.replace(/\D/g, "");
              let newVal = mask.replace(/[_\d]/g, (arg) =>
                index < val.length
                  ? val.charAt(index++) || def.charAt(index)
                  : arg
              );

              index = newVal.indexOf("_");

              if (index !== -1) {
                index < 5 && (index = 3);
                newVal = newVal.slice(0, index);
              }

              let reg = mask
                .substr(0, event.target.value.length)
                .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
                .replace(/[+()]/g, "\\$&");

              reg = new RegExp(`^${reg}$`);

              if (
                !reg.test(event.target.value) ||
                event.target.value.length < 5 ||
                (keyCode > 47 && keyCode < 58)
              ) {
                event.target.value = newVal;
              }
            }

            payload.value = event.target.value;
          }
        }

        if (element === "dropdown") {
          if (event.target.closest(".dropdown__item")) {
            payload = {
              name: event.target.closest(".dropdown").getAttribute("name"),
              value: event.target
                .closest(".dropdown__item")
                .querySelector(".dropdown__title").innerHTML,
              event: "change",
            };
          } else return false;
        }

        if (element === "switch") {
          if (event.target.closest(".control__block") !== null) {
            payload = {
              value: event.target.checked,
              name: event.target.getAttribute("name"),
              id:
                event.target
                  .closest(".control__block")
                  .getAttribute("data-id") !== null
                  ? +event.target
                      .closest(".control__block")
                      .getAttribute("data-id")
                  : null,
              param: event.target.closest(".control__param").getAttribute("data-param"),
              event: "change",
            };
          } else {
            payload = {
              value: event.target.checked,
              name: event.target.getAttribute("name"),
              event: "change",
            };
          }
        }

        if (element === "daypicker") {
          if (event.target.closest(".daypicker__item")) {
            payload = {
              event: "change",
              type: "day",
              stage: event.target
                .closest(".daypicker")
                .getAttribute("data-stage"),
              value: +event.target
                .closest(".daypicker__item")
                .getAttribute("data-id"),
              num: +event.target
                .closest(".daypicker__item")
                .getAttribute("data-num"),
            };
          }
        }

        if (element === "timepicker") {
          if (event.target.closest(".timepicker__item")) {
            payload = {
              event: "change",
              type: "time",
              stage: event.target
                .closest(".timepicker")
                .getAttribute("data-stage"),
              unit: event.target
                .closest(".timepicker__list")
                .getAttribute("data-unit"),
              value: parseInt(
                event.target.closest(".timepicker__item").innerHTML
              ),
            };
          }
        }

        if (element === "file") {
          payload = {
            value: event.target.files[0],
            name: event.target.getAttribute("name"),
            event: "change",
          };
        }

        payload.element = element;
        payload.section = event.target.closest("section").className;

        this.handleUnitChanges(payload);
      },
      handleProperty(event, property, param) {
        let payload = {};

        if (param === "programs") {
          if (event.target.closest(".dropdown__item")) {
            payload = {
              event: "change",
              value: event.target
                .closest(".dropdown__item")
                .getAttribute("data-name"),
            };
          }
        }

        if (param === "value") {
          payload = {
            event: event.type,
            scope: event.target.closest(".slider").getAttribute("data-scope"),
            value: +event.target.value,
          };
        }

        if (param === "active_srv") {
          payload = {
            event: event.type,
            value: event.target.checked,
          };
        }

        if (param === "period") {
          if (event.target.closest(".daypicker")) {
            if (event.target.closest(".daypicker__item")) {
              payload = {
                event: "change",
                type: "day",
                stage: event.target
                  .closest(".daypicker")
                  .getAttribute("data-stage"),
                value: +event.target
                  .closest(".daypicker__item")
                  .getAttribute("data-id"),
                num: +event.target
                  .closest(".daypicker__item")
                  .getAttribute("data-num"),
              };
            }
          }

          if (event.target.closest(".timepicker")) {
            if (event.target.closest(".timepicker__item")) {
              payload = {
                event: "change",
                type: "time",
                stage: event.target
                  .closest(".timepicker")
                  .getAttribute("data-stage"),
                unit: event.target
                  .closest(".timepicker__list")
                  .getAttribute("data-unit"),
                value: parseInt(
                  event.target.closest(".timepicker__item").innerHTML
                ),
              };
            }
          }
        }

        if (param === "range") {
          if (event.target.closest(".timepicker")) {
            if (event.target.closest(".timepicker__item")) {
              payload = {
                event: "change",
                id: +event.target.closest('.regime__schedule-period').id,
                type: "time",
                stage: event.target
                  .closest(".timepicker")
                  .getAttribute("data-stage"),
                unit: event.target
                  .closest(".timepicker__list")
                  .getAttribute("data-unit"),
                value: parseInt(
                  event.target.closest(".timepicker__item").innerHTML
                ),
              };
            }
          }
        }

        if (param === "select") {
          payload = {
            event: "change",
            value: event.target.checked,
            period: event.target.closest(".checkbox").getAttribute("data-period"),
          };
        }

        if (param === "zone") {
          payload = {
            event: event.type,
            id: +event.target.closest('.regime__zone').id,
            value: +event.target.value,
            period:
              event.target.getAttribute("data-period") ||
              event.target
                .closest(".regime__zone-value")
                .querySelector(".input")
                .getAttribute("data-period"),
          };

          if (event.target.closest(".regime__zone-button")) {
            payload.event = "change";
            payload.step = event.target
              .closest(".regime__zone-button")
              .getAttribute("data-step");

            let value = parseInt(
              event.target.closest(".regime__zone-value").querySelector(".input")
                .value
            );

            if (payload.step === "increase") {
              value++;
              if (value > 58) value = 58;
            }

            if (payload.step === "decrease") {
              value--;
              if (value < 0) value = 0;
            }

            payload.value = value;
          }
        }

        payload.property = property;
        payload.param = param;

        this.handleUnitProperty(payload);
      },
      handlePagination(event) {
        if (
          event.target.closest(".pagination__button") ||
          event.target.closest(".pagination__page")
        ) {
          this.fetchUnitTasks();
        }
      },
      cancelChanges(event, param) {
        let payload = {
          param: param,
        };

        if (param === "zone") {
          payload.id = +event.target.closest(".zone").getAttribute("data-id");
        }

        this.cancelUnitChanges(payload);
      },
      sendChanges(payload) {
        this.sendUnitChanges(payload);
      },
      changeRegime(event) {
        let payload = {
          id: +event.target.closest(".regime__program").getAttribute("data-id"),
        };

        this.changeUnitRegime(payload);
      },
      addZone() {
        let payload = {
          type: "zone",
          params: {
            name: null,
            num: null,
            control_channels: this.about.control_channels,
            devs: [],
            nozzles: [],
            hum: {
              status: false,
              set: null,
            },
            temp: {
              status: false,
              set: null,
            },
            carb: {
              status: false,
              set: null,
            },
            from_unit: +document
              .querySelector(".unit")
              .getAttribute("data-id"),
          },
        };

        this.createModal(payload);
      },
      setActiveZone(event) {
        let payload = {
          id: +event.target.closest(".zone").getAttribute("data-id"),
        };

        this.setUnitActiveZone(payload);
        this.extendUnitZone(payload);
      },
      handleZone(event, element, param) {
        let payload = {};

        payload.event = event.type;

        if (element === "input") {
          payload.value = event.target.value;
          payload.name = event.target.getAttribute("name");
          payload.param = event.target.getAttribute("data-param");
        }

        if (element === "switch") {
          payload.value = event.target.checked;
          payload.name = event.target.getAttribute("name");
          payload.param = param;
        }

        if (element === "slider") {
          payload.value = +event.target.value;
          payload.name = event.target.getAttribute("name");
          payload.param = param;
        }

        if (element === "dropdown") {
          if (event.target.closest(".dropdown").getAttribute("name") === "num") {
            if (
              event.target.closest(".dropdown__button") ||
              event.target.closest(".dropdown__field")
            ) {
              payload = {
                event: "change",
                open: true,
                name: "num",
              };
            } else if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target.closest(".dropdown").getAttribute("name"),
                alias: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown__item")
                  .querySelector(".dropdown__title").innerHTML,
                event: "change",
              };
            } else return false;
          } else {
            if (event.target.closest(".dropdown__item")) {
              payload = {
                name: event.target.closest(".dropdown").getAttribute("name"),
                alias: event.target
                  .closest(".dropdown__item")
                  .getAttribute("data-name"),
                value: event.target
                  .closest(".dropdown__item")
                  .querySelector(".dropdown__title").innerHTML,
                event: "change",
              };
            } else return false;
          }
        }

        if (element === "add") {
          payload = {
            block: event.target
              .closest(".zone__block")
              .getAttribute("data-block"),
            event: "change",
          };
        }

        if (element === "delete") {
          payload = {
            modules: event.target
              .closest(".zone__modules")
              .getAttribute("data-modules"),
            index: +event.target.closest(".zone__module").getAttribute("data-id"),
            event: "change",
          };
        }

        payload.id = +event.target.closest(".zone").getAttribute("data-id");
        payload.element = element;
        payload.unit = +document
          .querySelector(".unit")
          .getAttribute("data-id");

        this.extendUnitZone(payload);
        this.handleUnitZone(payload);
      },
      changeZone(event) {
        let payload = {
          zone: +event.target.closest(".zone").getAttribute("data-id"),
          unit: +document.querySelector(".unit").getAttribute("data-id"),
        };

        this.changeUnitZone(payload);
      },
      collapseZone(event, extended) {
        let payload = {
          id: +event.target.closest(".zone").getAttribute("data-id"),
        };

        if (extended) {
          event.stopPropagation();
          this.collapseUnitZone(payload);
        }
      },
      deleteZone() {
        let payload = {
          type: "zone",
          params: {
            zone: +document
              .querySelector(".zone_current")
              .getAttribute("data-id"),
            unit: +document.querySelector(".unit").getAttribute("data-id"),
          },
        };

        this.createPopup(payload);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .unit {

    &__info {
      display: flex;
      flex-wrap: wrap;
    }

    &__programs {
      width: 100%;
      margin-top: 20px;
    }

    &__zones {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 5px;

      @include mediaquery(sm) {
        margin-top: -5px;
      }

      @include mediaquery(md) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        margin-left: -5px;
        margin-right: -5px;
        margin-top: -10px;
        margin-bottom: 20px;
        width: calc(100% + 10px);
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: flex-end;
        }

        @include mediaquery(md) {
          padding: 0 5px;
        }

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: auto;

            &:first-of-type {
              margin-bottom: 0px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .about {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      margin-bottom: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        cursor: pointer;
      }

      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 65%;

        @include mediaquery(xl) {
          flex-wrap: nowrap;
          justify-content: flex-start;
          width: auto;
          flex-direction: row;
          align-items: center;
        }
      }

      &__name {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(lg) {
          width: 200px;
        }

        @include mediaquery(xl) {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__address {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(xl) {
          width: 310px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__service {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 150px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__mileage {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 150px;
          margin-bottom: 0;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mediaquery(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &__state {
        @include state;
        @include mediaquery(md) {
          margin: 0 10px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;
        width: 35%;
        margin-left: 10px;

        @include mediaquery(sm) {
          width: 30%;
        }
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__contract {
        font-size: 12px;
        font-weight: 500;
        margin: 5px 0 11px;
      }

      &__params {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
        }
      }

      &__param {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(25% - 10px);
        }
      }

      &__owner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 10px 0 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          justify-content: flex-start;
          align-items: flex-end;

          .about__block {
            &:nth-last-child(2) {
              margin-bottom: 0;
              margin-right: 10px;
            }

            @include mediaquery(lg) {
              width: calc(20% - 19px);
              margin-bottom: 0;
            }
          }
        }
      }

      &__contacts {
        border-bottom: 1px solid #e1e1e1;
        padding: 10px 0 20px;
        margin-bottom: 25px;

        &-headline {
          font-size: 12px;
          font-weight: 500;
        }

        &-blocks {
          display: flex;
          flex-wrap: wrap;
        }

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          align-items: flex-end;
        }
      }

      &__block {
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(20% - 10px);
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__vacation {
        @include base;

        width: 100%;
        padding: 10px 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__features {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding: 20px 0;
      }

      &__tabs {
        display: flex;
        justify-content: space-between;
      }

      &__feature {
        @include base;

        width: 32%;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__documents {
        padding-top: 20px;
        display: flex;

        &-column {
          width: 50%;

          &:first-of-type {
            padding-right: 10px;
          }
        }
      }

      &__document {
        margin-bottom: 10px;
        &-wrapper {
          display: flex;
        }

        input {
          margin-right: 10px;
        }

        &-control {
          display: flex;
        }

        button {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      &__action {
        width: 36px;
        display: flex;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 0 0;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .documents {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: -5px;
      }

      &__new {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-title {
          font-weight: 500;
          font-size: 12px;
          margin-left: 18px;
          color: #2b1a0a;
        }
      }

      &__document {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-icon {
          width: 46px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-info {
          display: flex;
          align-items: center;
          margin-left: 17px;
        }

        &-name {
          font-size: 12px;
          font-weight: 500;
          color: #2b1a0a;
        }

        &-size {
          font-size: 10px;
          color: #2b1a0a;
          margin-left: 20px;
        }

        &-control {
          display: flex;

          button {
            margin-left: 10px;
          }
        }

        &-link {
          height: 36px;
          min-width: 36px;
          padding: 6px;
          border-radius: 6px;
          box-shadow: $shadowLight;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;

          @media (hover: hover) {
            &:hover {
              background-color: #fff;
            }
          }
        }

        &-icon {
          width: 20px;
          opacity: 0.5;
        }
      }

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
      }

      &__block {
        width: calc(50% - 10px);
        margin: 5px;
      }

      &__title {
        @include title;
      }

      &__wrapper {
        display: flex;

        input {
          margin-right: 10px;
        }
      }

      &__file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        padding: 0;
        border-radius: 6px;
        box-shadow: $shadowLight;
        border: 0;
        cursor: pointer;
        overflow-x: hidden;

        &-input {
          position: absolute;
          width: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }

        &-icon {
          width: 16px;
        }
      }
    }

    .regime {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 20px;

      &[data-type="hum"] {
        .regime__start-date,
        .regime__stop-date {
          background-color: $colorHum;
        }
      }

      &[data-type="temp"] {
        .regime__start-date,
        .regime__stop-date {
          background-color: $colorTemp;
        }
      }

      &[data-type="carb"] {
        .regime__start-date,
        .regime__stop-date {
          background-color: $colorCarb;
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__body {
        display: block;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 85%;

        @include mediaquery(md) {
          width: 50%;
        }

        @include mediaquery(lg) {
          width: 85%;
          flex-direction: row;
        }
      }

      &__list {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(lg) {
          width: 200px;
          margin-bottom: 0;
        }
      }

      &__period {
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: auto;

        @include mediaquery(lg) {
          width: auto;
          flex-direction: row;
          margin-left: 10px;
        }
      }

      &__start,
      &__stop {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #222325;
        background-color: $colorMainLight;
        border: 1px solid #dcdcdc;
        border-radius: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(lg) {
          margin-right: 10px;
          margin-bottom: 0;

          &:last-of-type {
            margin-right: 0;
          }
        }

        &-strong {
          font-weight: 500;
          margin-right: 3px;
        }

        &-date {
          margin-left: 10px;
          text-transform: uppercase;
          color: #fff;
          padding: 5px 15px;
          border-radius: 15px;
          font-size: 10px;
          text-align: center;
          min-width: 45px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
        width: 15%;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__program {
        padding: 15px 0 10px;
        display: flex;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__column {
        width: 100%;

        @include mediaquery(sm) {
          &:first-of-type {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
          }
        }

        @include mediaquery(md) {
          &:first-of-type {
            flex-direction: column;
            min-width: 200px;
            max-width: 200px;
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 210px);
          }
        }
      }

      &__schedule {
        @include base;

        padding: 10px;
        width: 100%;

        @include mediaquery(sm) {
          width: 50%;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        &-headline {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
        }

        &-period {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &[data-period="morning"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #8bd341;
            }
            .timepicker {
              background-color: #8bd341;
            }
          }

          &[data-period="day"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #ff6eda;
            }
            .timepicker {
              background-color: #ff6eda;
            }
          }

          &[data-period="evening"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #7976ff;
            }
            .timepicker {
              background-color: #7976ff;
            }
          }

          &[data-period="night"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #3a95ff;
            }
            .timepicker {
              background-color: #3a95ff;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 12px;
          font-weight: 500;
        }

        &-range {
          display: flex;

          .timepicker {
            color: #fff;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }

      &__settings {
        @include base;

        padding: 10px;
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        @include mediaquery(sm) {
          width: 50%;
          margin: 0;
          margin-left: 10px;
        }

        @include mediaquery(md) {
          width: 100%;
          margin: 0;
          margin-top: 10px;
        }

        &-setting {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-wrapper {
          display: flex;
        }

        &-stage {
          font-weight: 500;
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
        }
      }

      &__zones {
        @include base;

        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        padding-bottom: 0;
        min-height: 191.5px;
        width: 100%;
      }

      &__select {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 5px;

        .checkbox {
          height: 28.5px;
          margin-bottom: 10px;
        }
      }

      .ps {
        display: flex;
        width: calc(100% - 55px);
        padding: 5px;
        padding-bottom: 10px;
      }

      &__zone {
        margin-left: 10px;
        width: 100px;
        min-width: 100px;

        &:first-of-type {
          margin-left: 0;
        }

        &-title {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-value {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          user-select: none;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-button {
          position: absolute;
          border: 1px solid #bcbcbc;
          color: #bcbcbc;
          border-radius: 2px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          transition: color 0.3s, border-color 0.3s;

          &:hover {
            border-color: #222325;
            color: #222325;
          }

          &:first-of-type {
            left: 7px;
          }

          &:last-of-type {
            right: 7px;
          }
        }

        &-minus {
          height: 2px;
          width: 10px;
          background-color: #bcbcbc;
        }

        &-plus {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 4px;
            display: block;
            width: 10px;
            height: 2px;
            background: #bcbcbc;
          }

          &::before {
            transform: rotate(0deg);
          }

          &::after {
            transform: rotate(90deg);
          }
        }

        input {
          text-align: center;
          height: 28.5px;
          padding: 6px 14px;
          margin-bottom: 10px;
          font-weight: 500;

          &:last-of-type {
            margin-bottom: 0;
          }

          &[data-period="morning"] {
            color: #8bd341;
            -webkit-text-fill-color: #8bd341;
          }

          &[data-period="day"] {
            color: #ff6eda;
            -webkit-text-fill-color: #ff6eda;
          }

          &[data-period="evening"] {
            color: #7976ff;
            -webkit-text-fill-color: #7976ff;
          }

          &[data-period="night"] {
            color: #3a95ff;
            -webkit-text-fill-color: #3a95ff;
          }
        }
      }

      &__tuner {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 15px 0;

        @include mediaquery(sm) {
          margin: 10px 0;
        }

        &-period,
        &-common {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mediaquery(sm) {
            justify-content: flex-start;
          }
        }

        &-period {
          margin-bottom: 20px;
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-title {
          font-weight: 500;
          font-size: 10px;
          color: #222325;
          text-transform: uppercase;

          @include mediaquery(sm) {
            min-width: 120px;
            margin-right: 10px;
          }
        }

        &-value {
          font-size: 12px;
          font-weight: 500;
          margin-left: 5px;
          color: #bcbcbc;
          min-width: 35px;
          text-align: right;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        &-control {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include mediaquery(sm) {
            flex-direction: row;
            justify-content: flex-end;
          }

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }

            @include mediaquery(sm) {
              width: auto;
              margin-bottom: 0;

              &:first-of-type {
                margin-right: 10px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .zone {
      @include base;

      width: 100%;
      border: 2px solid $colorMainLight;
      padding: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include mediaquery(md) {
        width: calc(50% - 10px);
        margin: 5px;
      }

      @include mediaquery(xl) {
        width: calc(33.333333% - 10px);
      }

      @include mediaquery(hg) {
        width: calc(25% - 10px);
      }

      &_current {
        border: 2px solid #41c6eb;
      }

      &__body {
        position: relative;
      }

      &__name {
        color: #222325;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        &-input {
          border: 1px solid transparent;
          border-radius: 6px;
          background: none;
          font-family: inherit;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;

          &:hover {
            border: 1px solid #dcdcdc;
          }

          &:focus {
            border: 1px solid #41c6eb;
          }

          &_valid {
            border-color: #41c6eb;
          }

          &_invalid {
            border-color: #ff8068 !important;
          }
        }
      }

      &__programs {
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-title {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-body {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          margin-left: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: 20px;
        }

        .zone__icon {
          width: 20px;
          margin: 0 5px 0 0;
        }
      }

      &__program {
        display: flex;
        align-items: center;
        margin: 0 5px;
        font-size: 14px;
        text-transform: uppercase;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &[data-type="hum"] {
          .zone__program-title {
            color: $colorHum;
          }
        }

        &[data-type="temp"] {
          .zone__program-title {
            color: $colorTemp;
          }
        }

        &[data-type="carb"] {
          .zone__program-title {
            color: $colorCarb;
          }
        }
      }

      &__info {
        display: flex;
        margin-top: 15px;
      }

      &__caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
        color: #363636;
        font-size: 11px;
        font-weight: 500;

        &-line {
          background: #363636;
          border: 0;
          width: 100%;
          height: 1px;
          margin: 7px 0;
        }
      }

      &__indicators {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }

      &__indicator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        box-shadow: $shadowLight;
        color: #fff;
        padding: 5px 10px;
        flex-grow: 1;
        width: calc(33.333333% - 10px);
        min-width: calc(33.333333% - 10px);
        min-height: 75px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &[data-param="temp"] {
          background-color: $colorTemp;
        }

        &[data-param="hum"] {
          background-color: $colorHum;
        }

        &[data-param="carb"] {
          background-color: $colorCarb;

          .zone__set,
          .zone__cur {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
          }

          .zone__set-unit,
          .zone__cur-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__set {
        font-size: 19px;
        font-weight: 500;
        margin-top: 6px;
        white-space: nowrap;

        svg {
          visibility: hidden;
        }

        &-dash {
          font-weight: 400;

          &:not(:first-of-type) {
            margin-left: 3px;
          }

          ~ .zone__set-unit {
            visibility: hidden;
          }
        }
      }

      &__cur {
        display: flex;
        align-items: center;
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 6px;

        &-wrapper {
          white-space: nowrap;
        }

        &-value {
          margin-left: 2px;
        }
      }

      &__led {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 5px;
        right: 5px;

        &_on {
          background: linear-gradient(212.01deg, #dfffbe 13.85%, #9eff3d 82.31%);
        }

        &_off {
          background: linear-gradient(208.61deg, #fc4f4f 0.59%, #ce0909 92.94%);
          background: none;
        }
      }

      &__errors {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        margin-bottom: 10px;
      }

      &__error {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-dot {
          background-color: #ff5e3f;
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 9px;
        }

        &-date {
          font-size: 9px;
          color: #000000;
        }

        &-text {
          font-size: 12px;
          color: #ff5e3f;
          text-transform: uppercase;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__params {
        display: flex;
        flex-direction: column;
      }

      &__param {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 10px;
        border: 2px solid transparent;
        border-radius: 6px;

        &[data-param="hum"] {
          border-color: $colorHum;

          .zone__set {
            color: $colorHum;
          }
        }

        &[data-param="temp"] {
          border-color: $colorTemp;

          .zone__set {
            color: $colorTemp;
          }
        }

        &[data-param="carb"] {
          border-color: $colorCarb;

          .zone__set {
            color: $colorCarb;
          }
        }
      }

      &__wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
      }

      &__limits {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &__limit {
        width: calc(50% - 5px);
      }

      &__tuner {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .slider {
          width: 100%;
          margin: 4px 0;
        }
      }

      &__icon {
        width: 28px;
        margin-left: 10px;
        margin-bottom: -3px;
      }

      &__block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 10px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      &__modules {
        display: flex;
        flex-wrap: wrap;
      }

      &__module {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__subblock {
        position: relative;

        &:first-of-type {
          width: calc(50% - 5px);
        }
        &:last-of-type {
          width: calc(50% - 51px);
        }
      }

      &__control {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        .button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: calc(50% - 5px);

            &:first-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }

      &__arrow {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
    }
  }
</style>