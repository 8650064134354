import {
  createRouter,
  createWebHistory
} from 'vue-router'

import Building from "../pages/Building.vue";
import Login from "../pages/Login.vue";
import Nothing from "../pages/Nothing.vue";
import Profile from "../pages/Profile.vue";
import Unit from "../pages/Unit.vue";


const routes = [{
    path: '/',
    redirect: '/login'
  }, {
    path: '/building/:id',
    name: 'building',
    component: Building,
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/unit/:id',
    name: 'unit',
    component: Unit
  },
  {
    path: '/:pathMatch(.*)',
    name: 'nothing',
    component: Nothing
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
});


router.beforeEach((to, from, next) => {
  const publicPages = [
    '/login',
    '/nothing',
  ];

  const dispatcherPages = [
    '/building',
    `/unit/${to.params.id}`,
  ];

  const authRequired = !publicPages.includes(to.path);
  const dispatcherRequired = dispatcherPages.includes(to.path);
  const loggedIn = localStorage.getItem('authorization__user-role');

  if (authRequired && !loggedIn) next('/login');
  else if (dispatcherRequired && loggedIn !== 'dispatcher') next('/nothing');

  else next();
});

export default router