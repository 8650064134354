<template>
  <div class="building__info">
    <section class="about">
      <div class="about__header" @click="toggleSection('about')">
        <div class="about__wrapper">
          <div class="about__name">
            <div class="about__title">Имя объекта</div>
            <Input
              :type="'text'"
              :name="about.name.name"
              :value="about.name.value"
              :status="about.name.status"
              @paste="handleChanges($event, about.name.element)"
              @input="handleChanges($event, about.name.element)"
              @change="handleChanges($event, about.name.element)"
              @click.stop="false"
            />
            <span class="about__error" v-if="about.name.error">{{
              about.name.errorText
            }}</span>
          </div>
          <div class="about__address">
            <div class="about__title">Адрес объекта</div>
            <Input :type="'text'" :value="about.address" :disabled="true" />
          </div>
          <div class="about__service">
            <div class="about__title">Дата планового ТО</div>
            <Input
              :type="'text'"
              :value="this.convertDate(about.date_maintenance_plan)"
              :disabled="true"
            />
          </div>
          <div class="about__mileage">
            <div class="about__title">Моточасы</div>
            <Input
              :type="'text'"
              :value="about.pump_hours"
              :disabled="true"
            />
          </div>
        </div>
        <div class="about__control">
          <div class="about__info">
            <div :class="`about__state about__state_${about.state.value}`">
              {{ about.state.text }}
            </div>
            <slot
              v-if="
                about.state.value === 'error' ||
                about.state.value === 'disabled'
              "
            >
              <Switch
                :paint="'blue'"
                :status="false"
                :disabled="true"
                @click.stop="false"
              />
            </slot>
            <slot v-else>
              <Switch
                :paint="'blue'"
                :status="about.active_user"
                @change.prevent="changeBuildingStatus"
              />
            </slot>
          </div>
          <button class="about__button" @click.stop="toggleSection('about')">
            <span
              :class="[
                about.extended
                  ? 'about__button-icon about__button-icon_rotate'
                  : 'about__button-icon',
              ]"
            ></span>
          </button>
        </div>
      </div>
      <div class="about__body" v-show="about.extended">
        <div class="about__contract">
          Договор {{ about.contract.value }} от
          {{ this.convertDate(about.contract.date) }}
        </div>
        <div class="about__params">
          <div class="about__param">
            <div class="about__title">Конфигурация системы</div>
            <Input :type="'text'" :value="`Airwet pro`" :disabled="true" />
          </div>
          <div class="about__param">
            <div class="about__title">Дата запуска</div>
            <Input
              :type="'text'"
              :value="this.convertDate(about.date_start)"
              :disabled="true"
            />
          </div>
          <div class="about__param">
            <div class="about__title">Гарантия до</div>
            <Input
              :type="'text'"
              :value="this.convertDate(about.date_warranty)"
              :disabled="true"
            />
          </div>
        </div>
        <section
          class="documents"
          v-if="Array.isArray(about.documents) && about.documents.length > 0"
        >
          <div class="documents__header" v-on:click="toggleSubSection">
            <div class="documents__info">
              <div class="documents__name">ДОКУМЕНТЫ</div>
              <div class="documents__description">
                все документы вашей системы
              </div>
            </div>
            <div class="documents__control">
              <button
                class="documents__button"
                v-on:click.stop="toggleSubSection"
              >
                <span class="documents__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="documents__body">
            <div class="documents__preview">
              <div
                class="documents__document"
                v-for="document in about.documents"
                :data-id="document.id"
                :key="document.id"
              >
                <div class="documents__document-info">
                  <div class="documents__document-name">
                    {{ document.name }}.{{ document.file.ext.slice(1) }}
                  </div>
                  <div class="documents__document-size">
                    {{ document.file.size }} Кб
                  </div>
                </div>
                <div class="documents__document-control">
                  <Button
                    :type="'action'"
                    :value="'download'"
                    :paint="'gray'"
                    @click="downloadDocument(document)"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="about__footer">
          <Button
            :type="'text'"
            :value="'Отмена'"
            :paint="'gray'"
            :disabled="about.cancellation"
            @click="cancelChanges($event, 'about')"
          />
          <Button
            :type="'text'"
            :value="'Сохранить изменения'"
            :paint="'blue'"
            :disabled="!about.validation"
            @click="sendChanges('about')"
          />
        </div>
      </div>
    </section>
    <section class="scheme" v-if="false">
      <div class="scheme__header" @click="toggleSection('scheme')">
        <div class="scheme__main">
          <div class="scheme__headline">Схема здания</div>
        </div>
        <div class="scheme__control">
          <button
            class="scheme__button"
            @click.stop="toggleSection('scheme')"
          >
            <span
              :class="[
                scheme.extended
                  ? 'scheme__button-icon scheme__button-icon_rotate'
                  : 'scheme__button-icon',
              ]"
            ></span>
          </button>
        </div>
      </div>
      <div class="scheme__body" v-show="scheme.extended">
        <div class="scheme__canvas">
          <div class="scheme__building">
            <div class="scheme__pipeline"></div>
            <div class="scheme__floor">
              <div class="scheme__fcb">ЭБУ этажный</div>
              <div class="scheme__pipes">
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
              </div>
            </div>
            <div class="scheme__floor">
              <div class="scheme__fcb">ЭБУ этажный</div>
              <div class="scheme__pipes">
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
              </div>
            </div>
            <div class="scheme__floor">
              <div class="scheme__fcb">ЭБУ этажный</div>
              <div class="scheme__pipes">
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
              </div>
            </div>
            <div class="scheme__floor">
              <div class="scheme__fcb">ЭБУ этажный</div>
              <div class="scheme__pipes">
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
              </div>
            </div>
            <div class="scheme__floor">
              <div class="scheme__fcb">ЭБУ этажный</div>
              <div class="scheme__pipes">
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
              </div>
            </div>
            <div class="scheme__floor">
              <div class="scheme__fcb">ЭБУ этажный</div>
              <div class="scheme__pipes">
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
                <div class="scheme__pipe">
                  <div class="scheme__pipetube"></div>
                  <div class="scheme__pipesensor">Датчик протечки</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                  <div class="scheme__room">ЭБУ квартирный</div>
                </div>
              </div>
            </div>
            <div class="scheme__basement">
              <div class="scheme__hydro">Гидроплита</div>
            </div>
            <!-- <div class="tree">
              <ul>
                <li>
                  <a href="#">Блок управления этажный</a>
                  <ul>
                    <li>
                      <a href="#">Насос 1</a>
                      <ul>
                        <li>
                          <a href="#">Датчик 1</a>
                          <ul>
                            <li>
                              <a href="#">Зона 1</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="#">Датчик 2</a>
                          <ul>
                            <li>
                              <a href="#">Зона 1</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                            <li>
                              <a href="#">Зона 2</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a href="#">Насос 2</a>
                      <ul>
                        <li>
                          <a href="#">Зона 1</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                        <li>
                          <a href="#">Зона 2</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div> -->
          </div>
        </div>
        <button class="scheme__fs" @click="changeScheme">
          <font-awesome-icon icon="fa-maximize" />
        </button>
      </div>
    </section>
    <div class="building__entrances">
      <div
        v-for="entrance in entrances"
        :class="entrance.current ? 'entrance entrance_current' : 'entrance'"
        :data-id="entrance.id"
        :key="entrance.id"
        v-click-outside="commonClick"
        @click="setActiveEntrance"
      >
        <div class="entrance__body">
          <div class="entrance__name">
            <input
              :class="'entrance__name-input'"
              type="text"
              :name="'name'"
              :value="entrance.name"
            />
          </div>
        </div>
        <section class="filtres" v-if="entrance.filtres.length > 0">
          <div class="filtres__header" @click="toggleSubSection">
            <div class="filtres__info">
              <div class="filtres__title">Водоподготовка</div>
            </div>
            <div class="filtres__control">
              <button class="filtres__button" @click.stop="toggleSubSection">
                <span class="filtres__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="filtres__body">
            <div class="filtres__blocks">
              <div
                v-for="filter in entrance.filtres"
                class="filtres__block"
                :data-id="filter.id"
                :key="filter.id"
              >
                <div class="filtres__name">
                  {{ filter.name }}
                </div>
                <div class="filtres__params">
                  <div class="filtres__subblock">
                    <div class="filtres__param">Вх. давление (p_in)</div>
                    <Input
                      :type="'text'"
                      :value="filter.p_in"
                      :disabled="true"
                    />
                  </div>
                  <div class="filtres__subblock">
                    <div class="filtres__param">Вых. давление (p_out)</div>
                    <Input
                      :type="'text'"
                      :value="filter.p_out"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="pumps" v-if="entrance.pumps.length > 0">
          <div class="pumps__header" @click="toggleSubSection">
            <div class="pumps__info">
              <div class="pumps__title">Насосы</div>
            </div>
            <div class="pumps__control">
              <button class="pumps__button" @click.stop="toggleSubSection">
                <span class="pumps__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="pumps__body">
            <div class="pumps__blocks">
              <div
                v-for="pump in entrance.pumps"
                class="pumps__block"
                :data-id="pump.id"
                :key="pump.id"
              >
                <div class="pumps__name">
                  {{ pump.name }}
                </div>
                <div class="pumps__params">
                  <div class="pumps__subblock">
                    <div class="pumps__param">Вх. давление (p_in)</div>
                    <Input
                      :type="'text'"
                      :value="pump.p_in"
                      :disabled="true"
                    />
                  </div>
                  <div class="pumps__subblock">
                    <div class="pumps__param">Вых. давление (p_out)</div>
                    <Input
                      :type="'text'"
                      :value="pump.p_out"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="collectors" v-if="entrance.collectors.length > 0">
          <div class="collectors__header" @click="toggleSubSection">
            <div class="collectors__info">
              <div class="collectors__title">Гидроплиты</div>
            </div>
            <div class="collectors__control">
              <button
                class="collectors__button"
                @click.stop="toggleSubSection"
              >
                <span class="collectors__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="collectors__body">
            <div class="collectors__blocks">
              <div
                v-for="collector in entrance.collectors"
                class="collectors__block"
                :data-id="collector.id"
                :key="collector.id"
              >
                <div class="collectors__name">
                  {{ collector.name }}
                </div>
                <div class="collectors__params">
                  <div class="collectors__subblock">
                    <div class="collectors__param">Вх. давление (p_in)</div>
                    <Input
                      :type="'text'"
                      :value="collector.p_in"
                      :disabled="true"
                    />
                  </div>
                  <div class="collectors__subblock">
                    <div class="collectors__param">Вых. давление (p_out)</div>
                    <Input
                      :type="'text'"
                      :value="collector.p_out"
                      :disabled="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="controllers" v-if="entrance.controllers.length > 0">
          <div class="controllers__header" @click="toggleSubSection">
            <div class="controllers__info">
              <div class="controllers__title">Блоки управления</div>
            </div>
            <div class="controllers__control">
              <button
                class="controllers__button"
                @click.stop="toggleSubSection"
              >
                <span class="controllers__button-icon"></span>
              </button>
            </div>
          </div>
          <div class="controllers__body">
            <div class="controllers__blocks">
              <div
                v-for="controller in entrance.controllers"
                class="controllers__block"
                :data-id="controller.id"
                :key="controller.id"
              >
                <div class="controllers__name">
                  ЭБУ {{ controller.id }}
                </div>
                <div class="controllers__params">
                  <div
                    v-for="floor in controller.floors"
                    class="controllers__floor"
                    :data-id="floor.id"
                    :key="floor.id"
                  >
                    {{ floor.title }}
                    <Switch :status="true" paint="blue" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="entrance__footer" v-show="entrance.extended">
          <div class="entrance__blocks">
            <div
              class="entrance__block"
              v-for="block in entrance.blocks"
              :data-id="block.id"
              :key="block.id"
            >
              <div class="entrance__name">
                {{ block.name }}
              </div>
              <div class="entrance__floors">
                <div
                  class="entrance__block"
                  v-for="floor in block.floors"
                  :data-id="floor.id"
                  :key="floor.id"
                >
                  {{ floor.title }}
                </div>
              </div>
            </div>
          </div>
          <div class="entrance__control">
            <Button
              :type="'text'"
              :value="'Отмена'"
              :paint="'gray'"
              @click="cancelChanges($event, 'entrance')"
            />
            <Button
              :type="'text'"
              :value="'Сохранить'"
              :paint="'blue'"
              @click="changeZone"
            />
          </div>
        </div>
        <div
          class="entrance__arrow"
          @click="collapseEntrance($event, entrance.extended)"
        >
          <Chevron :orient="entrance.extended ? 'up' : 'down'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Button from "../components/Button";
  import Input from "../components/Input";

  export default {
    name: "Building",
    data() {
      return {
        format: "dd.MM.yyyy",
      };
    },
    components: {
      Button,
      Input,
    },
    computed: {
      ...mapGetters({
        about: "getBuildingAbout",
        scheme: "getBuildingScheme",
        entrances: "getBuildingEntrances",
      }),
    },
    methods: {
      ...mapMutations([
        "toggleBuildingSection",
        "setBuildingActiveEntrance",
        "extendBuildingEntrance",
        "collapseBuildingEntrance",
        "handleBuildingChanges",
        "cancelBuildingChanges",
        "createModal",
        "createPopup",
        "resetBuildingState",
      ]),
      ...mapActions([
        "changeBuildingActiveStatus",
        "sendBuildingChanges",
      ]),
      toggleSection(section) {
        this.toggleBuildingSection(section);
      },
      toggleSubSection(event) {
        let section = event.target.closest("section");
        let className = section.classList[0];
        let sectionBody = section.querySelector(`.${className}__body`);
        let sectionButton = section.querySelector(`.${className}__button-icon`);

        if (getComputedStyle(sectionBody).display === "none") {
          sectionBody.style.display = "block";
          sectionButton.className = `${className}__button-icon ${className}__button-icon_rotate`;
        } else if (getComputedStyle(sectionBody).display === "block") {
          sectionBody.style.display = "none";
          sectionButton.className = `${className}__button-icon`;
        }
      },
      convertDate(timestamp, type) {
        if (timestamp !== null) {
          let newDate = new Date(timestamp * 1000);
          let year = newDate.getFullYear();
          let month = newDate.getMonth() + 1;
          if (month < 10) month = "0" + month;
          let day = newDate.getDate();
          if (day < 10) day = "0" + day;
          let hour = newDate.getHours();
          if (hour < 10) hour = "0" + hour;
          let min = newDate.getMinutes();
          if (min < 10) min = "0" + min;
          let sec = newDate.getSeconds();
          if (sec < 10) sec = "0" + sec;

          if (type && type === "full")
            return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
          else return `${day}.${month}.${year}`;
        }
      },
      commonClick(event) {
        if (event.target.closest(".entrance")) return;
        else {
          if(event.target.closest(".building__entrances")) {
            let entrances = document.querySelectorAll('.entrance');

            for (let i = 0; i < entrances.length; i++) {
              let sections = entrances[i].getElementsByTagName('section');

              Array.from(sections).forEach(section => {
                section.querySelector(`.${section.className}__body`).style.display = 'none';
                section.querySelector(`.${section.className}__button-icon`).className = `${section.className}__button-icon`;
              });
            }
          }
          
          this.resetBuildingState();
        }
      },
      changeBuildingStatus(event) {
        let payload = {
          system: +event.target.closest(".building").getAttribute("data-id"),
          active: event.target.checked,
        };

        this.changeBuildingActiveStatus(payload);
      },
      downloadDocument(document) {
        window.location.href = `https://api.airwet.app${document.file.url}`;
      },
      handleChanges(event, element) {
        let payload = {};

        if (element === "datepicker") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.value,
          };
        }

        if (element === "input") {
          payload = {
            event: event.type,
            name: event.target.getAttribute("name"),
            value: event.target.value,
          };

          if (payload.name === "phone") {
            if (event.type === "paste") {
              let value = event.clipboardData.getData("text/plain");

              let pattern =
                /(\+7|8)\s?[\s(]?(\d{3})[\s)]?\s?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;
              let phoneNumbers = value.match(pattern);

              if (phoneNumbers !== null) {
                let correctNumber = phoneNumbers[0].replace(
                  pattern,
                  "+7 ($2) $3-$4-$5"
                );

                event.target.value = correctNumber;
              }
            } else if (event.type === "keydown" || event.type === "input") {
              let keyCode;
              event.keyCode && (keyCode = event.keyCode);

              if (event.target.selectionStart < 3) {
                event.target.value = `+7 ${event.data}`;
              }

              let mask = "+7 (___) ___-__-__";
              let index = 0;
              let def = mask.replace(/\D/g, "");
              let val = event.target.value.replace(/\D/g, "");
              let newVal = mask.replace(/[_\d]/g, (arg) =>
                index < val.length
                  ? val.charAt(index++) || def.charAt(index)
                  : arg
              );

              index = newVal.indexOf("_");

              if (index !== -1) {
                index < 5 && (index = 3);
                newVal = newVal.slice(0, index);
              }

              let reg = mask
                .substr(0, event.target.value.length)
                .replace(/_+/g, (arg) => `\\d{1,${arg.length}}`)
                .replace(/[+()]/g, "\\$&");

              reg = new RegExp(`^${reg}$`);

              if (
                !reg.test(event.target.value) ||
                event.target.value.length < 5 ||
                (keyCode > 47 && keyCode < 58)
              ) {
                event.target.value = newVal;
              }
            }

            payload.value = event.target.value;
          }
        }

        if (element === "dropdown") {
          if (event.target.closest(".dropdown__item")) {
            payload = {
              name: event.target.closest(".dropdown").getAttribute("name"),
              value: event.target
                .closest(".dropdown__item")
                .querySelector(".dropdown__title").innerHTML,
              event: "change",
            };
          } else return false;
        }

        if (element === "switch") {
          if (event.target.closest(".control__block") !== null) {
            payload = {
              value: event.target.checked,
              name: event.target.getAttribute("name"),
              id:
                event.target
                  .closest(".control__block")
                  .getAttribute("data-id") !== null
                  ? +event.target
                      .closest(".control__block")
                      .getAttribute("data-id")
                  : null,
              param: event.target.closest(".control__param").getAttribute("data-param"),
              event: "change",
            };
          } else {
            payload = {
              value: event.target.checked,
              name: event.target.getAttribute("name"),
              event: "change",
            };
          }
        }

        if (element === "daypicker") {
          if (event.target.closest(".daypicker__item")) {
            payload = {
              event: "change",
              type: "day",
              stage: event.target
                .closest(".daypicker")
                .getAttribute("data-stage"),
              value: +event.target
                .closest(".daypicker__item")
                .getAttribute("data-id"),
              num: +event.target
                .closest(".daypicker__item")
                .getAttribute("data-num"),
            };
          }
        }

        if (element === "timepicker") {
          if (event.target.closest(".timepicker__item")) {
            payload = {
              event: "change",
              type: "time",
              stage: event.target
                .closest(".timepicker")
                .getAttribute("data-stage"),
              unit: event.target
                .closest(".timepicker__list")
                .getAttribute("data-unit"),
              value: parseInt(
                event.target.closest(".timepicker__item").innerHTML
              ),
            };
          }
        }

        if (element === "file") {
          payload = {
            value: event.target.files[0],
            name: event.target.getAttribute("name"),
            event: "change",
          };
        }

        payload.element = element;
        payload.section = event.target.closest("section").className;

        this.handleBuildingChanges(payload);
      },
      cancelChanges(event, param) {
        let payload = {
          param: param,
        };

        if (param === "zone") {
          payload.id = +event.target.closest(".zone").getAttribute("data-id");
        }

        this.cancelBuildingChanges(payload);
      },
      sendChanges(payload) {
        this.sendBuildingChanges(payload);
      },
      setActiveEntrance(event) {
        let payload = {
          id: +event.target.closest(".entrance").getAttribute("data-id"),
        };

        this.setBuildingActiveEntrance(payload);
        this.extendBuildingEntrance(payload);
      },
      collapseEntrance(event, extended) {
        let payload = {
          id: +event.target.closest(".entrance").getAttribute("data-id"),
        };

        if (extended) {
          event.stopPropagation();
          this.collapseBuildingEntrance(payload);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .building {
    &__info {
      display: flex;
      flex-wrap: wrap;
    }

    &__zones {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include mediaquery(md) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        margin-left: -5px;
        margin-right: -5px;
        margin-top: -5px;
        margin-bottom: 20px;
        width: calc(100% + 10px);
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: flex-end;
        }

        @include mediaquery(md) {
          padding: 0 5px;
        }

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: auto;

            &:first-of-type {
              margin-bottom: 0px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    &__entrances {
      width: 100%;
      display: flex;
      flex-direction: column;

      @include mediaquery(md) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        margin-left: -5px;
        margin-right: -5px;
        margin-top: -5px;
        margin-bottom: 20px;
        width: calc(100% + 10px);
      }

      &-footer {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: flex-end;
        }

        @include mediaquery(md) {
          padding: 0 5px;
        }

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: auto;

            &:first-of-type {
              margin-bottom: 0px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    .about {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px;
      margin-bottom: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 10px;
        cursor: pointer;
      }

      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 65%;

        @include mediaquery(xl) {
          flex-wrap: nowrap;
          justify-content: flex-start;
          width: auto;
          flex-direction: row;
          align-items: center;
        }
      }

      &__name {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(lg) {
          width: 200px;
        }

        @include mediaquery(xl) {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__address {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(xl) {
          width: 310px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__service {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 150px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }

      &__mileage {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(50% - 5px);
        }

        @include mediaquery(xl) {
          width: 150px;
          margin-bottom: 0;
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include mediaquery(md) {
          flex-direction: row;
          align-items: center;
        }
      }

      &__state {
        @include state;
        @include mediaquery(md) {
          margin: 0 10px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;
        width: 35%;
        margin-left: 10px;

        @include mediaquery(sm) {
          width: 30%;
        }
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__contract {
        font-size: 12px;
        font-weight: 500;
        margin: 5px 0 11px;
      }

      &__params {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
        }
      }

      &__param {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(25% - 10px);
        }
      }

      &__owner {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 10px 0 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          justify-content: flex-start;
          align-items: flex-end;

          .about__block {
            &:nth-last-child(2) {
              margin-bottom: 0;
              margin-right: 10px;
            }

            @include mediaquery(lg) {
              width: calc(20% - 19px);
              margin-bottom: 0;
            }
          }
        }
      }

      &__contacts {
        border-bottom: 1px solid #e1e1e1;
        padding: 10px 0 20px;
        margin-bottom: 25px;

        &-headline {
          font-size: 12px;
          font-weight: 500;
        }

        &-blocks {
          display: flex;
          flex-wrap: wrap;
        }

        @include mediaquery(sm) {
          margin-left: -5px;
          margin-right: -5px;
          align-items: flex-end;
        }
      }

      &__block {
        position: relative;
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(sm) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(20% - 10px);
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__vacation {
        @include base;

        width: 100%;
        padding: 10px 15px;
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__features {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1;
        padding: 20px 0;
      }

      &__tabs {
        display: flex;
        justify-content: space-between;
      }

      &__feature {
        @include base;

        width: 32%;
        padding: 10px 15px;
        display: flex;
        justify-content: space-between;

        &-title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-description {
          font-size: 10px;
          margin-top: 5px;
        }

        &-control {
          display: flex;
          align-items: center;
        }
      }

      &__documents {
        padding-top: 20px;
        display: flex;

        &-column {
          width: 50%;

          &:first-of-type {
            padding-right: 10px;
          }
        }
      }

      &__document {
        margin-bottom: 10px;
        &-wrapper {
          display: flex;
        }

        input {
          margin-right: 10px;
        }

        &-control {
          display: flex;
        }

        button {
          &:not(:last-of-type) {
            margin-right: 10px;
          }
        }
      }

      &__action {
        width: 36px;
        display: flex;
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px 0 0;

        button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }
        }

        @include mediaquery(sm) {
          flex-direction: row;

          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .scheme {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 20px;
      margin-bottom: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__headline {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__badge {
        margin-left: 5px;
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 7px;
        font-size: 10px;
      }

      &__main {
        display: flex;
        align-items: center;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__blocks {
        display: flex;
        flex-direction: column;
        padding: 10px 0 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #e1e1e1;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__block {
        width: 100%;
        margin-bottom: 10px;
        position: relative;

        @include mediaquery(md) {
          width: calc(25% - 8px);
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: 9px;
        color: #ff8068;
      }

      &__footer {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        margin-top: 20px;

        button {
          width: 100%;
          margin-bottom: 10px;
        }

        @include mediaquery(sm) {
          flex-direction: row;
          button {
            width: auto;
            margin-bottom: 0;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }
    }

    .maintenance {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;
      margin-bottom: 10px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
      }

      &__block {
        width: calc(50% - 10px);
        margin: 5px;
      }

      &__title {
        font-size: 9px;
        margin-bottom: 6px;
        color: #9b9b9b;
      }

      &__wrapper {
        display: flex;

        input {
          margin-right: 10px;
        }
      }
    }

    .documents {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__name {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &__description {
        font-size: 10px;
        margin-top: 5px;
      }

      &__control {
        display: flex;
        align-items: center;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__body {
        display: none;
      }

      &__preview {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 -5px;
        margin-top: -5px;
      }

      &__new {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-title {
          font-weight: 500;
          font-size: 12px;
          margin-left: 18px;
          color: #2b1a0a;
        }
      }

      &__document {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
        }

        &-icon {
          width: 46px;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-info {
          display: flex;
          align-items: center;
          margin-left: 17px;
        }

        &-name {
          font-size: 12px;
          font-weight: 500;
          color: #2b1a0a;
        }

        &-size {
          font-size: 10px;
          color: #2b1a0a;
          margin-left: 20px;
        }

        &-control {
          display: flex;

          button {
            margin-left: 10px;
          }
        }

        &-link {
          height: 36px;
          min-width: 36px;
          padding: 6px;
          border-radius: 6px;
          box-shadow: $shadowLight;
          border: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;

          @media (hover: hover) {
            &:hover {
              background-color: #fff;
            }
          }
        }

        &-icon {
          width: 20px;
          opacity: 0.5;
        }
      }

      &__blocks {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;
      }

      &__block {
        width: calc(50% - 10px);
        margin: 5px;
      }

      &__title {
        @include title;
      }

      &__wrapper {
        display: flex;

        input {
          margin-right: 10px;
        }
      }

      &__file {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        min-width: 36px;
        padding: 0;
        border-radius: 6px;
        box-shadow: $shadowLight;
        border: 0;
        cursor: pointer;
        overflow-x: hidden;

        &-input {
          position: absolute;
          width: 0;
          height: 0;
          opacity: 0;
          overflow: hidden;
          z-index: -1;
        }

        &-icon {
          width: 16px;
        }
      }
    }

    .regime {
      @include base;

      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px 20px;
      margin-bottom: 15px;

      &[data-type="hum"] {
        .regime__start-date,
        .regime__stop-date {
          background-color: $colorHum;
        }
      }

      &[data-type="temp"] {
        .regime__start-date,
        .regime__stop-date {
          background-color: $colorTemp;
        }
      }

      &[data-type="carb"] {
        .regime__start-date,
        .regime__stop-date {
          background-color: $colorCarb;
        }
      }

      &__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        cursor: pointer;
      }

      &__body {
        display: block;
      }

      &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 85%;

        @include mediaquery(md) {
          width: 50%;
        }

        @include mediaquery(lg) {
          width: 85%;
          flex-direction: row;
        }
      }

      &__list {
        width: 100%;
        margin-bottom: 10px;

        @include mediaquery(lg) {
          width: 200px;
          margin-bottom: 0;
        }
      }

      &__period {
        width: 100%;
        display: flex;
        flex-direction: column;
        cursor: auto;

        @include mediaquery(lg) {
          width: auto;
          flex-direction: row;
          margin-left: 10px;
        }
      }

      &__start,
      &__stop {
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #222325;
        background-color: $colorMainLight;
        border: 1px solid #dcdcdc;
        border-radius: 6px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(lg) {
          margin-right: 10px;
          margin-bottom: 0;

          &:last-of-type {
            margin-right: 0;
          }
        }

        &-strong {
          font-weight: 500;
          margin-right: 3px;
        }

        &-date {
          margin-left: 10px;
          text-transform: uppercase;
          color: #fff;
          padding: 5px 15px;
          border-radius: 15px;
          font-size: 10px;
          text-align: center;
          min-width: 45px;
        }
      }

      &__control {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 10px;
        width: 15%;
      }

      &__button {
        position: relative;
        background: none;
        padding: 0;
        border: 0;
        height: 11px;
        width: 11px;
        cursor: pointer;
        margin-left: 10px;

        &-icon {
          position: absolute;
          left: 2px;
          top: 0px;
          width: 7px;
          height: 7px;
          border: solid #bcbcbc;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);

          &_rotate {
            border-color: #222325;
            top: 3px;
            transform: rotate(225deg);
          }
        }
      }

      &__program {
        padding: 15px 0 10px;
        display: flex;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
        }
      }

      &__column {
        width: 100%;

        @include mediaquery(sm) {
          &:first-of-type {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
          }
        }

        @include mediaquery(md) {
          &:first-of-type {
            flex-direction: column;
            min-width: 200px;
            max-width: 200px;
            margin-right: 10px;
            margin-bottom: 0;
          }

          &:last-of-type {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: calc(100% - 210px);
          }
        }
      }

      &__schedule {
        @include base;

        padding: 10px;
        width: 100%;

        @include mediaquery(sm) {
          width: 50%;
        }

        @include mediaquery(md) {
          width: 100%;
        }

        &-headline {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
        }

        &-period {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &[data-period="morning"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #8bd341;
            }
            .timepicker {
              background-color: #8bd341;
            }
          }

          &[data-period="day"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #ff6eda;
            }
            .timepicker {
              background-color: #ff6eda;
            }
          }

          &[data-period="evening"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #7976ff;
            }
            .timepicker {
              background-color: #7976ff;
            }
          }

          &[data-period="night"] {
            .hum__schedule-title,
            .temp__schedule-title {
              color: #3a95ff;
            }
            .timepicker {
              background-color: #3a95ff;
            }
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-title {
          font-size: 12px;
          font-weight: 500;
        }

        &-range {
          display: flex;

          .timepicker {
            color: #fff;

            &:first-of-type {
              margin-right: 10px;
            }
          }
        }
      }

      &__settings {
        @include base;

        padding: 10px;
        display: flex;
        flex-direction: column;
        margin: 10px 0;

        @include mediaquery(sm) {
          width: 50%;
          margin: 0;
          margin-left: 10px;
        }

        @include mediaquery(md) {
          width: 100%;
          margin: 0;
          margin-top: 10px;
        }

        &-setting {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-wrapper {
          display: flex;
        }

        &-stage {
          font-weight: 500;
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
        }
      }

      &__zones {
        @include base;

        display: flex;
        flex-wrap: wrap;
        padding: 5px;
        padding-bottom: 0;
        min-height: 191.5px;
        width: 100%;
      }

      &__select {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 5px;

        .checkbox {
          height: 28.5px;
          margin-bottom: 10px;
        }
      }

      .ps {
        display: flex;
        width: calc(100% - 55px);
        padding: 5px;
        padding-bottom: 10px;
      }

      &__zone {
        margin-left: 10px;
        width: 100px;
        min-width: 100px;

        &:first-of-type {
          margin-left: 0;
        }

        &-title {
          font-size: 12px;
          color: #222325;
          text-transform: uppercase;
          margin-bottom: 13px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-value {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          user-select: none;
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &-button {
          position: absolute;
          border: 1px solid #bcbcbc;
          color: #bcbcbc;
          border-radius: 2px;
          cursor: pointer;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          transition: color 0.3s, border-color 0.3s;

          &:hover {
            border-color: #222325;
            color: #222325;
          }

          &:first-of-type {
            left: 7px;
          }

          &:last-of-type {
            right: 7px;
          }
        }

        &-minus {
          height: 2px;
          width: 10px;
          background-color: #bcbcbc;
        }

        &-plus {
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;

          &::before,
          &::after {
            content: "";
            position: absolute;
            top: 8px;
            left: 4px;
            display: block;
            width: 10px;
            height: 2px;
            background: #bcbcbc;
          }

          &::before {
            transform: rotate(0deg);
          }

          &::after {
            transform: rotate(90deg);
          }
        }

        input {
          text-align: center;
          height: 28.5px;
          padding: 6px 14px;
          margin-bottom: 10px;
          font-weight: 500;

          &:last-of-type {
            margin-bottom: 0;
          }

          &[data-period="morning"] {
            color: #8bd341;
            -webkit-text-fill-color: #8bd341;
          }

          &[data-period="day"] {
            color: #ff6eda;
            -webkit-text-fill-color: #ff6eda;
          }

          &[data-period="evening"] {
            color: #7976ff;
            -webkit-text-fill-color: #7976ff;
          }

          &[data-period="night"] {
            color: #3a95ff;
            -webkit-text-fill-color: #3a95ff;
          }
        }
      }

      &__tuner {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 15px 0;

        @include mediaquery(sm) {
          margin: 10px 0;
        }

        &-period,
        &-common {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include mediaquery(sm) {
            justify-content: flex-start;
          }
        }

        &-period {
          margin-bottom: 20px;
        }

        &-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        &-title {
          font-weight: 500;
          font-size: 10px;
          color: #222325;
          text-transform: uppercase;

          @include mediaquery(sm) {
            min-width: 120px;
            margin-right: 10px;
          }
        }

        &-value {
          font-size: 12px;
          font-weight: 500;
          margin-left: 5px;
          color: #bcbcbc;
          min-width: 35px;
          text-align: right;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        @include mediaquery(sm) {
          flex-direction: row;
        }

        &-control {
          display: flex;
          flex-direction: column;
          width: 100%;

          @include mediaquery(sm) {
            flex-direction: row;
            justify-content: flex-end;
          }

          button {
            width: 100%;

            &:first-of-type {
              margin-bottom: 10px;
            }

            @include mediaquery(sm) {
              width: auto;
              margin-bottom: 0;

              &:first-of-type {
                margin-right: 10px;
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .zone {
      @include base;

      width: 100%;
      border: 2px solid $colorMainLight;
      padding: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include mediaquery(md) {
        width: calc(50% - 10px);
        margin: 5px;
      }

      @include mediaquery(xl) {
        width: calc(33.333333% - 10px);
      }

      @include mediaquery(hg) {
        width: calc(25% - 10px);
      }

      &_current {
        border: 2px solid #41c6eb;
      }

      &__body {
        position: relative;
      }

      &__name {
        color: #222325;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        &-input {
          border: 1px solid transparent;
          border-radius: 6px;
          background: none;
          font-family: inherit;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;

          &:hover {
            border: 1px solid #dcdcdc;
          }

          &:focus {
            border: 1px solid #41c6eb;
          }

          &_valid {
            border-color: #41c6eb;
          }

          &_invalid {
            border-color: #ff8068 !important;
          }
        }
      }

      &__programs {
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-title {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-body {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          margin-left: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: 20px;
        }

        .zone__icon {
          width: 20px;
          margin: 0 5px 0 0;
        }
      }

      &__program {
        display: flex;
        align-items: center;
        margin: 0 5px;
        font-size: 14px;
        text-transform: uppercase;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &[data-type="hum"] {
          .zone__program-title {
            color: $colorHum;
          }
        }

        &[data-type="temp"] {
          .zone__program-title {
            color: $colorTemp;
          }
        }

        &[data-type="carb"] {
          .zone__program-title {
            color: $colorCarb;
          }
        }
      }

      &__info {
        display: flex;
        margin-top: 15px;
      }

      &__caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
        color: #363636;
        font-size: 11px;
        font-weight: 500;

        &-line {
          background: #363636;
          border: 0;
          width: 100%;
          height: 1px;
          margin: 7px 0;
        }
      }

      &__indicators {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }

      &__indicator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        box-shadow: $shadowLight;
        color: #fff;
        padding: 5px 10px;
        flex-grow: 1;
        width: calc(33.333333% - 10px);
        min-width: calc(33.333333% - 10px);
        min-height: 75px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &[data-param="temp"] {
          background-color: $colorTemp;
        }

        &[data-param="hum"] {
          background-color: $colorHum;
        }

        &[data-param="carb"] {
          background-color: $colorCarb;

          .zone__set,
          .zone__cur {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
          }

          .zone__set-unit,
          .zone__cur-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__set {
        font-size: 19px;
        font-weight: 500;
        margin-top: 6px;
        white-space: nowrap;

        svg {
          visibility: hidden;
        }

        &-dash {
          font-weight: 400;

          &:not(:first-of-type) {
            margin-left: 3px;
          }

          ~ .zone__set-unit {
            visibility: hidden;
          }
        }
      }

      &__cur {
        display: flex;
        align-items: center;
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 6px;

        &-wrapper {
          white-space: nowrap;
        }

        &-value {
          margin-left: 2px;
        }
      }

      &__led {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 5px;
        right: 5px;

        &_on {
          background: linear-gradient(212.01deg, #dfffbe 13.85%, #9eff3d 82.31%);
        }

        &_off {
          background: linear-gradient(208.61deg, #fc4f4f 0.59%, #ce0909 92.94%);
          background: none;
        }
      }

      &__errors {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        margin-bottom: 10px;
      }

      &__error {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-dot {
          background-color: #ff5e3f;
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 9px;
        }

        &-date {
          font-size: 9px;
          color: #000000;
        }

        &-text {
          font-size: 12px;
          color: #ff5e3f;
          text-transform: uppercase;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__params {
        display: flex;
        flex-direction: column;
      }

      &__param {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 10px;
        border: 2px solid transparent;
        border-radius: 6px;

        &[data-param="hum"] {
          border-color: $colorHum;

          .zone__set {
            color: $colorHum;
          }
        }

        &[data-param="temp"] {
          border-color: $colorTemp;

          .zone__set {
            color: $colorTemp;
          }
        }

        &[data-param="carb"] {
          border-color: $colorCarb;

          .zone__set {
            color: $colorCarb;
          }
        }
      }

      &__wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
      }

      &__limits {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &__limit {
        width: calc(50% - 5px);
      }

      &__tuner {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .slider {
          width: 100%;
          margin: 4px 0;
        }
      }

      &__icon {
        width: 28px;
        margin-left: 10px;
        margin-bottom: -3px;
      }

      &__block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 10px;

        &:not(:first-of-type) {
          margin-top: 10px;
        }
      }

      &__modules {
        display: flex;
        flex-wrap: wrap;
      }

      &__module {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__subblock {
        position: relative;

        &:first-of-type {
          width: calc(50% - 5px);
        }
        &:last-of-type {
          width: calc(50% - 51px);
        }
      }

      &__control {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        .button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: calc(50% - 5px);

            &:first-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }

      &__arrow {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }
    }

    .entrance {
      @include base;

      width: 100%;
      border: 2px solid $colorMainLight;
      padding: 10px;
      margin-bottom: 10px;
      cursor: pointer;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include mediaquery(md) {
        width: calc(50% - 10px);
        margin: 5px;
      }

      @include mediaquery(xl) {
        width: calc(33.333333% - 10px);
      }

      @include mediaquery(hg) {
        width: calc(25% - 10px);
      }

      &_current {
        border: 2px solid #41c6eb;
      }

      &__body {
        position: relative;
      }

      &__name {
        color: #222325;
        display: flex;
        justify-content: center;
        margin-bottom: 5px;

        &-input {
          border: 1px solid transparent;
          border-radius: 6px;
          background: none;
          font-family: inherit;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 5px;

          &:hover {
            border: 1px solid #dcdcdc;
          }

          &:focus {
            border: 1px solid #41c6eb;
          }

          &_valid {
            border-color: #41c6eb;
          }

          &_invalid {
            border-color: #ff8068 !important;
          }
        }
      }

      &__programs {
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        &-title {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &-body {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          margin-left: 5px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-height: 20px;
        }

        .zone__icon {
          width: 20px;
          margin: 0 5px 0 0;
        }
      }

      &__program {
        display: flex;
        align-items: center;
        margin: 0 5px;
        font-size: 14px;
        text-transform: uppercase;

        &:first-of-type {
          margin-left: 0;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &[data-type="hum"] {
          .zone__program-title {
            color: $colorHum;
          }
        }

        &[data-type="temp"] {
          .zone__program-title {
            color: $colorTemp;
          }
        }

        &[data-type="carb"] {
          .zone__program-title {
            color: $colorCarb;
          }
        }
      }

      &__info {
        display: flex;
        margin-top: 15px;
      }

      &__caption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: 15px;
        color: #363636;
        font-size: 11px;
        font-weight: 500;

        &-line {
          background: #363636;
          border: 0;
          width: 100%;
          height: 1px;
          margin: 7px 0;
        }
      }

      &__indicators {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
      }

      &__indicator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: 6px;
        box-shadow: $shadowLight;
        color: #fff;
        padding: 5px 10px;
        flex-grow: 1;
        width: calc(33.333333% - 10px);
        min-width: calc(33.333333% - 10px);
        min-height: 75px;

        &:not(:last-of-type) {
          margin-right: 10px;
        }

        &[data-param="temp"] {
          background-color: $colorTemp;
        }

        &[data-param="hum"] {
          background-color: $colorHum;
        }

        &[data-param="carb"] {
          background-color: $colorCarb;

          .zone__set,
          .zone__cur {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;
          }

          .zone__set-unit,
          .zone__cur-unit {
            font-size: 7px;
            margin-top: -3px;
          }
        }
      }

      &__set {
        font-size: 19px;
        font-weight: 500;
        margin-top: 6px;
        white-space: nowrap;

        svg {
          visibility: hidden;
        }

        &-dash {
          font-weight: 400;

          &:not(:first-of-type) {
            margin-left: 3px;
          }

          ~ .zone__set-unit {
            visibility: hidden;
          }
        }
      }

      &__cur {
        display: flex;
        align-items: center;
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 6px;

        &-wrapper {
          white-space: nowrap;
        }

        &-value {
          margin-left: 2px;
        }
      }

      &__led {
        position: absolute;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 5px;
        right: 5px;

        &_on {
          background: linear-gradient(212.01deg, #dfffbe 13.85%, #9eff3d 82.31%);
        }

        &_off {
          background: linear-gradient(208.61deg, #fc4f4f 0.59%, #ce0909 92.94%);
          background: none;
        }
      }

      &__errors {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        margin-bottom: 10px;
      }

      &__error {
        @include base;

        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;

        &:last-of-type {
          margin-bottom: 0;
        }

        &-wrapper {
          display: flex;
          align-items: center;
        }

        &-dot {
          background-color: #ff5e3f;
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 9px;
        }

        &-date {
          font-size: 9px;
          color: #000000;
        }

        &-text {
          font-size: 12px;
          color: #ff5e3f;
          text-transform: uppercase;
        }
      }

      &__footer {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
      }

      &__params {
        display: flex;
        flex-direction: column;
      }

      &__param {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 10px;
        padding: 10px;
        border: 2px solid transparent;
        border-radius: 6px;

        &[data-param="hum"] {
          border-color: $colorHum;

          .zone__set {
            color: $colorHum;
          }
        }

        &[data-param="temp"] {
          border-color: $colorTemp;

          .zone__set {
            color: $colorTemp;
          }
        }

        &[data-param="carb"] {
          border-color: $colorCarb;

          .zone__set {
            color: $colorCarb;
          }
        }
      }

      &__wrapper {
        width: 100%;
        display: flex;
        align-items: flex-end;
        margin-bottom: 10px;
      }

      &__limits {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      &__limit {
        width: calc(50% - 5px);
      }

      &__tuner {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 10px;

        .slider {
          width: 100%;
          margin: 4px 0;
        }
      }

      &__icon {
        width: 28px;
        margin-left: 10px;
        margin-bottom: -3px;
      }

      &__modules {
        display: flex;
        flex-wrap: wrap;
      }

      &__module {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__title {
        @include title;
      }

      &__subblock {
        position: relative;

        &:first-of-type {
          width: calc(50% - 5px);
        }
        &:last-of-type {
          width: calc(50% - 51px);
        }
      }

      &__control {
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        @include mediaquery(sm) {
          flex-direction: row;
          justify-content: space-between;
        }

        .button {
          width: 100%;

          &:first-of-type {
            margin-bottom: 10px;
          }

          @include mediaquery(sm) {
            width: calc(50% - 5px);

            &:first-of-type {
              margin-bottom: 0px;
            }
          }
        }
      }

      &__arrow {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }

      &__blocks {
        display: flex;
        flex-direction: column;
      }

      &__block {
        @include base;
        padding: 20px;
        margin-bottom: 10px;
        flex-direction: column;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__floor {
        @include base;
        padding: 10px;
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .filtres,
      .pumps,
      .collectors,
      .controllers {
        @include base;

        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        margin-bottom: 10px;

        &__header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          cursor: pointer;
        }

        &__title {
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        }

        &__control {
          display: flex;
          align-items: center;
        }

        &__button {
          position: relative;
          background: none;
          padding: 0;
          border: 0;
          height: 11px;
          width: 11px;
          cursor: pointer;
          margin-left: 10px;

          &-icon {
            position: absolute;
            left: 2px;
            top: 0px;
            width: 7px;
            height: 7px;
            border: solid #bcbcbc;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);

            &_rotate {
              border-color: #222325;
              top: 3px;
              transform: rotate(225deg);
            }
          }
        }

        &__body {
          display: none;
          padding-top: 10px;
        }

        &__block {
          @include base;

          display: flex;
          flex-direction: column;
          padding: 10px;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        &__name {
          font-weight: 500;
          text-align: center;
          margin-bottom: 10px;
        }

        &__params {
          display: flex;
          justify-content: space-between;
        }

        &__param {
          @include title;
        }

        &__subblock {
          width: calc(50% - 5px);
        }
      }

      .controllers {
        margin-bottom: 0;

        &__params {
          flex-direction: column;
        }

        &__floor {
          @include base;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>