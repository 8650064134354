import axios from 'axios'

export default {
  state: {
    navbar: {
      search: {
        query: null,
        validation: false,
        result: null,
      },
    },
    about: {
      extended: localStorage.getItem('building__about-extended') === 'true' ? true : false || false,
      cancellation: true,
      validation: false,
    },
    scheme: {},
    children: {
      sort: {
        param: null,
        order: null,
      },
      list: []
    },
    entrances: [],
  },
  getters: {
    getBuildingNavbar: state => state.navbar,
    getBuildingAbout: state => state.about,
    getBuildingScheme: state => state.scheme,
    getBuildingChildren: state => state.children,
    getBuildingEntrances: state => state.entrances,
  },
  mutations: {
    toggleBuildingSection(state, payload) {
      state[payload].extended = !state[payload].extended;

      localStorage.setItem(`building__${payload}-extended`, state[payload].extended);
    },
    setBuildingActiveNavbarItem(state, item) {
      state.navbar.list.forEach(element => element.isActive = false);
      state.navbar.list.forEach(element => {
        if (element.id == item.getAttribute('data-id')) {
          element.isActive = true;
          state.navbar.current = +item.getAttribute('data-id');
          localStorage.setItem(`building__navbar-current`, +item.getAttribute('data-id'));
        }
      });
    },
    updateBuildingNavbar(state, payload) {
      state.navbar = {
        current: localStorage.getItem(`building__navbar-current`) ? +localStorage.getItem(`building__navbar-current`) : 1,
        search: {
          query: null,
          validation: false,
        },
        back: 'Список зданий',
      };

      state.navbar.list = [{
          id: 1,
          quantity: 6,
          name: 'Информация',
          feature: payload.contract.value,
          isActive: state.navbar.current === 1 ? true : false,
        },
        {
          id: 2,
          quantity: payload.children.length,
          name: 'Локальные системы',
          feature: payload.contract.value,
          isActive: state.navbar.current === 2 ? true : false,
        },
      ];
    },
    updateBuildingAbout(state, payload) {
      state.about.id = payload.id;
      state.about.contract = payload.contract;
      state.about.from_user = payload.from_user;
      state.about.active_user = payload.active_user;
      state.about.active_srv = payload.active_srv;
      state.about.errors = payload.errors;
      state.about.date = payload.date;
      state.about.mileage = payload.mileage;
      state.about.pump_hours = payload.pump_hours;
      state.about.type = payload.type;
      state.about.system_type = payload.system_type;
      state.about.system_architecture = payload.system_architecture;
      state.about.name = {
        element: 'input',
        name: 'name',
        value: payload.name,
        pattern: /^[а-яА-ЯёЁa-zA-Z0-9\s]{2,30}$/,
        status: 'default',
        error: false,
        errorText: 'Неверный формат',
      };
      state.about.address = payload.address;
      state.about.date_start = payload.date_start;
      state.about.date_install = payload.date_install;
      state.about.date_warranty = payload.date_warranty;
      state.about.date_maintenance_plan = payload.date_maintenance_plan;
      state.about.documents = payload.documents;
      state.about.modes = payload.modes;

      if (payload.state === 0) {
        state.about.state = {
          value: 'disabled',
          text: 'выключена'
        }
      }

      if (payload.state === 1) {
        state.about.state = {
          value: 'enabled',
          text: 'включена'
        }
      }

      if (payload.state === 2) {
        state.about.state = {
          value: 'error',
          text: 'ошибка'
        }
      }

      if (payload.state === 3) {
        state.about.state = {
          value: 'active',
          text: 'работает'
        }
      }

      if (payload.state === 4) {
        state.about.state = {
          value: 'inactive',
          text: 'не работает'
        }
      }
    },
    setBuildingActiveEntrance(state, payload) {
      state.entrances.forEach((entrance) => {
        if (payload.id !== entrance.id) {
          entrance.current = false;
          entrance.cancellation = true;
          entrance.validation = false;
          entrance.extended = false;

          /* if (state.backup.entrances && entrance.id === state.backup.zones[index].id) {
            zone.hum = state.backup.zones[index].hum;
            zone.temp = state.backup.zones[index].temp;
            zone.name = state.backup.zones[index].name;
            zone.num = state.backup.zones[index].num;
            zone.device = state.backup.zones[index].device;
            zone.devs = state.backup.zones[index].devs;
            zone.nozzle = state.backup.zones[index].nozzle;
            zone.nozzles = state.backup.zones[index].nozzles;
            zone.status = 'default';
            state.backup.zones[index] = {};
          } */
        }
      });
      state.entrances.forEach(entrance => entrance.id === payload.id ? entrance.current = true : false);
      state.deleting = false;
    },
    extendBuildingEntrance(state, payload) {
      state.entrances.forEach(entrance => {
        if (entrance.id === payload.id) {
          entrance.extended = true;
        }
      });
    },
    updateBuildingEntrances(state, payload) {
      state.entrances = JSON.parse(JSON.stringify(payload.entrances));
    },
    updateBuildingObjects(state, payload) {
      console.log(state);
      console.log(payload);
    },
    updateBuildingChildren(state, payload) {
      state.children.list = payload.children;

      state.children.list.forEach(child => {
        if (payload.state === 0) {
        child.state = {
          value: 'disabled',
          text: 'выключена'
        }
      }

      if (payload.state === 1) {
        child.state = {
          value: 'enabled',
          text: 'включена'
        }
      }

      if (payload.state === 2) {
        child.state = {
          value: 'error',
          text: 'ошибка'
        }
      }

      if (payload.state === 3) {
        child.state = {
          value: 'active',
          text: 'работает'
        }
      }

      if (payload.state === 4) {
        child.state = {
          value: 'inactive',
          text: 'не работает'
        }
      }
      });
    },
    sortBuildingTable(state, payload) {
      let order = '';

      if (payload.order === null) payload.order = 'asc';
      payload.order === 'asc' ? order = 'desc' : order = 'asc';

      if (payload.tab === 'objects') {
        if (payload.param === 'date') {
          if (payload.order === 'asc') {
            state.objects.list.sort((a, b) => b.contract.date - a.contract.date);
          }
          if (payload.order === 'desc') {
            state.objects.list.sort((a, b) => a.contract.date - b.contract.date);
          }
        }

        if (payload.param === 'type') {
          state.objects.list.sort((a, b) => {
            let typeA = a.type.toLowerCase();
            let typeB = b.type.toLowerCase();

            if (payload.order === 'asc') {
              if (typeA < typeB) return -1
              if (typeA > typeB) return 1
              return 0
            }

            if (payload.order === 'desc') {
              if (typeA > typeB) return -1
              if (typeA < typeB) return 1
              return 0
            }
          })
        }

        if (payload.param === 'address') {
          state.objects.list.sort((a, b) => {
            let addressA = a.address.toLowerCase();
            let addressB = b.address.toLowerCase();

            if (payload.order === 'asc') {
              if (addressA < addressB) return -1
              if (addressA > addressB) return 1
              return 0
            }

            if (payload.order === 'desc') {
              if (addressA > addressB) return -1
              if (addressA < addressB) return 1
              return 0
            }
          })
        }

        if (payload.param === 'state') {
          state.objects.list.sort((a, b) => {
            let stateA = a.state.text.toLowerCase();
            let stateB = b.state.text.toLowerCase();

            if (payload.order === 'asc') {
              if (stateA < stateB) return -1
              if (stateA > stateB) return 1
              return 0
            }

            if (payload.order === 'desc') {
              if (stateA > stateB) return -1
              if (stateA < stateB) return 1
              return 0
            }
          })
        }
      }

      state[payload.tab].sort.param = payload.param;
      state[payload.tab].sort.order = order;
    },
    changeBuildingObjectsSearch(state, payload) {
      if (payload.length > 2) state.navbar.search.validation = true;
      else state.navbar.search.validation = false;

      state.navbar.search.query = payload;
    },
    clearBuildingStateSearch(state, payload) {
      if (payload) {
        state.navbar.search.result = payload;
        state.navbar.search.validation = false;
      } else {
        state.navbar.search.result = null;
        state.navbar.search.query = null;
        state.navbar.search.validation = false;
      }
    },
    resetBuildingState(state) {
      if (state.entrances) {
        state.entrances.forEach((entrance) => {
          /* if (state.backup.zones && state.backup.zones.length > 0) {
            if (zone.id === state.backup.zones[index].id) {
              zone.hum = state.backup.zones[index].hum;
              zone.temp = state.backup.zones[index].temp;
              zone.name = state.backup.zones[index].name;
              zone.num = state.backup.zones[index].num;
              zone.device = state.backup.zones[index].device;
              zone.devs = state.backup.zones[index].devs;
              zone.nozzle = state.backup.zones[index].nozzle;
              zone.nozzles = state.backup.zones[index].nozzles;
              zone.status = 'default';
              state.backup.zones[index] = {};
            }
          } */
          entrance.current = false;
          entrance.cancellation = true;
          entrance.validation = false;
          entrance.extended = false;
        });
      }
    },
    clearBuildingState(state) {
      state.navbar = {};
    }
  },
  actions: {
    fetchBuilding(context, payload) {
      context.commit("showPreloader");

      axios
        .get(`https://api.airwet.app/api/getSystem/${payload}`, this.state.common.headers)
        .then((response) => {
          context.commit("destroyNotification");
          context.commit('updateBuildingNavbar', response.data);
          context.commit('updateBuildingAbout', response.data);
          context.commit('updateBuildingEntrances', response.data);
          context.commit('updateBuildingChildren', response.data);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    searchBuildingObjects(context, payload) {
      context.commit("showPreloader");

      axios
        .get(`https://api.airwet.app/api/systems/?populate[0]=contract&filters[name][$containsi]=${payload}`, this.state.common.headers)
        .then((response) => {
          context.commit('updateBuildingObjects', response.data.data);
          context.commit('clearBuildingStateSearch', response.data.data);
          context.commit("hidePreloader");
        })
        .catch((error) => context.commit("handleError", error));
    },
    clearBuildingState(context) {
      context.commit("showPreloader");

      if (context.state.navbar.search.result) {
        axios
          .get("https://api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
          .then((response) => {
            context.commit('updateBuildingObjects', response.data.data);
            context.commit('clearBuildingStateSearch');
            context.commit("hidePreloader");
          })
          .catch((error) => context.commit("handleError", error));
      } else {
        context.commit('clearBuildingStateSearch');
        context.commit("hidePreloader");
      }
    },
    createBuildingObject(context, payload) {
      context.commit("showPreloader");

      if (payload.owner === 'new') {
        let acronym = '';

        if (payload.first_name !== null && payload.last_name !== null) {
          acronym = `${payload.first_name.substr(0, 1)}${payload.last_name.substr(0, 1)}`;
        }

        axios
          .post("https://api.airwet.app/api/auth/local/register", {
            username: payload.email.substring(0, payload.email.indexOf('@')),
            email: payload.email,
            password: payload.password,
            first_name: payload.first_name,
            last_name: payload.last_name,
            middle_name: payload.middle_name,
            phone: payload.phone,
            acronym: acronym,
            first_entry: true,
          }, this.state.common.headers)
          .then((user) => {
            axios
              .post("https://api.airwet.app/api/users/sendMailToNewUser", {
                user_id: user.data.user.id,
                password: payload.password,
              }, this.state.common.headers)
              .then(() => {
                axios
                  .post('https://api.airwet.app/api/systems', {
                    data: {
                      type: payload.type,
                      control_channels: payload.control_channels,
                      address: payload.address,
                      name: payload.name,
                      from_users: {
                        connect: [user.data.data.id]
                      }
                    },
                  }, this.state.common.headers)
                  .then((system) => {
                    axios
                      .post('https://api.airwet.app/api/contracts', {
                        data: {
                          value: payload.contract,
                          phone: payload.phone,
                          date: payload.date,
                          from_system: system.data.data.id,
                        },
                      }, this.state.common.headers)
                      .then(() => {
                        axios
                          .post("https://api.airwet.app/api/boards", {
                            data: {
                              params: {
                                features: {
                                  "p_in_min": 0.8,
                                  "p_in_max": 7,
                                  "p_out_min": 35,
                                  "p_out_nom": 55,
                                  "p_out_max": 85,
                                  "p_precharge": 25,
                                  "precharge_timeout": 45,
                                  "p_delay": 10,
                                  "p_timeout": 2,
                                  "pump_rpm": 750,
                                  "Kp": 3,
                                  "Ki": 0.5,
                                  "Kd": 0,
                                  "p_drain": 32,
                                  "discharge_timeout": 300,
                                  "t_drain": 3,
                                  "t_open": 60,
                                  "t_close": 60,
                                  "hum_min": 0,
                                  "hum_max": 58,
                                },
                                network: {
                                  "MAC": "",
                                  "IP": "",
                                  "mask": "",
                                  "gateway": "",
                                  "DNS": ""
                                }
                              },
                              from_system: system.data.data.id
                            },
                          }, this.state.common.headers)
                          .then(() => {
                            axios
                              .get(`https://api.airwet.app/api/systems/?populate[0]=contract,from_users&pagination[start]=${this.state.common.pagination.start}&pagination[limit]=${this.state.common.pagination.limit}`, this.state.common.headers)
                              .then((response) => {
                                context.commit('updateBuildingObjects', response.data.data);
                                context.commit("hidePreloader");
                              })
                          })
                      })
                  })
              })
          })
          .catch((error) => context.commit("handleError", error));
      }

      if (payload.owner === 'exist') {
        axios
          .get(`https://api.airwet.app/api/users/GetUserIdFromEmail/${payload.email}`, this.state.common.headers)
          .then((user) => {
            axios
              .post('https://api.airwet.app/api/systems', {
                data: {
                  type: payload.type,
                  control_channels: payload.control_channels,
                  address: payload.address,
                  name: payload.name,
                  from_users: {
                    connect: [user.data.data.id]
                  }
                },
              }, this.state.common.headers)
              .then((system) => {
                axios
                  .post('https://api.airwet.app/api/contracts', {
                    data: {
                      value: payload.contract,
                      phone: payload.phone,
                      date: payload.date,
                      from_system: system.data.data.id,
                    },
                  }, this.state.common.headers)
                  .then(() => {
                    axios
                      .post("https://api.airwet.app/api/boards", {
                        data: {
                          params: {
                            features: {
                              "p_in_min": 0.8,
                              "p_in_max": 7,
                              "p_out_min": 35,
                              "p_out_nom": 55,
                              "p_out_max": 85,
                              "p_precharge": 25,
                              "p_drain": 32,
                              "p_delay": 10,
                              "p_timeout": 2,
                              "t_drain": 3,
                              "t_open": 60,
                              "t_close": 60,
                              "pump_rpm": 750,
                              "Kp": 3,
                              "Ki": 0.5,
                              "Kd": 0,
                              "discharge_timeout": 300,
                              "precharge_timeout": 45,
                            },
                            network: {
                              "MAC": "",
                              "IP": "",
                              "mask": "",
                              "gateway": "",
                              "DNS": ""
                            }
                          },
                          from_system: system.data.data.id
                        }
                      }, this.state.common.headers)
                      .then(() => {
                        axios
                          .get("https://api.airwet.app/api/systems/?populate[0]=contract", this.state.common.headers)
                          .then((response) => {
                            context.commit('updateBuildingObjects', response.data.data);
                            context.commit("hidePreloader");
                          })
                      })
                  })
              })
          })
          .catch((error) => context.commit("handleError", error));
      }
    },
    editBuildingObject(context, payload) {
      context.commit("showPreloader");

      let system = {
        data: {
          type: payload.type,
          control_channels: payload.control_channels,
          address: payload.address,
          name: payload.name,
          from_users: {
            connect: [payload.user]
          }
        }
      };

      let contract = {
        data: {
          value: payload.contract,
          phone: payload.phone,
          date: payload.date,
          from_system: payload.system,
        }
      };

      axios
        .put(`https://api.airwet.app/api/updateSystem/${payload.system}`, system, this.state.common.headers)
        .then(() => {
          axios
            .put(`https://api.airwet.app/api/contracts/${payload.contractID}`, contract, this.state.common.headers)
            .then(() => {
              axios
                .get(`https://api.airwet.app/api/systems/?populate[0]=contract,from_users&pagination[start]=${this.state.common.pagination.start}&pagination[limit]=${this.state.common.pagination.limit}`, this.state.common.headers)
                .then((response) => {
                  context.commit('updateBuildingObjects', response.data.data);
                  context.commit("hidePreloader");
                })
            })
        })
        .catch((error) => context.commit("handleError", error));
    },
    deleteBuildingObject(context, payload) {
      context.commit("showPreloader");

      axios
        .delete(`https://api.airwet.app/api/systems/${payload.system}`, this.state.common.headers)
        .then(() => {
          axios
            .get(`https://api.airwet.app/api/systems/?populate[0]=contract,from_users&pagination[start]=${this.state.common.pagination.start}&pagination[limit]=${this.state.common.pagination.limit}`, this.state.common.headers)
            .then((response) => {
              context.commit('updateBuildingObjects', response.data.data);
              context.commit("hidePreloader");
            })
        })
        .catch((error) => context.commit("handleError", error));
    }
  }
}