import {
  createStore
} from 'vuex';

import authorization from './authorization';
import building from './building';
import common from './common';
import profile from './profile';
import unit from './unit';

export const store = createStore({
  modules: {
    authorization,
    building,
    common,
    profile,
    unit,
  },
})