<template>
  <div class="building__children">
    <div class="building__children-data">
      <perfect-scrollbar>
        <table class="building__children-table">
          <thead class="building__children-thead">
            <tr class="building__children-row">
              <th
                class="building__children-th building__children-th_date"
                data-id="date"
              >
                <div class="building__children-wrapper">
                  <div class="building__children-content">
                    Дата
                    <button class="building__children-sort" @click="sortTable">
                      <span class="building__children-up"></span>
                      <span class="building__children-down"></span>
                    </button>
                  </div>
                </div>
              </th>
              <th class="building__children-th building__children-th_contract">
                <div class="building__children-wrapper">
                  <div class="building__children-content">Договор</div>
                </div>
              </th>
              <th
                class="building__children-th building__children-th_type"
                data-id="type"
              >
                <div class="building__children-wrapper">
                  <div class="building__children-content">
                    Тип
                    <button class="building__children-sort" @click="sortTable">
                      <span class="building__children-up"></span
                      ><span class="building__children-down"></span>
                    </button>
                  </div>
                </div>
              </th>
              <th class="building__children-th">
                <div class="building__children-wrapper">
                  <div class="building__children-content">Дилер</div>
                </div>
              </th>
              <th class="building__children-th building__children-th_name">
                <div class="building__children-wrapper">
                  <div class="building__children-content">Имя объекта</div>
                </div>
              </th>
              <th class="building__children-th building__children-th_contacts">
                <div class="building__children-wrapper">
                  <div class="building__children-content">Контакты</div>
                </div>
              </th>
              <th
                class="building__children-th building__children-th_state"
                data-id="state"
              >
                <div class="building__children-wrapper">
                  <div class="building__children-content">
                    Статус
                    <button class="building__children-sort" @click="sortTable">
                      <span class="building__children-up"></span
                      ><span class="building__children-down"></span>
                    </button>
                  </div>
                </div>
              </th>
              <th class="building__children-th building__children-th_action">
                <div class="building__children-wrapper">
                  <div class="building__children-content">Действия</div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="building__children-tbody">
            <tr
              class="building__children-row"
              v-for="row in children.list"
              :id="row.id"
              :key="row.id"
              @click="selectChild($event, row.id)"
            >
              <td class="building__children-td building__children-td_date">
                <div class="building__children-wrapper">
                  {{ convertDate(row.contract.date) }}
                </div>
              </td>
              <td class="building__children-td building__children-td_contract">
                <div class="building__children-wrapper">
                  {{ row.contract.value }}
                </div>
              </td>
              <td class="building__children-td building__children-td_type">
                <div class="building__children-wrapper">
                  <span
                    :class="[
                      row.type === 'pro'
                        ? 'building__children-type building__children-type_pro'
                        : 'building__children-type',
                    ]"
                    >{{ row.type }}</span
                  >
                </div>
              </td>
              <td class="building__children-td">
                <div class="building__children-wrapper">Климат Тех</div>
              </td>
              <td class="building__children-td building__children-td_name">
                <div class="building__children-wrapper">
                  {{ row.name }}
                </div>
              </td>
              <td class="building__children-td">
                <div class="building__children-wrapper">
                  {{ row.contract.phone }}
                </div>
              </td>
              <td class="building__children-td">
                <div class="building__children-wrapper">
                  <span
                    :class="`building__children-state building__children-state_${row.state.value}`"
                  >
                    {{ row.state.text }}</span
                  >
                </div>
              </td>
              <td class="building__children-td">
                <!-- <button class="building__children-edit">
                  <font-awesome-icon icon="fa-pen-to-square" />
                </button>
                <button class="building__children-delete">
                  <font-awesome-icon icon="trash-can" />
                </button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
    <Pagination @click="handlePagination" v-if="false" />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  import Pagination from "../layout/Pagination";

  export default {
    name: "BuildingChildren",
    components: {
      Pagination,
    },
    computed: {
      ...mapGetters({
        children: "getBuildingChildren",
      }),
    },
    methods: {
      ...mapActions([
        "fetchUnit",
      ]),
      convertDate(timestamp, type) {
        if (timestamp !== null) {
          let newDate = new Date(timestamp * 1000);
          let year = newDate.getFullYear();
          let month = newDate.getMonth() + 1;
          if (month < 10) month = "0" + month;
          let day = newDate.getDate();
          if (day < 10) day = "0" + day;
          let hour = newDate.getHours();
          if (hour < 10) hour = "0" + hour;
          let min = newDate.getMinutes();
          if (min < 10) min = "0" + min;
          let sec = newDate.getSeconds();
          if (sec < 10) sec = "0" + sec;

          if (type && type === "full")
            return `${day}.${month}.${year} ${hour}:${min}:${sec}`;
          else return `${day}.${month}.${year}`;
        }
      },
      selectChild(event, id) {
        localStorage.setItem("building__previous-id", event.target.closest(".building").getAttribute('data-id'));
        localStorage.removeItem("building__navbar-current");

        this.$router.push(`/unit/${id}`);
        this.fetchUnit(id);
      },
      handlePagination(event) {
        if (
          event.target.closest(".pagination__button") ||
          event.target.closest(".pagination__page")
        ) {
          this.fetchBuildingTasks();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .building {
    &__children {
      &-data {
        @include base;
      }

      .ps {
        width: 100%;
        padding: 0 10px 10px;
      }

      &-table {
        width: 100%;
        min-width: 1100px;
        border-collapse: collapse;
      }

      &-thead {
        .building__children-wrapper {
          min-height: 36px;
          border-right: 0;
        }
      }

      &-tbody {
        @include mediaquery(md) {
          .building__children-row {
            &:hover {
              cursor: pointer;
              outline: 2px solid #41c6eb;
            }
          }
        }
      }

      &-row {
        background-color: $colorMainLight;
      }

      &-th,
      &-td {
        padding: 8px 0;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        text-align: left;

        &:last-of-type {
          .building__children-wrapper {
            border-right: 0;
          }
        }
      }

      &-th {
        border: 0;
        padding: 10px 5px;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &_date {
          width: 80px;
        }

        &_type {
          max-width: 80px;
        }

        &_name {
          max-width: 120px;
        }

        &_address {
          max-width: 200px;
        }

        &_contacts {
          width: 150px;
        }

        &_state {
          max-width: 140px;
        }

        &_action {
          max-width: 90px;
        }
      }

      &-td {
        position: relative;

        .building__children-wrapper {
          padding-left: 19px;
        }

        &:first-of-type {
          .building__children-wrapper {
            padding-left: 15px;
          }
        }

        &:last-of-type {
          text-align: center;
        }

        &_name {
          max-width: 100px;

          .building__children-wrapper {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;
          }
        }

        &_address {
          max-width: 200px;

          .building__children-wrapper {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 15px;
          }
        }
      }

      &-wrapper {
        border-right: 1px solid #dddddd;
        font-weight: 400;
        font-size: 12px;
        color: #222325;
        display: flex;
        align-items: center;
      }

      &-content {
        @include base;

        padding: 7px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 36px;
        width: 100%;
        text-align: center;
      }

      &-sort {
        position: relative;
        background: none;
        padding: 0;
        display: flex;
        border: 0;
        height: 16px;
        width: 17px;
        cursor: pointer;
        margin-left: 20px;
      }

      &-up {
        position: absolute;
        left: 5px;
        top: 2px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(45deg);
      }

      &-down {
        position: absolute;
        left: 5px;
        bottom: 1px;
        width: 7px;
        height: 7px;
        border: solid #bcbcbc;
        border-width: 1.5px 0 0 1.5px;
        transform: rotate(225deg);
      }

      &-type {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 10px;

        &_pro {
          background-color: #ffc96f;
          color: #222325;
        }
      }

      &-kind {
        background-color: #41c6eb;
        color: #fff;
        border-radius: 10px;
        padding: 3px 10px;
        font-size: 10px;

        &_error {
          background-color: #ff7156;
        }

        &_success {
          background-color: #7ed321;
        }
      }

      &-state {
        @include state;
        margin: 0;
        font-size: 11px;
        min-width: auto;
      }

      &-edit {
        font-size: 16px;
        color: #787878;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
        border: 0;
        background: none;

        @include mediaquery(md) {
          &:hover {
            color: #3c3c3c;
          }
        }
      }

      &-delete {
        font-size: 16px;
        color: #ff7156;
        padding: 0;
        margin: 0 5px;
        cursor: pointer;
        border: 0;
        background: none;

        @include mediaquery(md) {
          &:hover {
            color: #fc302e;
          }
        }
      }
    }
  }
</style>